export default [
  {
    path: '/apps/company/company-management',
    name: 'company-management',
    component: () => import('@/views/apps/company/company-list/CompanyList.vue'),
    meta: {
      resource: 'resource_clients_companies',
      action: 'read',
      pageTitle: 'Client List',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Management',
        },
        {
          text: 'Company List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/management/finance-tools',
    name: 'finance-tools',
    component: () => import('@/views/apps/finance/InvoicePreview.vue'),
    meta: {
      resource: 'resource_management_finance_tools',
      action: 'read',
      pageTitle: 'Invoice Preview',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Management',
        },
        {
          text: 'Invoice Preview',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/user-management/role-permissions/:roleName/:roleId',
    name: 'route-management-role-permissions',
    component: () => import('@/views/apps/permission/roles/roles-permission/RoleSetup.vue'),
    meta: {
      resource: 'resource_management_role_permissions',
      action: 'read',
      pageTitle: 'Role Management',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Management',
        },
        {
          text: 'Roles',
          to: '/apps/user-management/roles',
        },
        {
          text: 'Role Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/user-management/roles',
    name: 'route-management-roles-list',
    component: () => import('@/views/apps/permission/roles/roles-list/RolesList.vue'),
    meta: {
      resource: 'resource_management_roles',
      action: 'read',
      pageTitle: 'Role Management',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Management',
        },
        {
          text: 'Roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/wms/locations',
    name: 'warehouse-location',
    component: () => import('@/views/apps/warehouse-management/Locations.vue'),
    meta: {
      resource: 'resource_management_warehouse_location',
      action: 'read',
      pageTitle: 'Set up Locations',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'WMS',
        },
        {
          text: 'Set up Locations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/wms/actions',
    name: 'warehouse-action',
    component: () => import('@/views/apps/warehouse-management/Actions.vue'),
    meta: {
      resource: 'resource_management_warehouse_location',
      action: 'read',
      pageTitle: 'Location Actions',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'WMS',
        },
        {
          text: 'Location Actions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/wms/actions/move-batch/:moveBatchId?',
    name: 'warehouse-move-batch',
    component: () => import('@/views/apps/warehouse-management/view/MoveBatchItem.vue'),
    meta: {
      resource: 'resource_management_warehouse_location',
      action: 'read',
      pageTitle: 'Location Actions',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'WNS',
        },
        {
          text: 'Location Actions',
          to: '/apps/wms/actions',
        },
        {
          text: 'Move Batch',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/wms/location/lpn',
    name: 'warehouse-location-lpn',
    component: () => import('@/views/apps/warehouse-management/view/LpnLocation.vue'),
    meta: {
      resource: 'resource_management_warehouse_location',
      action: 'read',
      pageTitle: 'LPN Actions',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'LPN Actions',
        },
        {
          text: 'Actions',
          to: '/apps/wms/actions',
        },
        {
          text: 'LPN',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/wms/actions/location-barcode-designer',
    name: 'location-barcode-designer',
    component: () => import('@/views/apps/warehouse-management/view/BarcodeDesigner.vue'),
    meta: {
      resource: 'resource_management_warehouse_location',
      action: 'read',
      pageTitle: 'Label Designer',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Management',
        },
        {
          text: 'WMS',
        },
        {
          text: 'Label Designer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/finance/cost-group',
    name: 'route-cost-group',
    component: () => import('@/views/apps/finance/cost-group/CostGroup.vue'),
    meta: {
      resource: 'resource_management_cost_group',
      action: 'read',
      pageTitle: 'Cost Group',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Prep Materials',
          to: '/apps/prep-materials/items',
        },
        {
          text: 'Cost Group',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/prep-materials/purchase',
    name: 'route-material-purchases',
    component: () => import('@/views/apps/prep-material/purchases/PrepMaterialPurchase.vue'),
    props: route => ({
      qPrepMaterialId: parseInt(route.query.qPrepMaterialId, 10) || null,
    }),
    meta: {
      resource: 'resource_management_prep_materials_purchases',
      action: 'read',
      pageTitle: 'Prep Material Purchases',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Prep Materials',
          to: '/apps/prep-materials/items',
        },
        {
          text: 'Prep Material Purchases',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/prep-materials/items',
    name: 'route-material-items',
    component: () => import('@/views/apps/prep-material/items/PrepMaterialItems.vue'),
    props: route => ({
      qCostGroupId: parseInt(route.query.qCostGroupId, 10) || null,
    }),
    meta: {
      resource: 'resource_management_prep_materials_materials',
      action: 'read',
      pageTitle: 'Prep Materials & Services',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Prep Material Items',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/product/category',
    name: 'route-products-category',
    component: () => import('@/views/apps/product/category/ProductCategory.vue'),
    meta: {
      resource: 'resource_product_products',
      action: 'read',
      pageTitle: 'Product Category',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Product',
        },
        {
          text: 'Product Category',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/product/products',
    name: 'route-products',
    component: () => import('@/views/apps/product/products/Products.vue'),
    meta: {
      resource: 'resource_product_products',
      action: 'read',
      pageTitle: 'Product Catalog',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'Product Catalog',
          active: true,
        },
      ],
    },
  },

  // Plans
  {
    path: '/apps/plan/management',
    name: 'route-plans-plan-management',
    component: () => import('@/views/apps/plan-management/plans/PlanManagement.vue'),
    meta: {
      resource: 'resource_management_plans',
      action: 'read',
      pageTitle: 'Plan Management',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Service Charge Plans',
          to: '/apps/plan/management/customer',
        },
        {
          text: 'Plan Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/plan/types',
    name: 'route-plans-plan-types',
    component: () => import('@/views/apps/plan-management/plans/PlanTypeManagement.vue'),
    meta: {
      resource: 'resource_management_plans',
      action: 'read',
      pageTitle: 'Plan Types',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Plans',
          to: '/apps/plan/management',
        },
        {
          text: 'Plan Types',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/plan/management/customer',
    name: 'route-plans-customer-plans',
    component: () => import('@/views/apps/plan-management/customer-plan-management/CustomerPlanManagement.vue'),
    meta: {
      resource: 'resource_management_plans',
      action: 'read',
      pageTitle: 'Service Charge Plans',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Service Charge Plans',
          active: true,
        },
      ],
    },
  },

  // Problem-Management
  {
    path: '/apps/problem/management',
    name: 'problem-management',
    component: () => import('@/views/apps/problem-management/ProblemManagement.vue'),
    meta: {
      resource: 'resource_management_problem_management',
      action: 'read',
      pageTitle: 'Problem Items',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Problem Items',
          active: true,
        },
      ],
    },
  },

  // User Info
  {
    path: '/apps/management/account-manager/users-list',
    name: 'users-list',
    component: () => import('@/views/apps/account-manager/users-list/UsersList.vue'),
    meta: {
      resource: 'resource_management_user_info',
      action: 'read',
      pageTitle: 'User List',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Management',
        },
        {
          text: 'Clients',
          to: '/apps/company/company-management',
        },
        {
          text: 'User List',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/apps/management/account-manager/user-detail/:id',
  //   name: 'user-detail',
  //   component: () => import('@/views/apps/account-manager/users-view/UsersView.vue'),
  //   meta: {
  //     resource: 'resource_management_user_info',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/apps/management/account-manager/user-info',
  //   name: 'user-info',
  //   component: () => import('@/views/apps/account-manager/UserInfoList.vue'),
  //   meta: {
  //     resource: 'resource_management_user_info',
  //     action: 'read',
  //     pageTitle: 'User List',
  //     breadcrumb: [
  //       {
  //         text: 'Home',
  //         to: '/dashboard',
  //       },
  //       {
  //         text: 'Ship',
  //       },
  //       {
  //         text: 'Uploaded Batches',
  //         to: '/uploaded-batches',
  //       },
  //       {
  //         text: 'Items',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/apps/management/subscribe',
    name: 'route-notification-subscribe',
    component: () => import('@/views/apps/notification/Subscribe.vue'),
    meta: {
      resource: 'resource_mail_management_mail_topic',
      action: 'read',
      pageTitle: 'Subscriptions',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Management',
        },
        {
          text: 'Subscriptions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/management/mail-management/mail-topic',
    name: 'route-mail-topic',
    component: () => import('@/views/apps/mail-management/mail-topic/MailTopicList.vue'),
    meta: {
      resource: 'resource_mail_topic',
      action: 'read',
      pageTitle: 'Email Topic',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Management',
        },
        {
          text: 'Email Managements',
        },
        {
          text: 'Email Topic',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/apps/management/mail-management/mail-log',
  //   name: 'route-mail-management-mail-log',
  //   component: () => import('@/views/apps/mail-management/mail-log/MailLogList.vue'),
  //   meta: {
  //     resource: 'resource_mail_management_mail_log',
  //     action: 'read',
  //     pageTitle: 'Email Log',
  //     breadcrumb: [
  //       {
  //         text: 'Home',
  //         to: '/dashboard',
  //       },
  //       {
  //         text: 'Management',
  //       },
  //       {
  //         text: 'Email Managements',
  //       },
  //       {
  //         text: 'Email Log',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/management/mail-management/mail-tenant',
  //   name: 'route-mail-management-mail-tenant',
  //   component: () => import('@/views/apps/mail-management/mail-tenant/MailTenantList.vue'),
  //   meta: {
  //     resource: 'resource_mail_management_mail_tenant',
  //     action: 'read',
  //     pageTitle: 'Email Tenant',
  //     breadcrumb: [
  //       {
  //         text: 'Home',
  //         to: '/dashboard',
  //       },
  //       {
  //         text: 'Management',
  //       },
  //       {
  //         text: 'Email Managements',
  //       },
  //       {
  //         text: 'Email Tenant',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/management/mail-management/mail-topic-company',
  //   name: 'route-mail-management-mail-topic-company',
  //   component: () => import('@/views/apps/mail-management/mail-topic-company/MailTopicCompany.vue'),
  //   meta: {
  //     resource: 'resource_mail_management_mail_topic_company',
  //     action: 'read',
  //     pageTitle: 'Email Subscription',
  //     breadcrumb: [
  //       {
  //         text: 'Home',
  //         to: '/dashboard',
  //       },
  //       {
  //         text: 'Management',
  //       },
  //       {
  //         text: 'Email Managements',
  //       },
  //       {
  //         text: 'Email Subscription',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/apps/management/task-management',
    name: 'route-task-management',
    component: () => import('@/views/apps/task-management/TaskManagement.vue'),
    meta: {
      resource: 'resource_customer_account_no',
      action: 'read',
      pageTitle: 'Task Management',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Management',
        },
        {
          text: 'Tasks',
        },
        {
          text: 'Task Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/management/task-calendar',
    name: 'route-task-calendar',
    component: () => import('@/views/apps/task-management/TaskCalendar.vue'),
    meta: {
      resource: 'resource_customer_account_no',
      action: 'read',
      pageTitle: 'Task Calendar',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Management',
        },
        {
          text: 'Tasks',
        },
        {
          text: 'Task Calendar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/file-manager/browser',
    name: 'file-browser-management',
    component: () => import('@/views/apps/file-manager/FileBrowser.vue'),
    meta: {
      resource: 'resource_management_file_management',
      action: 'read',
      pageTitle: 'File Management',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'File Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/management/tickets',
    name: 'route-tickets',
    component: () => import('@/views/apps/tickets/TicketGrid.vue'),
    meta: {
      resource: 'resource_admin',
      action: 'read',
      pageTitle: 'Tickets',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Management',
        },
        {
          text: 'Tickets',
          active: true,
        },
      ],
    },
  },
]
