const outboundShipping = {

  Controller: {
    base: '/api/outbound/outbound-shipping',

    /*
     * ShipmentBatchParent mappings
     */
    findBatchParentById: id => `${outboundShipping.Controller.base}/batch-parent/${id}`,
    getBatchParentsByQuery: pageable => `${outboundShipping.Controller.base}/batch-parent/query?${pageable}`,
    getShipmentPlanStats: id => `${outboundShipping.Controller.base}/batch-parent/${id}/stats`,
    getCanTransmitBatch: id => `${outboundShipping.Controller.base}/batch-parent/amazon/can-transmit-batch/${id}`,
    createBatchParent: () => `${outboundShipping.Controller.base}/batch-parent`,
    updateBatchParent: () => `${outboundShipping.Controller.base}/batch-parent/update`,
    deleteBatchParent: id => `${outboundShipping.Controller.base}/batch-parent/${id}`,
    revertShipmentPlanFromBoxingToWorking: id => `${outboundShipping.Controller.base}/batch-parent/amazon/revert-shipment-plan/${id}`,
    closeBatchParent: id => `${outboundShipping.Controller.base}/batch-parent/close/${id}`,
    getBatchParentItemsByQuery: () => `${outboundShipping.Controller.base}/batch-parent-items/query`,
    getBatchParentItems: id => `${outboundShipping.Controller.base}/batch-parent-items/${id}`,
    updateBatchParentItemInfo: () => `${outboundShipping.Controller.base}/batch-parent-items/update-info`,
    updatePatchItem: () => `${outboundShipping.Controller.base}/batch-parent-items/patch-item`,
    getParentItemById: id => `${outboundShipping.Controller.base}/batch-parent-item/${id}`,
    getFbaBoxItemMapInfoByBoxId: boxId => `${outboundShipping.Controller.base}/batch-parent-item/by-box/${boxId}`,
    createBatchParentItem: () => `${outboundShipping.Controller.base}/batch-parent-item`,
    updateBatchParentItem: () => `${outboundShipping.Controller.base}/batch-parent-items/update`,
    updateBatchItemQuantity: () => `${outboundShipping.Controller.base}/batch-parent-item/update-quantity`,
    updateBatchItemPlan: (id, planId, applyAll) => `${outboundShipping.Controller.base}/batch-parent-item/update/${id}/plan/${planId}/apply-all/${applyAll}`,
    assignBatchParentItemToBoxes: () => `${outboundShipping.Controller.base}/batch-parent-items/assign-boxes`,
    assignBatchParentItemToBoxesMap: () => `${outboundShipping.Controller.base}/batch-parent-items/assign-boxes/multiple`,
    assignAllBatchParentItemToBox: batchId => `${outboundShipping.Controller.base}/batch-parent-items/assign-box-all/${batchId}`,
    estimateTransport: (batchId, shipmentType) => `${outboundShipping.Controller.base}/batch-parent/amazon/estimate-transport/${batchId}/${shipmentType}`,
    getTransportDetails: batchId => `${outboundShipping.Controller.base}/batch-parent/amazon/get-transport-details/${batchId}`,
    confirmTransport: batchId => `${outboundShipping.Controller.base}/batch-parent/amazon/confirm-transport/${batchId}`,
    getFbaShipmentLabels: (batchId, pageType) => `${outboundShipping.Controller.base}/batch-parent/amazon/get-labels/${batchId}/${pageType}`,
    voidFbaShipmentLabel: batchId => `${outboundShipping.Controller.base}/batch-parent/amazon/void-label/${batchId}`,
    deleteBatchParentItem: (id, deleteFromAmazon) => `${outboundShipping.Controller.base}/batch-parent-items/delete/${id}/${deleteFromAmazon}`,
    getFBAItemsByProductId: (id, pageable) => `${outboundShipping.Controller.base}/items-by-product/${id}/fba?${pageable}`,
    getOtherItemsByProductId: (id, pageable) => `${outboundShipping.Controller.base}/items-by-product/${id}/others?${pageable}`,
    getOpenParentBatches: channel => `${outboundShipping.Controller.base}/batch-parent/open${channel}`,
    importAmazonShipmentBatch: () => `${outboundShipping.Controller.base}/batch-parent/import`,
    updateSingleBatch: () => `${outboundShipping.Controller.base}/batch/update`,
    getMissingReferenceIds: () => `${outboundShipping.Controller.base}/dashboard/ltl`,

    /*
     * ShipmentBatch mappings
     */
    findBatchById: id => `${outboundShipping.Controller.base}/batch/${id}`,
    getAllBatches: () => `${outboundShipping.Controller.base}/batch`,
    findAllBatchesByCompanyId: id => `${outboundShipping.Controller.base}/batch/company/${id}`,
    findBatchesByCompanyIdAndStatus: (id, status) => `${outboundShipping.Controller.base}/batch/company/${id}/status/${status}`,
    findAllBatchesByStatus: status => `${outboundShipping.Controller.base}/batch/status/${status}`,
    getBatchesByQuery: pageable => `${outboundShipping.Controller.base}/batch/query?${pageable}`,
    getBatchDataByParentId: parentId => `${outboundShipping.Controller.base}/batch/batch-data/parent-batch/${parentId}`,
    getCountsBatchesByQuery: () => `${outboundShipping.Controller.base}/batch/counts`,
    queryMerchantShipments: pageable => `${outboundShipping.Controller.base}/package/box?${pageable}`,
    listMerchantShipmentItems: shipmentId => `${outboundShipping.Controller.base}/package/box/items/${shipmentId}`,
    createBatch: () => `${outboundShipping.Controller.base}/batch`,
    createBatchAmazon: () => `${outboundShipping.Controller.base}/batch/fba`,
    addCostToShipmentBatch: () => `${outboundShipping.Controller.base}/batch/add-cost`,
    updateCostToShipmentBatch: () => `${outboundShipping.Controller.base}/batch/add-cost/update`,
    markAsShipped: batchId => `${outboundShipping.Controller.base}/batch/mark-as-shipped/${batchId}`,
    updateBatch: () => `${outboundShipping.Controller.base}/batch/update`,
    updateBatchStatus: (batchId, status) => `${outboundShipping.Controller.base}/batch/update/${batchId}/status/${status}`,
    deleteBatch: id => `${outboundShipping.Controller.base}/batch/${id}`,
    deleteFBABatchBoxId: id => `${outboundShipping.Controller.base}/batch-parent-items/delete-box/${id}`,

    /*
     * ShipmentBox mappings
     */
    findBoxById: id => `${outboundShipping.Controller.base}/box/${id}`,
    findAllBoxesByBatchId: batchId => `${outboundShipping.Controller.base}/box/batch/${batchId}`,
    findAllBoxeDataByBatchId: batchId => `${outboundShipping.Controller.base}/box/batch/data/${batchId}`,
    findAllBoxesByBatchIdAndStatus: (batchId, status) => `${outboundShipping.Controller.base}/box/batch/${batchId}/status/${status}`,
    getBoxContentFor2DBarcodeAllBatch: batchId => `${outboundShipping.Controller.base}/box/content-2d-barcode/batch/${batchId}`,
    getBoxContentFor2DBarcode: boxId => `${outboundShipping.Controller.base}/box/content-2d-barcode/${boxId}`,
    getPalletBarcode: batchId => `${outboundShipping.Controller.base}/pallet/labels/${batchId}`,
    findBoxesByQuery: pageable => `${outboundShipping.Controller.base}/package/query?${pageable}`,
    getBatchesByParentId: parentId => `${outboundShipping.Controller.base}/batch/parent-batch/${parentId}`,
    setBoxBoxDimensions: () => `${outboundShipping.Controller.base}/box/set-box-dimensions`,
    setShipmentPalletDetailsAndTransmitToAmazon: () => `${outboundShipping.Controller.base}/pallet/set-pallets-and-transmit`,
    getShipmentPalletByBatchId: batchId => `${outboundShipping.Controller.base}/pallet/batch/${batchId}`,
    createBox: () => `${outboundShipping.Controller.base}/box`,
    createShipmentPackages: () => `${outboundShipping.Controller.base}/package`,
    scanInfoBox: () => `${outboundShipping.Controller.base}/batch-parent-items/scan-into-box`,
    /*
     * ShipmentItem mappings
     */
    findItemById: id => `${outboundShipping.Controller.base}/item/${id}`,
    findAllItemsByBatchId: (batchId, pageable) => `${outboundShipping.Controller.base}/item/batch/${batchId}?${pageable}`,
    shipmentBatchStats: batchId => `${outboundShipping.Controller.base}/item/batch/stats/${batchId}`,
    findAllByMultipleBatchIds: () => `${outboundShipping.Controller.base}/item/batches`,
    getILFormattedItemsBatchIds: () => `${outboundShipping.Controller.base}/item/batch/il-format`,
    findAllItemsByBoxId: boxId => `${outboundShipping.Controller.base}/item/box/${boxId}`,

    /**
     * Amazon FBA Shipment Batch
     */
    amazon: '/api/outbound/outbound-shipping/batch-parent/amazon',
    submitAmazonInboundShipmentPlan: parentBatchId => `${outboundShipping.Controller.amazon}/submit-plan/${parentBatchId}`,
    consolidateAmazonInboundShipmentPlan: (parentBatchId, destination) => `${outboundShipping.Controller.amazon}/consolidate-plan/${parentBatchId}/${destination}`,
    createConsolidatedAmazonInboundShipment: () => `${outboundShipping.Controller.amazon}/create-consolidated-batch`,
    cancelConsolidatingAmazonInboundShipment: shipmentPlanId => `${outboundShipping.Controller.amazon}/cancel-consolidating-batch/${shipmentPlanId}`,
    createAmazonInboundShipment: batchId => `${outboundShipping.Controller.amazon}/create-batch/${batchId}`,
    updateAmazonInboundShipment: batchId => `${outboundShipping.Controller.amazon}/update-batch/${batchId}`,
    mergeShipmentPlan: (fromPlanId, toPlanId) => `${outboundShipping.Controller.amazon}/merge-shipment-plan-items/${fromPlanId}/${toPlanId}`,
    updateShipFromAddress: (batchId, fromAddressId) => `${outboundShipping.Controller.amazon}/update-batch/${batchId}/address/${fromAddressId}`,
  },
}
export default {
  outboundShipping,
}
