export default {
  individual: {
    value: 'individual',
    key: 'individual',
    text: 'Single',
    disabled: false,
  },
  bundle: {
    value: 'bundle',
    key: 'bundle',
    text: 'Custom Bundle',
    disabled: false,
  },
  multi_pack: {
    value: 'multi_pack',
    key: 'multi_pack',
    text: 'Custom Multi-Pack',
    disabled: false,
  },
}
