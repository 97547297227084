import find from 'lodash/find'
/**
 * Enum for Inventory Types.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const inventoryTypesEnum = Object.freeze({
  INVENTORY: { key: 'INVENTORY', value: 'INVENTORY', text: 'Inventory' },
  NONE_INVENTORY: { key: 'NONE_INVENTORY', value: 'NONE_INVENTORY', text: 'Non-inventory' },
})

function getInventoryTypeEnum(predicate, predicateKey = 'key') {
  const result = find(inventoryTypesEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getInventoryTypeList() {
  const options = []
  Object
    .keys(inventoryTypesEnum)
    .map(key => options.push(
      {
        value: inventoryTypesEnum[key].value,
        text: inventoryTypesEnum[key].text,
      },
    ))
  return options
}

export default inventoryTypesEnum

export {
  getInventoryTypeEnum,
  getInventoryTypeList,
}
