import find from 'lodash/find'
/**
 * Enum for Basic Reporting Date Range Selections
 * @readonly
 * @enum {{key: string, value: string|null, text: string}}
 */

const predefinedDateRangesEnum = Object.freeze({
  TODAY: { key: 'TODAY', value: 'today', text: 'Today' },
  CURRENTWEEK: { key: 'CURRENT_WEEK', value: 'current_week', text: 'Current Week' },
  CURRENTMONTH: { key: 'CURRENT_MONTH', value: 'current_month', text: 'Current Month' },
  CURRENTQUARTER: { key: 'CURRENT_QUARTER', value: 'current_quarter', text: 'Current Quarter' },
  CURRENTYEAR: { key: 'CURRENT_YEAR', value: 'current_year', text: 'Current Year' },
  LAST7DAYS: { key: 'LAST7DAYS', value: 'last7Days', text: 'Last 7 Days' },
  LAST30DAYS: { key: 'LAST30DAYS', value: 'last30Days', text: 'Last 30 Days' },
  LAST90DAYS: { key: 'LAST90DAYS', value: 'last90Days', text: 'Last 90 Days' },
  LAST365DAYS: { key: 'LAST365DAYS', value: 'last365Days', text: 'Last 365 Days' },
  CUSTOM: { key: 'CUSTOM', value: 'custom', text: 'Custom' },
})

function getPredefinedDateRangesEnum(predicate, predicateKey = 'key') {
  const result = find(predefinedDateRangesEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getPredefinedDateRangesList() {
  const options = []
  Object
    .keys(predefinedDateRangesEnum)
    .map(key => options.push(
      {
        value: predefinedDateRangesEnum[key].value,
        text: predefinedDateRangesEnum[key].text,
      },
    ))
  return options
}

export default predefinedDateRangesEnum

export {
  getPredefinedDateRangesEnum,
  getPredefinedDateRangesList,
}
