const membershipTypeDetail = {
  Controller: {
    base: '/api/membership/types/details',
    create: () => `${membershipTypeDetail.Controller.base}`,
    delete: id => `${membershipTypeDetail.Controller.base}/${id}`,
    update: () => `${membershipTypeDetail.Controller.base}`,
    getListByFilter: () => `${membershipTypeDetail.Controller.base}/list`,
    getByPageableQuery: pageable => `${membershipTypeDetail.Controller.base}/query${pageable}`,
    findById: id => `${membershipTypeDetail.Controller.base}/${id}`,
  },
}

export default { membershipTypeDetail }
