const printerAgentManagement = {

  Controller: {
    availableprinters: 'api/printer/available',
    labeltypes: 'api/printer/labelTypes',
    labelList: 'api/printer/labels',
    labelListByVendor: 'api/printer/labelsByVendor',
    printbylabel: 'api/printer/printByLabel',
    printbylabelList: 'api/printer/printByLabelList',
    labelbase64: 'api/printer/labelBase64',
    printbyrawdata: 'api/printer/printRaw',
    getVersion: 'api/printer/version',

    // #region Endpoints
    availablePrinters: () => `${printerAgentManagement.Controller.availableprinters}`,
    labelTypes: () => `${printerAgentManagement.Controller.labeltypes}`,
    labels: () => `${printerAgentManagement.Controller.labelList}`,
    labelsbyvendor: () => `${printerAgentManagement.Controller.labelListByVendor}`,
    printoutbylabel: () => `${printerAgentManagement.Controller.printbylabel}`,
    printOutByLabelList: () => `${printerAgentManagement.Controller.printbylabelList}`,
    getLabelDocument: () => `${printerAgentManagement.Controller.labelbase64}`,
    printoutbyrawdata: () => `${printerAgentManagement.Controller.printbyrawdata}`,
    getAgentVersion: () => `${printerAgentManagement.Controller.getVersion}`,
    // #endregion
  },
}

const printerServerManagement = {

  Controller: {
    vendor_base: 'api/printer/vendors',
    label_base: 'api/printer/labels',
    systemdata_base: 'api/printer/system-data/params',

    // #region vendor Endpoints
    addVendor: () => `${printerServerManagement.Controller.vendor_base}`,
    deleteVendor: id => `${printerServerManagement.Controller.vendor_base}/${id}`,
    editVendor: id => `${printerServerManagement.Controller.vendor_base}/${id}`,
    getVendors: () => `${printerServerManagement.Controller.vendor_base}`,
    // #endregion

    // #region labels Endpoints
    getLabels: vendorid => `${printerServerManagement.Controller.label_base}/vendor-id/${vendorid}`,
    addLabel: () => `${printerServerManagement.Controller.label_base}`,
    editLabel: labelid => `${printerServerManagement.Controller.label_base}/${labelid}`,
    deleteLabel: labelid => `${printerServerManagement.Controller.label_base}/${labelid}`,
    // #endregion

    // #region system-data Endpoints
    getSystemData: () => `${printerServerManagement.Controller.systemdata_base}`,
    getSystemDataByKey: key => `${printerServerManagement.Controller.systemdata_base}/key/${key}`,
    addSystemData: () => `${printerServerManagement.Controller.systemdata_base}`,
    editSystemData: systemdataid => `${printerServerManagement.Controller.systemdata_base}/${systemdataid}`,
    deleteSystemData: systemdataid => `${printerServerManagement.Controller.systemdata_base}/${systemdataid}`,
    // #endregion
  },
}

export default {
  printerAgentManagement,
  printerServerManagement,
}
