const invoiceItems = {

  Controller: {
    base: '/api/finance/invoice/items',

    // invoiceItems Endpoints
    findById: id => `${invoiceItems.Controller.base}/id/${id}`,
    findAllByInvoiceId: invoiceId => `${invoiceItems.Controller.base}/${invoiceId}`,
    findCostCategoriesByInvoiceId: invoiceId => `${invoiceItems.Controller.base}/cost-group/${invoiceId}`,
    findItemsByCostGroupAndInvoiceId: params => `${invoiceItems.Controller.base}/${params.invoiceId}/cost-group/${params.costGroup}`,
    create: () => `${invoiceItems.Controller.base}`,
    update: () => `${invoiceItems.Controller.base}`,
    appllyDiscount: (id, discount) => `${invoiceItems.Controller.base}/apply-discount/${id}/discount/${discount}`,
    delete: id => `${invoiceItems.Controller.base}/${id}`,
  },
}
export default {
  invoiceItems,
}
