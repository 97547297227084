export default {
  annually: {
    value: 'annually',
    key: 'annually',
    text: 'Annually',
    disabled: false,
  },
  monthly: {
    value: 'monthly',
    key: 'monthly',
    text: 'Monthly',
    disabled: false,
  },
}
