const prepMaterial = {

  Controller: {
    base: '/api/prep-material/prep-material-item',

    // prepMaterial Endpoints
    findAll: pageable => `${prepMaterial.Controller.base}?${pageable}`,
    getByQuery: pageable => `${prepMaterial.Controller.base}/query?${pageable}`,
    findInfoById: id => `${prepMaterial.Controller.base}/${id}`,
    findAllByCostGroup: (costGroup, pageable) => `${prepMaterial.Controller.base}/cost-group/${costGroup}?${pageable}`,
    findAllMatchingName: name => `${prepMaterial.Controller.base}/name/${name}`,
    create: () => `${prepMaterial.Controller.base}`,
    update: () => `${prepMaterial.Controller.base}`,
    delete: id => `${prepMaterial.Controller.base}/${id}`,
  },
}
export default {
  prepMaterial,
}
