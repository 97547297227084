const mailTenant = {
  Controller: {
    base: '/api/mail/mail-tenant',
    findAll: params => `${mailTenant.Controller.base}${params}`,
    findById: id => `${mailTenant.Controller.base}/${id}`,
    create: () => `${mailTenant.Controller.base}`,
    update: () => `${mailTenant.Controller.base}`,
    delete: id => `${mailTenant.Controller.base}/${id}`,
  },
}
export default {
  mailTenant,
}
