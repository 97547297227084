export default [
  {
    path: '/apps/inbound/file-upload',
    name: 'file-upload',
    component: () => import('@/views/apps/ship/file-upload/FileUpload.vue'),
    meta: {
      resource: 'resource_ship_file_upload',
      action: 'read',
      pageTitle: 'Inbound Shipment Plans',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Inbound Shipment Plans',
          to: '/apps/inbound/uploaded-batches',
        },
        {
          text: 'File Upload',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inbound/batch-workspace/:batchId',
    name: 'batch-workspace',
    component: () => import('@/views/apps/ship/batch-workspace/Index.vue'),
    meta: {
      resource: 'resource_ship_shipment_batch',
      action: 'read',
      pageTitle: 'Batch Workspace',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Inbound Shipment Plans',
          to: '/apps/inbound/uploaded-batches',
          name: 'uploaded-batches',
          withQuery: true,
        },
        {
          text: 'Batch Workspace',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inbound/shipment-items/:status',
    name: 'route-shipment-items',
    component: () => import('@/views/apps/ship/shipment-items/ShipmentItems.vue'),
    meta: {
      resource: 'resource_ship_shipment_batch',
      action: 'read',
      pageTitle: 'Shipment Items',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Shipment Plans',
          to: '/apps/inbound/uploaded-batches',
          name: 'uploaded-batches',
          withQuery: true,
        },
        {
          text: 'Shipment Items',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inbound/shipment-items/pending',
    name: 'pending-inbound-items',
    component: () => import('@/views/apps/ship/shipment-items/PendingShipmentItems.vue'),
    meta: {
      resource: 'resource_ship_shipment_batch',
      action: 'read',
      pageTitle: 'Pending Shipment Items',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Shipment Plans',
          to: '/apps/inbound/uploaded-batches',
          name: 'uploaded-batches',
          withQuery: true,
        },
        {
          text: 'Pending Shipment Items',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inbound/shipment-bundles',
    name: 'route-shipment-bundles',
    component: () => import('@/views/apps/ship/shipment-items/ShipmentBundles.vue'),
    meta: {
      resource: 'resource_ship_shipment_batch',
      action: 'read',
    },
  },
  {
    path: '/apps/inbound/uploaded-batches',
    name: 'uploaded-batches',
    component: () => import('@/views/apps/ship/shipment-batch/UploadedBatch.vue'),
    props: route => ({
      qAccountNo: parseInt(route.query.qAccountNo, 10),
      qWarehouseId: parseInt(route.query.qWarehouseId, 10) || 0,
      qStoreId: parseInt(route.query.storeId, 10) || 0,
      qStatus: route.query.qStatus || 'all',
    }),
    meta: {
      resource: 'resource_ship_shipment_batch',
      action: 'read',
      pageTitle: 'Inbound Shipment Plans',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Shipment Plans',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inbound/package-receive-batch',
    name: 'route-unload-scan-batch',
    component: () => import('@/views/apps/inbound/package-receive-batch/PackageAccountSelect.vue'),
    meta: {
      resource: 'resource_inbound_package_scan_receive_batch',
      action: 'read',
      pageTitle: 'Inbound Delivered Packages',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Delivered Packages',
          active: true,
        },
        {
          text: 'Account Select',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inbound/package-receive-batch/:accountNo/:companyId/:companyName',
    name: 'route-unload-scan-batch-process',
    component: () => import('@/views/apps/inbound/package-receive-batch/PackageReceiveBatch.vue'),
    props: true,
    meta: {
      resource: 'resource_inbound_package_scan_receive_batch',
      action: 'read',
      pageTitle: 'Batches',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Account Select',
          to: '/apps/inbound/package-receive-batch',
        },
        {
          text: 'Batches',
        },
      ],
    },
  },
  {
    path: '/apps/inbound/package-scan',
    name: 'route-package-scan',
    component: () => import('@/views/apps/inbound/package-scan/PackageScan.vue'),
    meta: {
      resource: 'resource_inbound_package_scan_receive_batch',
      action: 'read',
      pageTitle: 'Batches',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Account Select',
          to: '/apps/inbound/package-receive-batch',
        },
        {
          text: 'Batches',
          name: 'route-unload-scan-batch-process',
          withQuery: true,
        },
        {
          text: 'Package Scan',
        },
        {
          text: 'Company Name',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      const batchName = decodeURIComponent(to.query.batchName)
      const accountNo = decodeURIComponent(to.query.accountNo)
      const breadcrumb = to.meta.breadcrumb
      const lastBreadcrumbItem = breadcrumb[breadcrumb.length - 1]
      lastBreadcrumbItem.text = `${accountNo} | ${batchName}`
      next()
    },
  },
  {
    path: '/apps/inbound/receive-batch/account-select',
    name: 'route-receive-batch-account-select',
    component: () => import('@/views/apps/inbound/receive-batch/AccountSelect.vue'),
    meta: {
      resource: 'resource_inbound_receive_batch',
      action: 'read',
      pageTitle: 'Receive Batch Account Select',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Account Select',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inbound/receive-batch/:accountNo/:companyId/:storeId?/:planId?/:action?',
    name: 'route-receive-batch',
    component: () => import('@/views/apps/inbound/receive-batch/ReceiveBatch.vue'),
    meta: {
      resource: 'resource_inbound_receive_batch',
      action: 'read',
      pageTitle: 'Receive Batch',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Account Select',
          to: '/apps/inbound/receive-batch/account-select',
        },
        {
          text: 'Receive Batch',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inbound/item-sort',
    name: 'route-unload-item-sort',
    component: () => import('@/views/apps/inbound/item-sort/ItemSort.vue'),
    meta: {
      resource: 'resource_inbound_item_sort',
      action: 'read',
      pageTitle: 'Item Sort',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Item Sort',
        },
        {
          text: 'Account Select',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inbound/item-sort/:accountNo/:companyId',
    name: 'route-unload-item-sort-proccess',
    component: () => import('@/views/apps/inbound/item-sort/ItemSortProccess.vue'),
    meta: {
      resource: 'resource_inbound_item_sort',
      action: 'read',
      pageTitle: 'Item Sort',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Account Select',
          to: '/apps/inbound/item-sort',
        },
        {
          text: 'Item Sort',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inbound/sorted-batches',
    name: 'route-inbound-sorted-batches',
    component: () => import('@/views/apps/inbound/sorted-batches/SortedBatches.vue'),
    meta: {
      resource: 'resource_inbound_item_sorted_batches',
      action: 'read',
      pageTitle: 'Sorted Batches',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inbound',
        },
        {
          text: 'Sorted Batches',
          active: true,
        },
      ],
    },
  },
]
