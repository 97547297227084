import find from 'lodash/find'
/**
 * Filters the results by the fulfillment network that you specify,
 * MFN (merchant fulfillment network) or AFN (Amazon fulfillment network).
 * Do not include this filter if you want the response to include order metrics
 * for all fulfillment networks. E.g.: AFN, if you want the response to include
 * order metrics for only Amazon fulfillment network.
* @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const fulfillmentNetworkEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'Fulfillment Network (All)' },
  AFN: { key: 'AFN', value: 'AFN', text: 'AFN' },
  MFN: { key: 'MFN', value: 'MFN', text: 'MFN' },
})

function getFulfillmentNetworkEnum(predicate, predicateKey = 'key') {
  const result = find(fulfillmentNetworkEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getList() {
  const options = []
  Object
    .keys(fulfillmentNetworkEnum)
    .map(key => options.push(
      {
        value: fulfillmentNetworkEnum[key].value,
        text: fulfillmentNetworkEnum[key].text,
      },
    ))
  return options
}

export default fulfillmentNetworkEnum

export {
  getFulfillmentNetworkEnum,
  getList,
}
