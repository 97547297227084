const upcAndCodes = {

  Controller: {
    base: '/api/product/upc-and-codes',

    // upcAndCodes Endpoints
    findAll: pageable => `${upcAndCodes.Controller.base}?${pageable}`,
    findById: id => `${upcAndCodes.Controller.base}/${id}`,
    findByUPC: upc => `${upcAndCodes.Controller.base}/upc/${upc}`,
    findByASIN: asin => `${upcAndCodes.Controller.base}/asin/${asin}`,
    findByMSKU: msku => `${upcAndCodes.Controller.base}/msku/${msku}`,
    findByFNSKU: fnsku => `${upcAndCodes.Controller.base}/fsku/${fnsku}`,
    findByAny: code => `${upcAndCodes.Controller.base}/any/${code}`,
    findByTitle: title => `${upcAndCodes.Controller.base}/title/${title}`,
    findByQuery: (params, pageable) => `${upcAndCodes.Controller.base}/query?${params}&${pageable}`,
    create: () => `${upcAndCodes.Controller.base}`,
    update: () => `${upcAndCodes.Controller.base}`,
    delete: id => `${upcAndCodes.Controller.base}/${id}`,
    deleteMany: () => `${upcAndCodes.Controller.base}/delete-many`,
  },
}
export default {
  upcAndCodes,
}
