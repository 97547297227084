/**
 * Enum for settings type.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const settingsTypeEnum = Object.freeze({
  MSKUFORMULA: { key: 'MSKUFORMULA', value: 'MSKUFORMULA' },
  PALLETCONTACT: { key: 'PALLETCONTACT', value: 'PALLETCONTACT' },
  THEMESETTINGS: { key: 'THEMESETTINGS', value: 'THEMESETTINGS' },
  NOTIFICATION_PREFERENCES: { key: 'NOTIFICATION_PREFERENCES', value: 'NOTIFICATION_PREFERENCES' },
  INBOUND_SHIPMENT_ITEMS_GRID: { key: 'INBOUND_SHIPMENT_ITEMS_GRID', value: 'INBOUND_SHIPMENT_ITEMS_GRID' },
  DATAGRID_STATES: { key: 'DATAGRID_STATES', value: 'DATAGRID_STATES' },
  STORE_FBM_COUNTRY: { key: 'STORE_FBM_COUNTRY', value: 'STORE_FBM_COUNTRY' },
  AUTO_PRICING: { key: 'AUTO_PRICING', value: 'AUTO_PRICING' },
  STORE_PULL_AMZ_RETURN_ORDER: { key: 'STORE_PULL_AMZ_RETURN_ORDER', value: 'STORE_PULL_AMZ_RETURN_ORDER' },
  STORE_PULL_AMZ_REMOVAL_ORDER: { key: 'STORE_PULL_AMZ_REMOVAL_ORDER', value: 'STORE_PULL_AMZ_REMOVAL_ORDER' },
})

export default settingsTypeEnum
