export default {
  none: {
    value: 'none',
    key: 'none',
    text: 'Damage Type',
    disabled: false,
  },
  broken: {
    value: 'broken',
    key: 'broken',
    text: 'Broken Item',
    disabled: false,
  },
  dirtyOrUsed: {
    value: 'dirty_or_used',
    key: 'dirty_or_used',
    text: 'Dirty or Used',
    disabled: false,
  },
  boxDamage: {
    value: 'box_damage',
    key: 'box_damage',
    text: 'Box Damage',
    disabled: false,
  },
  lostFluid: {
    value: 'lost_fluid',
    key: 'lost_fluid',
    text: 'Lost Fluid',
    disabled: false,
  },
  wet: {
    value: 'wet',
    key: 'wet',
    text: 'Wet',
    disabled: false,
  },
  defective: {
    value: 'defective',
    key: 'defective',
    text: 'Defective',
    disabled: false,
  },
}
