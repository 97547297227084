export default {
  private_flow: {
    value: 'private_flow',
    key: 'private_flow',
    text: 'Private',
    disabled: false,
  },
  live_flow: {
    value: 'live_flow',
    key: 'live_flow',
    text: 'Live',
    disabled: false,
  },
}
