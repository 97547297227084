import find from 'lodash/find'
/**
 * Enum for Amazon sales order fulfillment method.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const fulfillmentMethodsEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'All Channels' },
  Amazon: { key: 'Amazon', value: 'Amazon', text: 'Amazon' },
  Merchant: { key: 'Merchant', value: 'Merchant', text: 'Merchant' },
})

function getFulfillmentMethodEnum(predicate, predicateKey = 'key') {
  const result = find(fulfillmentMethodsEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getFulfillmentMethodList() {
  const options = []
  Object
    .keys(fulfillmentMethodsEnum)
    .map(key => options.push(
      {
        value: fulfillmentMethodsEnum[key].value,
        text: fulfillmentMethodsEnum[key].text,
      },
    ))
  return options
}

export default fulfillmentMethodsEnum

export {
  getFulfillmentMethodEnum,
  getFulfillmentMethodList,
}
