/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { POSITION, TYPE } from 'vue-toastification'
import Vue from 'vue'
import Swal from 'sweetalert2'
import { useClipboard } from '@vueuse/core'
import isString from 'lodash/isString'
import jwtDefaultConfig from './jwtDefaultConfig'

let loader = null
const siteUrl = window.location.origin

function hideLoader() {
  if (loader) loader.hide()
  loader = null
}

export default class JwtService {
  axiosIns = null

  jwtConfig = { ...jwtDefaultConfig }

  isAlreadyFetchingAccessToken = false

  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    this.axiosIns.interceptors.request.use(
      config => {
        const accessToken = this.getToken()
        if (accessToken && !this.isExpired()) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        } else {
          config.headers.Authorization = ''
        }

        if (loader) return config
        loader = Vue.$loading.show()

        return config
      },
      error => Promise.reject(error),
    )

    this.axiosIns.interceptors.response.use(
      response => {
        if ('data' in response && !isString(response.data) && 'meta' in response.data && 'error_list' in response.data.meta && response.data.meta.error_list) {
          response.data.meta.error_list.forEach(errorMsg => {
            if (errorMsg.message) {
              Vue.$toast(errorMsg.message, {
                type: TYPE.ERROR,
                position: POSITION.TOP_CENTER,
              })
            }
          })
        }

        if ('data' in response && !isString(response.data) && 'meta' in response.data && 'info_list' in response.data.meta && response.data.meta.info_list) {
          response.data.meta.info_list.forEach(successMsg => {
            if (successMsg.message) {
              Vue.$toast(successMsg.message, {
                type: TYPE.SUCCESS,
                position: POSITION.TOP_CENTER,
              })
            }
          })
        }

        if ('data' in response && !isString(response.data) && 'meta' in response.data && 'warn_list' in response.data.meta && response.data.meta.warn_list) {
          response.data.meta.warn_list.forEach(warnMsg => {
            if (warnMsg.message) {
              Vue.$toast(warnMsg.message, {
                type: TYPE.WARNING,
                position: POSITION.TOP_CENTER,
              })
            }
          })
        }

        if ('data' in response && !isString(response.data) && 'status' in response.data && (response.data.status === 401 || response.data.status === 400)) {
          Vue.$toast(response.data, {
            type: TYPE.ERROR,
            position: POSITION.TOP_CENTER,
          })
        }

        hideLoader()
        return response
      },
      error => {
        const { config, response } = error
        const originalRequest = config
        hideLoader()
        if (response && 'data' in response && 'meta' in response.data && 'error_list' in response.data.meta && response.data.meta.error_list && 'status' in response && response.status !== 412) {
          response.data.meta.error_list.forEach(errorMsg => {
            if (errorMsg.message) {
              Vue.$toast(errorMsg.message, {
                type: TYPE.ERROR,
                position: POSITION.TOP_CENTER,
              })
            }
          })
        }

        if (response && 'data' in response && 'meta' in response.data && 'error_list' in response.data.meta && response.data.meta.error_list && 'status' in response && response.status === 412) {
          response.data.meta.error_list.forEach(errorMsg => {
            if (errorMsg.message) {
              const { copy } = useClipboard()
              const swalMessage = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-block btn-primary',
                },
                buttonsStyling: false,
              })
              swalMessage.fire({
                title: 'Error',
                text: errorMsg.message.replace(/\\u([0-9a-fA-F]{4})/g, (match, group1) => String.fromCharCode(parseInt(group1, 16))),
                type: 'error',
                icon: 'error',
                width: '800px',
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonText: 'Copy message, close it!',
                cancelButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-warning ml-1',
                },
              }).then(result => {
                if (result.isConfirmed) {
                  copy(errorMsg.message)
                  Vue.$toast('Error message has been copied to clipboard.', {
                    type: TYPE.DEFAULT,
                    position: POSITION.TOP_CENTER,
                  })
                }
              })
            }
          })
        }

        if (response && response.status === 401) {
          this.removeToken()
          window.location.href = `${siteUrl}/login`
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  removeToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem('loginDate')
    localStorage.removeItem('userData')
    localStorage.removeItem('currentTenant')
  }

  isExpired() {
    const token = this.getToken()
    if (token) {
      const decoded = JSON.parse(atob(token.split('.')[1]))
      const res = decoded.exp < Date.now() / 1000
      if (res) {
        this.removeToken()
      }
      return res
    }
    return false
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
