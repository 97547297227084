import find from 'lodash/find'

/**
 * Enum for Task Priority.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const priorityEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'All Priorities' },
  LOW: { key: 'LOW', value: 'LOW', text: 'Low' },
  MEDIUM: { key: 'MEDIUM', value: 'MEDIUM', text: 'Medium' },
  HIGH: { key: 'HIGH', value: 'HIGH', text: 'High' },
})

function getPriorityEnumValue(predicate, predicateKey = 'key') {
  const result = find(priorityEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getPriorityEnumList() {
  const options = []
  Object.keys(priorityEnum).forEach(key => {
    options.push({
      value: priorityEnum[key].value,
      text: priorityEnum[key].text,
    })
  })
  return options
}

export default priorityEnum

export {
  getPriorityEnumValue,
  getPriorityEnumList,
}
