const fbm = {
  FbmItemReservationController: {
    base: '/api/fbm/item/reservation',
    createReservation: () => `${fbm.FbmItemReservationController.base}`,
    getReservedItems: fbmOrderItemId => `${fbm.FbmItemReservationController.base}/${fbmOrderItemId}`,
    deleteReservation: id => `${fbm.FbmItemReservationController.base}/${id}`,
    getOrderReservedItems: fbmOrderId => `${fbm.FbmItemReservationController.base}/order/${fbmOrderId}`,
    makeBulkReservation: () => `${fbm.FbmItemReservationController.base}/bulk`,
    cancelBulkReservation: () => `${fbm.FbmItemReservationController.base}/bulk/delete`,

  },
  Controller: {
    base: '/api/fbm/force',
    getFbmStoreSummary: pageable => `${fbm.Controller.base}/store/summary?${pageable}`,
    getFbmOrders: pageable => `${fbm.Controller.base}/order/query?${pageable}`,
    findFbmOrderById: fbmOrderId => `${fbm.Controller.base}/order/${fbmOrderId}`,
    getItems: fbmOrderId => `${fbm.Controller.base}/items/${fbmOrderId}`,
    getOrderAllInfo: () => `${fbm.Controller.base}/orders/all/info`,
    // MANUAL ORDERS
    importManualOrders: (warehouseId, storeId) => `${fbm.Controller.base}/csv/warehouse/${warehouseId}/store/${storeId}/import`,
    uploadManualOrders: (warehouseId, storeId) => `${fbm.Controller.base}/csv/warehouse/${warehouseId}/store/${storeId}/import`,
    deleteManualOrders: () => `${fbm.Controller.base}/manual/delete/orders`,
    downloadTemplate: () => `${fbm.Controller.base}/csv/download/template`,
    pullStoreOrders: storeId => `${fbm.Controller.base}/pull-store-orders/${storeId}`,
    // ADDRESSES
    getWarehouseAddress: () => `${fbm.Controller.base}/warehouse/address`,
    updateShippingAddress: fbmOrderId => `${fbm.Controller.base}/customer/address/${fbmOrderId}`,
    getCustomerAddress: fbmOrderId => `${fbm.Controller.base}/customer/address/${fbmOrderId}`,
    // CHARGES
    getAllCharges: fbmOrderId => `${fbm.Controller.base}/charges/${fbmOrderId}`,
    createCharges: () => `${fbm.Controller.base}/charges`,
    deleteCharge: chargeId => `${fbm.Controller.base}/charges/${chargeId}`,
    deleteChargesInBulk: () => `${fbm.Controller.base}/bulk/charges/delete`,
    getDefaultCharges: fbmOrderId => `${fbm.Controller.base}/order/${fbmOrderId}/default/charges`,
    // LABELS
    purchaseLabel: () => `${fbm.Controller.base}/label/psh/purchase`,
    getShippingLabels: fbmOrderId => `${fbm.Controller.base}/label/${fbmOrderId}`,
    getShippingLabelRates: () => `${fbm.Controller.base}/label/psh/rates`,
    deleteShippingLabel: labelId => `${fbm.Controller.base}/label/${labelId}`,
    saveCustomerShippingLabel: () => `${fbm.Controller.base}/label/custom`,
    getCustomCarriers: () => `${fbm.Controller.base}/label/custom/carriers`,
    createSellerCentralShippingLabel: fbmOrderId => `${fbm.Controller.base}/order/${fbmOrderId}/seller/central/shipping/label/create`,
    getAmazonShippingLabelDocs: (fbmOrderId, labelId) => `${fbm.Controller.base}/order/${fbmOrderId}/label/${labelId}/amazon/download`,
    buyBulkShippingLabel: () => `${fbm.Controller.base}/bulk/shipping`,
    updateBulkShippingLabel: () => `${fbm.Controller.base}/bulk/shipping`,
    buyLabelAndMarkAsShipped: () => `${fbm.Controller.base}/bulk/purchase/mark/ship`,
    downloadLabelsInBulk: () => `${fbm.Controller.base}/bulk/merged/labels/download`,
    markAsShippedInBulk: () => `${fbm.Controller.base}/bulk/mark/ship`,
    checkItemsReservations: () => `${fbm.Controller.base}/bulk/order/items`,
    checkItemsReservationData: () => `${fbm.Controller.base}/bulk/order/items/data`,
    // ADMIN
    matchAwaitingItems: storeId => `${fbm.Controller.base}/match/awating/items/store/${storeId}`,
    reserveAwaitingItems: storeId => `${fbm.Controller.base}/reserve/awating/items/store/${storeId}`,
    // DIMENSIONS
    getBoxDimensions: fbmOrderId => `${fbm.Controller.base}/order/${fbmOrderId}/default/box/dimensions`,
    // BOX
    createBoxItems: labelId => `${fbm.Controller.base}/box/item/label/${labelId}`,
    getRemainingReservedItems: fbmOrderId => `${fbm.Controller.base}/box/item/reserved/remaining/items/${fbmOrderId}`,
    deleteBoxItems: labelId => `${fbm.Controller.base}/box/item/label/${labelId}`,
    // SHIPMENT
    markAsShipped: fbmOrderId => `${fbm.Controller.base}/order/${fbmOrderId}/mark/as/shipped`,
    cancelShipment: fbmOrderId => `${fbm.Controller.base}/order/${fbmOrderId}/cancel/all/shipment`,
    reopenShipment: fbmOrderId => `${fbm.Controller.base}/order/${fbmOrderId}/reopen/all/shipment`,
    // WEB REFRESH
    getWebOrders: () => `${fbm.Controller.base}/web/view/orders`,
    refreshOrders: () => `${fbm.Controller.base}/web/refresh/orders`,
    pullOrder: (storeId, orderId) => `${fbm.Controller.base}/web/view/orders/store/${storeId}/search/${orderId}`,
    // PICK PACK LIST
    createPickBatch: () => `${fbm.Controller.base}/pick/batch`,
    getPickBatches: () => `${fbm.Controller.base}/pick/batches`,
    getPickBatch: pickBatchId => `${fbm.Controller.base}/pick/batch/${pickBatchId}/items`,
    deletePickBatch: pickBatchId => `${fbm.Controller.base}/pick/batch/${pickBatchId}`,
    deletePickBatchItem: pickBatchItemId => `${fbm.Controller.base}/pick/batch/item/${pickBatchItemId}`,
    updatePickBatchItem: () => `${fbm.Controller.base}/pick/batch/item`,

  },

}
export default {
  fbm,
}
