const mailTopicCompany = {
  Controller: {
    base: '/api/mail/mail-topic-company',
    findAll: params => `${mailTopicCompany.Controller.base}${params}`,
    findById: id => `${mailTopicCompany.Controller.base}/${id}`,
    create: () => `${mailTopicCompany.Controller.base}`,
    update: () => `${mailTopicCompany.Controller.base}`,
    delete: id => `${mailTopicCompany.Controller.base}/${id}`,
    fetchByCompanyIdAndMailTopicId: (companyId, mailTopicId) => `${mailTopicCompany.Controller.base}/company/${companyId}/mail-topic/${mailTopicId}`,
    updateCompanyMailTopicSubcribedUsers: (companyId, mailTopicId) => `${mailTopicCompany.Controller.base}/company/${companyId}/mail-topic/${mailTopicId}/subcribe`,
  },
}
export default {
  mailTopicCompany,
}
