/**
 * Enum for FBM Resource type.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const fbmResourceTypeEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'All' },
  API2CART: { key: 'API2CART', value: 'Api 2 Cart' },
  SALESORDER: { key: 'SALESORDER', value: 'Sales Order' },
})

export default fbmResourceTypeEnum
