const products = {

  Controller: {
    base: '/api/product/products',
    // products Endpoints
    // findAll: pageable => `${products.Controller.base}?${pageable}`,
    findById: id => `${products.Controller.base}/id/${id}`,
    findByIdList: () => `${products.Controller.base}/ids`,
    // findByUPC: upc => `${products.Controller.base}/upc/${upc}`,
    findDetailsById: id => `${products.Controller.base}/details/${id}`,
    findByASINAndPackType: params => `${products.Controller.base}/asin/${params.companyId}/${params.asin}/${params.packType}`,
    // findByTitle: title => `${products.Controller.base}/title/${title}`,
    assignPlan: planId => `${products.Controller.base}/assign-plan/${planId}`,
    updatePlan: (productId, planId) => `${products.Controller.base}/update-plan/${productId}/plan/${planId}`,
    findByQuery: pageable => `${products.Controller.base}/query?${pageable}`,
    getByQueryForWorkingBatch: () => `${products.Controller.base}/query/working-batch`,
    findDefaultCharges: () => `${products.Controller.base}/default-charges`,
    findProductAndCharges: () => `${products.Controller.base}/product-and-default-charges`,
    updateDefaultCharges: id => `${products.Controller.base}/update-default-charges/${id}`,
    create: () => `${products.Controller.base}`,
    update: () => `${products.Controller.base}/update`,
    delete: id => `${products.Controller.base}/${id}`,
    deleteMany: () => `${products.Controller.base}/delete-many`,
    findCodesById: id => `${products.Controller.base}/codes/product-id/${id}`,
    findProductDataByIds: () => `${products.Controller.base}/product-data-by-ids`,
    getProductBundleInfo: (companyId, asin) => `${products.Controller.base}/company/${companyId}/asin/${asin}`,
    getProductDataById: productId => `${products.Controller.base}/product-data-by-id/${productId}`,
  },
}
export default {
  products,
}
