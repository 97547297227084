import find from 'lodash/find'

/**
 * Enum for Task Priority.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const supportTicketStatusEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'All Status' },
  OPEN: { key: 'OPEN', value: 'OPEN', text: 'Open' },
  WORKING: { key: 'WORKING', value: 'WORKING', text: 'Working' },
  RESOLVED: { key: 'RESOLVED', value: 'RESOLVED', text: 'Resolved' },
  PLANNED: { key: 'PLANNED', value: 'PLANNED', text: 'Planned' },
})

function getSupportTicketStatusEnumValue(predicate, predicateKey = 'key') {
  const result = find(supportTicketStatusEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getSupportTicketStatusList() {
  const options = []
  Object.keys(supportTicketStatusEnum).forEach(key => {
    options.push({
      value: supportTicketStatusEnum[key].value,
      text: supportTicketStatusEnum[key].text,
    })
  })
  return options
}

export default supportTicketStatusEnum

export {
  getSupportTicketStatusEnumValue,
  getSupportTicketStatusList,
}
