const warehouse = {
  warehouseController: {
    base: '/api/company/warehouse',
    findAll: pageable => `${warehouse.warehouseController.base}?${pageable}`,
    updateName: (warehouseId, name) => `${warehouse.warehouseController.base}/${warehouseId}/name/${name}`,
  },
}
export default {
  warehouse,
}
