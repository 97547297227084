<template>
    <div>
        <dx-util-select-box
        :data-source="options"
        value-expr="value"
        search-expr="text"
        display-expr="text"
        :value="value"
        :show-clear-button="showClearButton"
        :search-enabled="searchEnabled"
        @value-changed="valueChangedEvent"
        />
    </div>
</template>

<script>
import find from 'lodash/find'
import { ValueToEnum } from '@robustshell/utils/index'
import is from 'ramda/es/is'

export default {
  props: {
    showClearButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    enum: {
      type: Object,
      required: true,
    },
    valueChanged: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    options: [],
  }),
  computed: {
    selected: {
      get() {
        if (is(String, this.value)) {
          return ValueToEnum(this.enum, this.value).value
        }
        return this.value
      },
      set(val) {
        const value = find(this.options, e => e.value === val)
        if (value) {
          this.$emit('input', val)
        }
      },
    },
  },
  mounted() {
    Object.keys(this.enum).map(key => this.options.push({ value: this.enum[key].value, text: this.enum[key].text, disabled: this.enum[key].disabled }))
  },
  methods: {
    valueChangedEvent(e) {
      const val = {
        previousValue: e.previousValue,
        value: e.value,
      }
      this.valueChanged(val)
    },
  },
}
</script>
