export default {
  namespaced: true,
  state() {
    return {
      uploadedShipmentItemsParams: {},
    }
  },
  mutations: {
    setUploadedShipmentItemsParams(state, payload) {
      state.uploadedShipmentItemsParams = {
        storeId: payload.storeId,
        companyId: payload.companyId,
        batchId: payload.batchId,
        batchName: payload.batchName,
        shipDate: payload.shipDate,
      }
    },
  },
  actions: {
    setUploadedShipmentItemsParams(context, payload) {
      context.commit('setUploadedShipmentItemsParams', payload)
    },
  },
  getters: {
    getUploadedShipmentItemsParams(state) {
      return state.uploadedShipmentItemsParams
    },
  },
}
