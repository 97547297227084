/**
 * Enum for value type.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const valueTypeEnum = Object.freeze({
  INTEGER: { key: 'INTEGER', value: 'INTEGER' },
  BIGDECIMAL: { key: 'BIGDECIMAL', value: 'BIGDECIMAL' },
  JSON: { key: 'JSON', value: 'JSON' },
  STRING: { key: 'STRING', value: 'STRING' },
  BOOLEAN: { key: 'BOOLEAN', value: 'BOOLEAN' },
  DATE: { key: 'DATE', value: 'DATE' },
  ARRAY: { key: 'ARRAY', value: 'ARRAY' },
})

export default valueTypeEnum
