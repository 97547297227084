/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

Vue.use(VueLoading, {
  canCancel: false, // default false
  color: '#fff',
  loader: 'spinner',
  transition: 'fade',
  width: 48,
  height: 48,
  backgroundColor: '#283046',
  lockScroll: true,
  blur: '4px',
  opacity: 0.6,
  zIndex: 9999,
  isFullPage: true,
},
{})
