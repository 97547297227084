/**
 * Enum for FBM Order Item Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const fbmOrderItemStatusEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'Status (All)' },
  // OPEN: { key: 'OPEN', value: 'OPEN', text: 'Open' },
  AWAITING: { key: 'AWAITING', value: 'AWAITING', text: 'Awaiting' },
  PARTIALLY_SHIPPED: { key: 'PARTIALLY_SHIPPED', value: 'PARTIALLY_SHIPPED', text: 'Partially Shipped' },
  // CANCELED: { key: 'CANCELED', value: 'CANCELED', text: 'Canceled' },
  // DELIVERED: { key: 'DELIVERED', value: 'DELIVERED', text: 'Delivered' },
  // RETURN: { key: 'RETURN', value: 'RETURN', text: 'Return' },
  SHIPPED: { key: 'SHIPPED', value: 'SHIPPED', text: 'Shipped' },
})

function getFBMOrderItemStatusEnumList() {
  const options = []
  Object.keys(fbmOrderItemStatusEnum).map(key => options.push({ value: fbmOrderItemStatusEnum[key].value, text: fbmOrderItemStatusEnum[key].text }))
  return options
}

export {
  getFBMOrderItemStatusEnumList,
}

export default fbmOrderItemStatusEnum
