import find from 'lodash/find'
/**
 * Enum for Merchant Connect Underwriting Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const minimumPriceEnum = Object.freeze({
  MINIMUM_LISTED_PRICE: { key: 'MINIMUM_LISTED_PRICE', value: 'MINIMUM_LISTED_PRICE', text: 'Minimum Listed Price' },
})

function getMinimumPriceEnum(predicate, predicateKey = 'key') {
  const result = find(minimumPriceEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getMinimumPriceList() {
  const options = []
  Object
    .keys(minimumPriceEnum)
    .map(key => options.push(
      {
        value: minimumPriceEnum[key].value,
        text: minimumPriceEnum[key].text,
      },
    ))
  return options
}

export default minimumPriceEnum

export {
  getMinimumPriceEnum,
  getMinimumPriceList,
}
