const notification = {
  Controller: {
    base: '/api/notification',
    findAll: params => `${notification.Controller.base}?${params}`,
    findById: id => `${notification.Controller.base}/${id}`,
    create: () => `${notification.Controller.base}`,
    update: () => `${notification.Controller.base}`,
    pushNotification: () => `${notification.Controller.base}/push-notification`,
    popNotification: () => `${notification.Controller.base}/pop-notification`,
    fetchAll: pageable => `${notification.Controller.base}/pop-notification?${pageable}`,
    popNotificationUnReadCount: () => `${notification.Controller.base}/pop-notification-unread-count`,
    updateNotification: () => `${notification.Controller.base}/update-notification`,
    notificationList: () => `${notification.Controller.base}/notification-topic-list`,
    fetchSubscribedUsersByCompanyIdAndNotificationTopicName: (companyId, notificationTopicName) => `${notification.Controller.base}/subscribed-user-list/company/${companyId}/topic/${notificationTopicName}`,
    updateCompanyNotificationTopicSubscribedUsers: (companyId, topic) => `${notification.Controller.base}/company/${companyId}/topic/${topic}/subscribe`,
    delete: id => `${notification.Controller.base}/${id}`,
  },
}
export default {
  notification,
}
