const sortItem = {

  controller: {
    sort: '/api/inbound/sort-item',

    getItemsByQuery: pageable => `${sortItem.controller.sort}/item/query?${pageable}`,
    trackItemsByQuery: pageable => `${sortItem.controller.sort}/item/track?${pageable}`,
    getByClientShipmentItemId: clientShipmentItemId => `${sortItem.controller.sort}/item/track/client-shipmen-item-id/${clientShipmentItemId}`,
    getBatchesByQuery: pageable => `${sortItem.controller.sort}/batch/query?${pageable}`,
    create: () => `${sortItem.controller.sort}`,
    update: () => `${sortItem.controller.sort}`,
    updateQuantity: () => `${sortItem.controller.sort}/update-quantity`,
    updateOrderNumber: params => `${sortItem.controller.sort}/update-order-number/${params.id}/${params.orderNumber}`,
    moveItemToInventory: () => `${sortItem.controller.sort}/move-items-to-inventory`,
    setSortCompleted: sortBatchId => `${sortItem.controller.sort}/set-sort-batch/${sortBatchId}`,
    batchSortedItems: () => `${sortItem.controller.sort}/batch-sorted-items`,
    delete: id => `${sortItem.controller.sort}/${id}`,
  },
}
export default {
  sortItem,
}
