import find from 'lodash/find'
/**
 * Enum for Merchant Connect Underwriting Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const listPriceEnum = Object.freeze({
  MATCH_BUY_BOX: { key: 'MATCH_BUY_BOX', value: 'MATCH_BUY_BOX', text: 'Match Buy Box' }, // Match Buy Box
  PRICE_FORMULA: { key: 'PRICE_FORMULA', value: 'PRICE_FORMULA', text: 'Price Formula' }, // Price Formula
  MANUAL_PRICING: { key: 'MANUAL_PRICING', value: 'MANUAL_PRICING', text: 'I Will Set My Own Price' }, // Manual Pricing
  FROM_UPLOADED_FILE: { key: 'FROM_UPLOADED_FILE', value: 'FROM_UPLOADED_FILE', text: 'From Uploaded File' }, // Copy Price
})

function getListPriceEnum(predicate, predicateKey = 'key') {
  const result = find(listPriceEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getListPriceList() {
  const options = []
  Object
    .keys(listPriceEnum)
    .map(key => options.push(
      {
        value: listPriceEnum[key].value,
        text: listPriceEnum[key].text,
      },
    ))
  return options
}

export default listPriceEnum

export {
  getListPriceEnum,
  getListPriceList,
}
