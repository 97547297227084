import find from 'lodash/find'
/**
 * Enum for customer plan status.
 * @readonly
 * @enum {{key: string, text: string, value: number}}
 */
const customerPlanVisibilityEnum = Object.freeze({
  ALL: {
    value: 'all',
    text: 'Visibility (All)',
    key: 'ALL',
  },
  PUBLIC: {
    value: 0,
    text: 'Public',
    key: 'PUBLIC',
  },
  PRIVATE: {
    value: 1,
    text: 'Private',
    key: 'PRIVATE',
  },
})

function getCustomerPlanVisibilityEnum(predicate, predicateKey = 'key') {
  const result = find(customerPlanVisibilityEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getCustomerPlanVisibilityList() {
  return Object.keys(customerPlanVisibilityEnum).map(key => ({ value: customerPlanVisibilityEnum[key].value, text: customerPlanVisibilityEnum[key].text }))
}

function getCustomerPlanVisibilityOptions() {
  return Object.keys(customerPlanVisibilityEnum).map(key => ({ value: customerPlanVisibilityEnum[key].value, text: customerPlanVisibilityEnum[key].text })).filter(item => item.value !== 'all')
}

export default customerPlanVisibilityEnum

export {
  getCustomerPlanVisibilityEnum,
  getCustomerPlanVisibilityList,
  getCustomerPlanVisibilityOptions,
}
