const mailLog = {
  Controller: {
    base: '/api/mail/mail-log',
    findAll: params => `${mailLog.Controller.base}${params}`,
    findById: id => `${mailLog.Controller.base}/${id}`,
    create: () => `${mailLog.Controller.base}`,
    update: () => `${mailLog.Controller.base}`,
    delete: id => `${mailLog.Controller.base}/${id}`,
  },
}
export default {
  mailLog,
}
