export default [
  {
    path: '/apps/tools/batch-verify',
    name: 'tools-batch-verify',
    component: () => import('@/views/apps/tools/BatchVerify.vue'),
    meta: {
      resource: 'resources_common',
      action: 'read',
      pageTitle: 'Barcode Scanning',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Tools',
        },
        {
          text: 'Barcode Scanning',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/tools/shipping-label',
    name: 'shipping-label',
    component: () => import('@/views/apps/tools/ShippingLabel.vue'),
    meta: {
      resource: 'resource_tools_shipping_label',
      action: 'read',
      pageTitle: 'Buy Shipping Label',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Tools',
        },
        {
          text: 'Buy Shipping Label',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/tools/print-label',
    name: 'print-label',
    component: () => import('@/views/apps/tools/PrintLabel.vue'),
    meta: {
      resource: 'resources_common',
      action: 'read',
      pageTitle: 'Manual Print Label',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Tools',
        },
        {
          text: 'Manual Print Label',
          active: true,
        },
      ],
    },
  },
]
