/**
 * Enum for scope type.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const scopeTypeEnum = Object.freeze({
  TENANTSCOPEFRONTEND: { key: 'TENANT_SCOPE_FRONTEND', value: 'TENANT_SCOPE_FRONTEND' },
  TENANTSCOPEBACKEND: { key: 'TENANT_SCOPE_BACKEND', value: 'TENANT_SCOPE_BACKEND' },
})

export default scopeTypeEnum
