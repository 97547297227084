import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async findAll(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.settings.controller.findAll(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async findById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.settings.controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async getByCompany(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.settings.controller.getByCompany(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async getByReference(referenceType, referenceId, referenceKey) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.settings.controller.getByReference(referenceType, referenceId, referenceKey))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async getByReferenceKey(referenceType, referenceId, referenceKey) {
    const result = await axios.get(endpoints.settings.controller.getByReference(referenceType, referenceId, referenceKey))
    return result.data.body
  },

  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.settings.controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async createOrUpdate(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.settings.controller.createOrUpdate(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.settings.controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async delete(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.settings.controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async getNextMskuId(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.commonEntities.Controller.getNextMskuId(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
}
