// Paket adı
const company = {
  // O paketin içindeki conrollerin adı
  Controller: {
    // Controllorın url prefixi
    base: '/api/company',
    // Performs the login process with the given username and password information and returns loginresponse object
    findAll: pageable => `${company.Controller.base}?${pageable}`,
    findByCompanyType: companyType => `${company.Controller.base}/company-type/${companyType}`,
    createVendorByName: () => `${company.Controller.base}/company-type/vendor`,
    findById: id => `${company.Controller.base}/${id}`,
    findByQuery: pageable => `${company.Controller.base}/query?${pageable}`,
    getCompanyCount: () => `${company.Controller.base}/pending/customer/count`,
    findByAccountNo: accountId => `${company.Controller.base}/account/${accountId}`,
    findByHub: hub => `${company.Controller.base}/hub/${hub}`,
    findAllMatchingNameAndType: params => `${company.Controller.base}/name/${params.name}/type/${params.type}`,
    findAllInfoByAccountNo: accountNo => `${company.Controller.base}/id/all-info/${accountNo}`,
    findCompanyAllInfoByCompanyId: companyId => `${company.Controller.base}/all-info-by-company-id/${companyId}`,
    findUsersByCompanyId: (id, pageable) => `${company.Controller.base}/${id}/users?${pageable}`,
    findCompanyAndStoresByAccountNo: accountNo => `${company.Controller.base}/acc/company-and-stores/${accountNo}`,
    findAuthoritiesByCompanyType: companyType => `${company.Controller.base}/authorities/${companyType}`,
    findAllWarehouses: () => `${company.Controller.base}/warehouse`,
    deleteCompanyUserByUserId: userId => `${company.Controller.base}/users/${userId}`,
    createCompanyUser: () => `${company.Controller.base}/create-company-user`,
    updateCompanyUser: () => `${company.Controller.base}/update-company-user`,
    create: () => `${company.Controller.base}`,
    update: () => `${company.Controller.base}`,
    delete: id => `${company.Controller.base}/${id}`,
    upsert: () => `${company.Controller.base}/upsert`,
    activate: (id, status) => `${company.Controller.base}/change-status/${id}/${status}`,
    findAccountManagerByAccountNo: accountNo => `${company.Controller.base}/account/accountManager/${accountNo}`,
    activateAccountRequestEmail: () => `${company.Controller.base}/activate-request-email`,
    getCompanyMerchantConnectInfo: () => `${company.Controller.base}/get-company-merchant-connect-info`,
    updateWorkingDays: companyId => `${company.Controller.base}/update/${companyId}/workingDays`,
    findAllTasksByQuery: pageable => `${company.Controller.base}/task/query?${pageable}`,
    findTaskById: taskId => `${company.Controller.base}/task/${taskId}`,
    createTask: () => `${company.Controller.base}/task`,
    updateTask: () => `${company.Controller.base}/task/upsert`,
    deleteTask: taskId => `${company.Controller.base}/task/${taskId}`,
    getWorkingDayCompanies: workingDay => `${company.Controller.base}/working/day/${workingDay}`,
    getWorkingDaysForDashboard: companyId => `${company.Controller.base}/task/calendar/working/days/${companyId}`,
    getCalendarPageQuery: pageable => `${company.Controller.base}/task/calendar/query?${pageable}`,
    generateTasks: () => `${company.Controller.base}/task/calendar/generate/batch`,
    getCalendarByCompanyId: companyId => `${company.Controller.base}/task/calendar/company/${companyId}`,
    updateCompanyAverageMonthlyVolume: (companyId, volume) => `${company.Controller.base}/task/calendar/company/${companyId}/monthly/item/${volume}`,
    saveCalendar: () => `${company.Controller.base}/task/calendar/update`,
    upsertCalendar: companyId => `${company.Controller.base}/task/calendar/upsert/company/${companyId}`,
    getTasksForDashboard: () => `${company.Controller.base}/task/dashboard/query`,
    ticket: '/api/company/support/ticket',
    queryTickets: () => `${company.Controller.ticket}/query`,
    findTicket: id => `${company.Controller.ticket}/${id}`,
    createTicket: () => `${company.Controller.ticket}`,
    updateTicket: () => `${company.Controller.ticket}`,
    upsertTicket: () => `${company.Controller.ticket}/upsert`,
    deleteTicket: id => `${company.Controller.ticket}/${id}`,
    getIssueTypes: () => `${company.Controller.ticket}/issue/types`,
    getSupportStaff: () => `${company.Controller.ticket}/psh/users`,
    expense: '/api/company/expense',
    getExpensesByQuery: pageable => `${company.Controller.expense}/query?${pageable}`,
    getExpenseById: id => `${company.Controller.expense}/${id}`,
    createExpense: () => `${company.Controller.expense}`,
    updateExpense: () => `${company.Controller.expense}`,
    upsertExpense: () => `${company.Controller.expense}/upsert`,
    deleteExpense: id => `${company.Controller.expense}/${id}`,
    income: '/api/company/income',
    getIncomesByQuery: pageable => `${company.Controller.income}/query?${pageable}`,
    getIncomeById: id => `${company.Controller.income}/${id}`,
    createIncome: () => `${company.Controller.income}`,
    updateIncome: () => `${company.Controller.income}`,
    upsertIncome: () => `${company.Controller.income}/upsert`,
    deleteIncome: id => `${company.Controller.income}/${id}`,
    category: '/api/company/income/expense',
    getIncomeExpenseCategoriesByQuery: pageable => `${company.Controller.category}/category/query?${pageable}`,
    getIncomeExpenseCategoryById: id => `${company.Controller.category}/category/${id}`,
    createIncomeExpenseCategory: () => `${company.Controller.category}/category`,
    updateIncomeExpenseCategory: () => `${company.Controller.category}/category`,
    upsertIncomeExpenseCategory: () => `${company.Controller.category}/category/upsert`,
    deleteIncomeExpenseCategory: id => `${company.Controller.category}/category/${id}`,

  },
  eCommerceSiteController: {
    base: '/api/company/ecommercesite',
    findAll: () => `${company.eCommerceSiteController.base}`,
    findAllActive: () => `${company.eCommerceSiteController.base}/active`,
    findList: () => `${company.eCommerceSiteController.base}/list`,
    findListActive: () => `${company.eCommerceSiteController.base}/list/active`,
  },
}
export default {
  company,
}
