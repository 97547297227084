/**
 * Enum for IncoTerm.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const IncotermEnum = Object.freeze({
  DDU: { key: 'DDU', value: 'DDU', text: 'DDU (bill recipient)' },
  DDP: { key: 'DDP', value: 'DDP', text: 'DDP (bill sender)' },
  FCA: { key: 'FCA', value: 'FCA', text: 'FCA' },
})

/**
 * Enum for PurposeOfShipment.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const PurposeOfShipmentEnum = Object.freeze({
  merchandise: { key: 'merchandise', value: 'MERCHANDISE', text: 'Merchandise' },
  documents: { key: 'documents', value: 'DOCUMENTS', text: 'Documents' },
  gift: { key: 'gift', value: 'GIFT', text: 'Gift' },
  sample: { key: 'sample', value: 'SAMPLE', text: 'Sample' },
  returnMerchandise: { key: 'returnMerchandise', value: 'RETURN_MERCHANDISE', text: 'Return Merchandise' },
  humanitarianDonation: { key: 'humanitarianDonation', value: 'HUMANITARIAN_DONATION', text: 'Humanitarian Donation' },
  other: { key: 'other', value: 'OTHER', text: 'Other' },
})

/**
 * Enum for ReturnHandling.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const ReturnHandlingEnum = Object.freeze({
  RETURN: { key: 'RETURN', value: 'RETURN', text: 'Return' },
  ABANDON: { key: 'ABANDON', value: 'ABANDON', text: 'Abandon' },
})

/**
 * Enum for TaxIdType.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const TaxIdTypeEnum = Object.freeze({
  VAT: { key: 'VAT', value: 'VAT', text: 'VAT' },
  IOSS: { key: 'IOSS', value: 'IOSS', text: 'IOSS' },
  EIN: { key: 'EIN', value: 'EIN', text: 'EIN' },
})

/**
 * Enum for EEL.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const EELEnum = Object.freeze({
  'NOEEI 30 37(a)': { key: 'NOEEI 30 37(a)', value: 'NOEEI 30 37(a)', text: 'NOEEI 30 37(a)' },
  'NOEEI 30 37(f)': { key: 'NOEEI 30 37(f)', value: 'NOEEI 30 37(f)', text: 'NOEEI 30 37(f)' },
  'NOEEI 30 37(h)': { key: 'NOEEI 30 37(h)', value: 'NOEEI 30 37(h)', text: 'NOEEI 30 37(h)' },
  'NOEEI 30 36': { key: 'NOEEI 30 36', value: 'NOEEI 30 36', text: 'NOEEI 30 36' },
  'AES / ITN': { key: 'AES / ITN', value: 'AES / ITN', text: 'AES / ITN' },
})

function enumToArray(enumObj) {
  return Object.values(enumObj).map(item => ({ value: item.value, text: item.text }))
}

function getEnumValues(enumType) {
  switch (enumType) {
    case 'Incoterm':
      return enumToArray(IncotermEnum)
    case 'PurposeOfShipment':
      return enumToArray(PurposeOfShipmentEnum)
    case 'TaxIdType':
      return enumToArray(TaxIdTypeEnum)
    case 'EEL':
      return enumToArray(EELEnum)
    case 'ReturnHandling':
      return enumToArray(ReturnHandlingEnum)
    default:
      return []
  }
}

export {
  getEnumValues,
  IncotermEnum,
  PurposeOfShipmentEnum,
  TaxIdTypeEnum,
  ReturnHandlingEnum,
  EELEnum,
}
