export default {
  namespaced: true,
  state() {
    return {
      initial: false,
      accountInfo: {},
      printerSettings: {},
    }
  },
  mutations: {
    setAccountInfo(state, payload) {
      state.initial = true
      state.accountInfo = {
        accountNo: payload.accountNo,
        companyId: payload.companyId,
        companyName: payload.companyName,
        storeId: payload.storeId,
        storeName: payload.storeName,
      }
    },
    setPrinterSettings(state, payload) {
      state.printerSettings = { ...payload }
    },
  },
  actions: {
    setAccountInfo(context, payload) {
      context.commit('setAccountInfo', payload)
    },
    setPrinterSettings(context, payload) {
      context.commit('setPrinterSettings', payload)
    },
  },
  getters: {
    getAccountInfo(state) {
      return state.accountInfo
    },
    getPrinterSettings(state) {
      return state.printerSettings
    },
  },
}
