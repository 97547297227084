const wfs = {
  controller: {
    base: '/api/outbound/wfs',
    /**
     * Http POST /api/outbound/wfs/auth/create
     * @returns {string}
     */
    createAuth: () => `${wfs.controller.base}/auth/create`,

    /**
     * Http PUT /api/outbound/wfs/auth/update
     * @returns {string}
     */
    updateAuth: () => `${wfs.controller.base}/auth/update`,

    /**
     * Http GET /api/outbound/wfs/auth/token
     * @returns {object}
     */
    getAppAuthorization: () => `${wfs.controller.base}/auth/token`,

    /**
     * Http GET /api/outbound/plan/{id}
     * @param id {number}
     * @returns {object}
     */
    getShipmentPlan: id => `${wfs.controller.base}/plan/${id}`,

    /**
     * Http POST /api/outbound/wfs/plan/query
     * @param query {object}
     * @returns {object}
     */
    getShipmentPlansByQuery: pageable => `${wfs.controller.base}/plan/query?${pageable}`,

    /**
     * Http GET /api/outbound/wfs/plan/{id}/stats
     * @param id {number}
     * @returns {object}
     */
    getShipmentPlanStats: id => `${wfs.controller.base}/plan/${id}/stats`,

    /**
     * Http POST /api/outbound/wfs/plan/create
     * @returns {string}
     */
    createShipmentPlan: () => `${wfs.controller.base}/plan/create`,

    /**
     * Http PUT /api/outbound/wfs/plan/create
     * @returns {string}
     */
    updateShipmentPlan: () => `${wfs.controller.base}/plan/update`,

    /**
     * Http DELETE /api/outbound/wfs/plan/{id}
     * @param id {number}
     * @returns {string}
     */
    deleteShipmentPlan: id => `${wfs.controller.base}/plan/${id}`,

    /**
     * Http PUT /api/outbound/wfs/plan/close/{id}
     * @param id {number}
     */
    closeShipmentPlan: id => `${wfs.controller.base}/plan/close/${id}`,

    /**
     * Http GET /api/outbound/wfs/plan/batch/{shipmentPlanId}
     * @param shipmentPlanId {number}
     * @returns {object}
     */
    getBatchDataByShipmentPlanId: shipmentPlanId => `${wfs.controller.base}/plan/batch/${shipmentPlanId}`,

    /**
     * Http POST /api/outbound/wfs/plan/items/query
     * @returns {string}
     * @param query {object}
     */
    getShipmentPlanItemDataByQuery: () => `${wfs.controller.base}/plan/items/query`,

    /**
     * Http PUT /api/outbound/wfs/plan/submit/{planId}
     * @param planId {number}
     * @returns {string}
     */
    submitShipmentPlan: (planId, deliveryDate) => `${wfs.controller.base}/plan/submit/${planId}/${deliveryDate}`,

    /**
     * Http GET /api/outbound/wfs/plan/items/{id}
     * @param id {number}
     * @returns {string}
     */
    getShipmentPlanItemById: id => `${wfs.controller.base}/plan/items/${id}`,

    /**
     * Http POST /api/outbound/wfs/plan/items/create
     * @returns {string}
     * @param data {object}
     */
    createShipmentPlanItem: () => `${wfs.controller.base}/plan/items/create`,

    /**
     * Http PUT /api/outbound/wfs/plan/items/update/quantity
     * @returns {string}
     * @param data {object}
     */

    updateShipmentPlanItemQuantity: () => `${wfs.controller.base}/plan/items/update/quantity`,

    /**
     * Http PUT /api/outbound/wfs/plan/items/update
     * @returns {string}
     * @param data {object}
     */
    updateShipmentPlanItem: () => `${wfs.controller.base}/plan/items/update`,

    /**
     * Http DELETE /api/outbound/wfs/plan/items/delete/{id}
     * @param {*} id
     * @returns
     */
    deleteShipmentPlanItem: id => `${wfs.controller.base}/plan/items/delete/${id}`,
    /**
     * Http POST /api/outbound/wfs/items/search
     * @returns {string}
     * @param data {object}
     */
    searchItem: () => `${wfs.controller.base}/items/search`,

    /**
     * HTTP POST /api/outbound/wfs/plan/items/query/batch
     * @returns {object}
     * @param query {object}
     */
    getShipmentPlanItemDataByQueryBatch: () => `${wfs.controller.base}/plan/items/query/batch`,

    /**
     * Http GET /api/outbound/wfs/plan/batch/can-transmit-batch/{batchId}
     * @returns {object}
     * @param id {number}
     */
    canTransmitBatch: id => `${wfs.controller.base}/plan/batch/can-transmit-batch/${id}`,

    /**
      * Http GET /api/outbound/wfs/plan/batch/box/{id}
      * @returns {object}
      * @param id {number}
      */
    findBoxById: id => `${wfs.controller.base}/plan/batch/box/${id}`,

    /**
     * Http GET /api/outbound/wfs/plan/batch/boxes/{batchId}
     * @returns {object}
     * @param batchId {number}
     */
    findAllBoxesByBatchId: batchId => `${wfs.controller.base}/plan/batch/boxes/${batchId}`,

    /**
     * Http POST /api/outbound/wfs/plan/batch/box
     * @returns {object}
     */
    createBox: () => `${wfs.controller.base}/plan/batch/box`,

    /**
     * Http PUT /api/outbound/wfs/plan/batch/box
     * @returns {object}
     */
    updateBoxDimensions: () => `${wfs.controller.base}/plan/batch/box`,

    /**
     * Http DELETE /api/outbound/wfs/plan/batch/box/{boxId}
     */
    deleteBatchBoxById: boxId => `${wfs.controller.base}/plan/batch/box/${boxId}`,

    /**
     * Http DELETE /api/outbound/wfs/plan/batch/box/all/{batchId}
     */
    deleteAllBatchBoxesByBatchId: batchId => `${wfs.controller.base}/plan/batch/box/all/${batchId}`,

    /**
     * Http GET /api/outbound/wfs/plan/items/box-map/batch/{batchId}
     * @returns {object}
     * @param batchId {number}
     */
    getShipmentPlanItemBoxMapItemByBatch: batchId => `${wfs.controller.base}/plan/items/box-map/batch/${batchId}`,

    /**
     * Http GET /api/outbound/wfs/plan/items/box-map/box/{boxId}
     * @returns {object}
     * @param boxId {number}
     */
    getShipmentPlanItemBoxMapItemByBox: () => `${wfs.controller.base}/plan/items/box-map/query`,

    /**
     * Http POST /api/outbound/wfs/plan/items/box/scan-item
     */
    scanItemMapByCode: () => `${wfs.controller.base}/plan/items/box/scan-item`,

    /**
     * Http PUT /api/outbound/wfs/plan/items/box/scan-item
     */
    updateItemMapQuantity: () => `${wfs.controller.base}/plan/items/box/scan-item`,

    /**
     * Http GET /api/outbound/wfs/plan/items/box/bulk
     * @returns {object}
     */
    assignBulkBoxItemMap: () => `${wfs.controller.base}/plan/items/box/bulk`,

    /**
     * Http POST /api/outbound/wfs/plan/items/box/auto-assign
     */

    autoAssignBoxItemMap: () => `${wfs.controller.base}/plan/items/box/auto-assign`,

    /**
     * Http DELETE /api/outbound/wfs/plan/items/box/{id}
     * @param id {number}
     */
    deleteShipmentBoxMapById: id => `${wfs.controller.base}/plan/items/box/item/${id}`,

    /**
     * Http DELETE /api/outbound/wfs/plan/items/box/batch/{batchId}
     * @param batchId {number}
     */
    deleteShipmentBoxMapsByBatch: batchId => `${wfs.controller.base}/plan/items/box/batch/${batchId}`,

    /**
     * Http POST /api/outbound/wfs//plan/batch/shipping/label/quote/create
     */
    getCarrierRateQuote: () => `${wfs.controller.base}/plan/batch/shipping/label/quote/create`,

    /**
     * Http PUT /api/outbound/wfs/plan/batch/shipping/label/quote/{batchId}/confirm
     * @param batchId {number}
     */
    confirmCarrierRateQuote: batchId => `${wfs.controller.base}/plan/batch/shipping/label/quote/${batchId}/confirm`,

    /**
     * Http PUT /api/outbound/wfs/plan/batch/shipping/label/{batchId}/void
     * @param batchId {number}
     */
    voidCarrierRateQuote: batchId => `${wfs.controller.base}/plan/batch/shipping/label/${batchId}/void`,

    /**
     * Http GET /api/outbound/wfs/plan/batch/shipping/label/{batchId}/print
     * @param batchId {number}
     */
    printCarrierLabel: batchId => `${wfs.controller.base}/plan/batch/shipping/label/${batchId}/print`,

    /**
   * Http GET /api/outbound/wfs/settings/shipping/template
   * @returns {object}
   * @param storeId {number}
   * @param templateId {number}
   */
    getShippingTemplateDetails: (storeId, templateId) => `${wfs.controller.base}/settings/shipping/template/${storeId}/${templateId}`,

    /**
   * Http GET /api/outbound/wfs/settings/shipping/templates
   * @returns {object}
   * @param storeId {number}
   */
    getShippingTemplates: storeId => `${wfs.controller.base}/settings/shipping/templates/${storeId}`,

    /**
   * Http POST /api/outbound/wfs/settings/shipping/template
   * @returns {string}
   * @param storeId {number}
   * @param templateId {number}
   */
    createShippingTemplates: storeId => `${wfs.controller.base}/settings/shipping/template/${storeId}`,

    /**
   * Http PUT /api/outbound/wfs/settings/shipping/template
   * @returns {object}
   * @param storeId {number}
   * @param templateId {number}
   */
    updateShippingTemplates: (storeId, templateId) => `${wfs.controller.base}/settings/shipping/template/${storeId}/${templateId}`,

    /**
   * Http DELETE /api/outbound/wfs/settings/shipping/template
   * @returns {string}
   * @param storeId {number}
   * @param templateId {number}
   */
    deleteShippingTemplates: (storeId, templateId) => `${wfs.controller.base}/settings/shipping/template/${storeId}/${templateId}`,

    /**
     * Http Puy /api/outbound/wfs/plan/item/update/{id}/plan/{planId}/apply-all/{applyAll}
     * @returns {string}
     */

    updateShipmentPlanItemPlanId: (id, planId, applyAll) => `${wfs.controller.base}/plan/item/update/${id}/plan/${planId}/apply-all/${applyAll}`,
  },
}
export default {
  wfs,
}
