const itemCharge = {

  Controller: {
    base: '/api/prep/item-charge',

    // itemCharge Endpoints
    findById: id => `${itemCharge.Controller.base}/${id}`,
    findAllByItemId: id => `${itemCharge.Controller.base}/item-id/${id}`,
    findPrepMatInfoById: id => `${itemCharge.Controller.base}/info/${id}`,
    // set params as key1=value1&key1=value1&...
    // params options: storage, shipping, prep_item, prep_material1, prep_material2, prep_material3, other(json object)
    addCostById: (id, params) => `${itemCharge.Controller.base}/update/id?${params}`,
    create: itemId => `${itemCharge.Controller.base}/${itemId}`,
    update: () => `${itemCharge.Controller.base}`,
    delete: id => `${itemCharge.Controller.base}/${id}`,
  },
}
export default {
  itemCharge,
}
