const pricing = {

  Controller: {
    base: '/api/pricing',

    // pricing Endpoints
    checkMembership: () => `${pricing.Controller.base}`,
    getPlans: () => `${pricing.Controller.base}/plans`,
    getPlan: plan => `${pricing.Controller.base}/plan/${plan}`,
  },
}
export default {
  pricing,
}
