export default [
  {
    path: '/dashboard',
    name: 'dashboard-home',
    component: () => import('@/views/dashboard/Index.vue'),
    meta: {
      resource: 'resources_home',
      action: 'read',
    },
  },
  {
    path: '/dashboard/basic-reporting',
    name: 'dashboard-basic-reporting',
    component: () => import('@/views/dashboard/basic-reporting/BasicReporting.vue'),
    meta: {
      resource: 'resource_dashboards_dashboard_basic_stats',
      action: 'read',
      pageTitle: 'Business Stats',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Dashboards',
        },
        {
          text: 'Business Stats',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/summary',
    name: 'dashboard-summary',
    component: () => import('@/views/dashboard/summary/Summary.vue'),
    meta: {
      resource: 'resource_dashboards_dashboard_summary',
      action: 'read',
      pageTitle: 'Item Tracking',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Dashboards',
        },
        {
          text: 'Item Tracking',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/snapshot',
    name: 'dashboard-statistics',
    component: () => import('@/views/dashboard/statistics/Index.vue'),
    meta: {
      resource: 'resource_dashboards_dashboard_snapshot',
      action: 'read',
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Dashboards',
        },
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/worker-performance',
    name: 'dashboard-worker-performance',
    component: () => import('@/views/dashboard/leader-board/WorkerPerformance.vue'),
    meta: {
      resource: 'resource_dashboards_dashboard_basic_stats',
      action: 'read',
      pageTitle: 'Leader Board',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Dashboards',
        },
        {
          text: 'Leader Board',
          active: true,
        },
      ],
    },
  },
]
