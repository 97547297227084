import find from 'lodash/find'
/**
 * Enum for Merchant Connect Underwriting Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const amzResolutionEnum = Object.freeze({
  OPEN: { key: 'OPEN', value: 'OPEN', text: 'Open' },
  CASE_INITIATED: { key: 'CASE_INITIATED', value: 'CASE_INITIATED', text: 'Case Initiated' },
  RESOLVED: { key: 'RESOLVED', value: 'RESOLVED', text: 'Resolved' },
})

function getAmzResolutionEnum(predicate, predicateKey = 'key') {
  const result = find(amzResolutionEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getAmzResolutionList() {
  const options = []
  Object
    .keys(amzResolutionEnum)
    .map(key => options.push(
      {
        value: amzResolutionEnum[key].value,
        text: amzResolutionEnum[key].text,
      },
    ))
  return options
}

export default amzResolutionEnum

export {
  getAmzResolutionEnum,
  getAmzResolutionList,
}
