const inventory = {

  Controller: {
    base: '/api/inventory',
    getAvailableItems: pageable => `${inventory.Controller.base}/available?${pageable}`,
    findById: id => `${inventory.Controller.base}/${id}`,
    // findAllByCompanyId: (companyId, pageable) => `${inventory.Controller.base}/company/${companyId}?${pageable}`,
    // findAllByCompanyIdAndStoreId: (companyId, storeId, pageable) => `${inventory.Controller.base}/company/${companyId}/store/${storeId}?${pageable}`,
    getByQuery: pageable => `${inventory.Controller.base}/query?${pageable}`,
    inventorySnapshot: pageable => `/api/reporting/item-tracking/inventory-snapshot?${pageable}`,
    countItemsByCompanyIdAndStoreAndAction: query => `${inventory.Controller.base}/item-count?${query}`,
    getItemsByAsinAndParams: query => `${inventory.Controller.base}/reconcile-items?${query}`,
    getItemsByLocation: pageable => `${inventory.Controller.base}/items-by-location?${pageable}`,
    getItemsByProduct: pageable => `${inventory.Controller.base}/items-by-product?${pageable}`,
    moveInventory: () => `${inventory.Controller.base}/move-inventory`,
    moveInventoryByLocation: () => `${inventory.Controller.base}/move-inventory-by-location`,
    createOrAdd: () => `${inventory.Controller.base}`,
    decrementItemQuantity: () => `${inventory.Controller.base}/update-quantity`,
    updateReserveItemsByProductId: () => `${inventory.Controller.base}/update-reserved`,
    update: () => `${inventory.Controller.base}/update`,
    delete: id => `${inventory.Controller.base}/${id}`,
    deleteMany: () => `${inventory.Controller.base}/delete-many`,
    mergeMskuFnsku: () => `${inventory.Controller.base}/merge-msku-fnsku`,
    getByIds: () => `${inventory.Controller.base}/ids`,
    amazonInventoryQuery: pageable => `${inventory.Controller.base}/amazon/query?${pageable}`,
  },
}
export default {
  inventory,
}
