const packageScan = {

  Controller: {
    base: '/api/inbound/package-scan',

    findAllSortedItemsByBatchId: (batchId, pageable) => `${packageScan.Controller.base}/sorted-batch-items/${batchId}?${pageable}`,
    findAllSortedItemsByPackageScanId: packId => `${packageScan.Controller.base}/sorted-batch-items/pack/${packId}`,
    findAllInfoByBatchId: (batchId, pageable) => `${packageScan.Controller.base}/info/${batchId}?${pageable}`,
    findAllSortBatchesByUser: (userId, pageable) => `${packageScan.Controller.base}/sorted-batches/${userId}?${pageable}`,
    findByTracking: tracking => `${packageScan.Controller.base}/tracking/${tracking}`,
    setSortCompleted: sortBatchId => `${packageScan.Controller.base}/set-sort-batch/${sortBatchId}`,
    createMany: () => `${packageScan.Controller.base}/create-many`,
    create: () => `${packageScan.Controller.base}`,
    update: () => `${packageScan.Controller.base}/update`,
    // params: {id: 'batchId', condition: 'ok|damaged'}
    changeCondition: params => `${packageScan.Controller.base}/${params.id}/${params.condition}`,
    delete: id => `${packageScan.Controller.base}/${id}`,
    deleteMany: () => `${packageScan.Controller.base}/delete-many`,
    assignLocation: () => `${packageScan.Controller.base}/assign-location`,
    getItemsByQuery: pageable => `${packageScan.Controller.base}/query?${pageable}`,
  },
}
export default {
  packageScan,
}
