const itemCharge = {

  Controller: {
    base: '/api/prep/item-charge',

    // prepItemCost Endpoints
    findById: id => `${itemCharge.Controller.base}/${id}`,
    findItemChargesByItemId: id => `${itemCharge.Controller.base}/info/${id}`,
    // set params as key1=value1&key1=value1&...
    // params options: storage, shipping, prep_item, prep_material1, prep_material2, prep_material3, other(json object)
    // addCostById: (id, params) => `${prepItemCost.Controller.base}/update/id?${params}`,
    // create: () => `${prepItemCost.Controller.base}`,
    // update: () => `${prepItemCost.Controller.base}`,
    // delete: id => `${prepItemCost.Controller.base}/${id}`,
  },
}
export default {
  itemCharge,
}
