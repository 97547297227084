import { BatchStatusEnum } from '~/enums'

export default {
  namespaced: true,
  state() {
    return {
      initial: false,
      batchAccountInfo: {},
      selectedBactInfo: {},
      printerSettings: {},
    }
  },
  mutations: {
    setBatchAccountInfo(state, payload) {
      state.initial = true
      state.batchAccountInfo = {
        accountNo: payload.accountNo,
        companyId: payload.companyId,
        companyName: payload.companyName,
        storeId: payload.storeId,
        storeName: payload.storeName,
      }
    },
    setSelectedBactInfo(state, payload) {
      const status = []
      Object.keys(BatchStatusEnum).map(key => {
        if (!BatchStatusEnum[key].disabled) {
          const currentStatus = BatchStatusEnum[key].value === payload.batchStatus
          return status.push({
            key: BatchStatusEnum[key].key,
            value: BatchStatusEnum[key].value,
            text: BatchStatusEnum[key].text,
            currentStatus: currentStatus,
          })
        }
        return null
      })

      let shipmentItem = {}
      if (!Object.is(payload.shipmentItem) && !Object.is(payload.shipmentItem)) {
        shipmentItem = payload.shipmentItem
      }

      state.selectedBactInfo = {
        accountNo: state.batchAccountInfo.accountNo,
        companyId: state.batchAccountInfo.companyId,
        companyName: state.batchAccountInfo.companyName,
        storeId: state.batchAccountInfo.storeId,
        storeName: state.batchAccountInfo.storeName,
        packingType: payload.packingType,
        batchName: payload.batchName,
        batchId: payload.id,
        batchStatus: status,
        shipmentItem: shipmentItem,
        shipToCountryCode: payload.shipToCountryCode,
      }
    },
    setPrinterSettings(state, payload) {
      state.printerSettings = { ...payload }
    },
  },
  actions: {
    setBatchAccountInfo(context, payload) {
      context.commit('setBatchAccountInfo', payload)
    },
    setSelectedBactInfo(context, payload) {
      context.commit('setSelectedBactInfo', payload)
    },
    setPrinterSettings(context, payload) {
      context.commit('setPrinterSettings', payload)
    },
  },
  getters: {
    getBatchAccountInfo(state) {
      return state.batchAccountInfo
    },
    getSelectedBactInfo(state) {
      return state.selectedBactInfo
    },
    getPrinterSettings(state) {
      return state.printerSettings
    },
  },
}
