const planManagement = {

  Controller: {
    type_base: '/api/plan/management/type',
    plan_base: '/api/plan/management',
    enroll_base: '/api/plan/enrollment',

    // planType Endpoints
    findAllPlanTypes: () => `${planManagement.Controller.type_base}`,
    createPlanType: () => `${planManagement.Controller.type_base}`,
    updatePlanType: () => `${planManagement.Controller.type_base}`,
    deletePlanTypeById: id => `${planManagement.Controller.type_base}/${id}`,

    // planManagement Endpoints
    findPlanById: id => `${planManagement.Controller.plan_base}/${id}`,
    findAllPlans: pageable => `${planManagement.Controller.plan_base}?${pageable}`,
    findAllByParentId: id => `${planManagement.Controller.plan_base}/parent/${id}`,
    findAllByWarehouse: id => `${planManagement.Controller.plan_base}/warehouse/${id}`,
    findAllPlansByQuery: pageable => `${planManagement.Controller.plan_base}/query?${pageable}`,
    createPlan: () => `${planManagement.Controller.plan_base}`,
    updatePlan: () => `${planManagement.Controller.plan_base}`,
    deletePlanById: id => `${planManagement.Controller.plan_base}/${id}`,

    // planEnrollment Endpoints
    findUserPlanById: id => `${planManagement.Controller.enroll_base}/${id}`,
    findAllForCurrentUser: () => `${planManagement.Controller.enroll_base}`,
    findAllMyUserPlansByQuery: (params, pageable) => `${planManagement.Controller.enroll_base}/my-plans?warehouseId=${params.warehouseId}&planType=${params.planType}&${pageable}`,
    findAllUserPlansByQuery: pageable => `${planManagement.Controller.enroll_base}/query?${pageable}`,
    findAllPlansByCompanyId: companyId => `${planManagement.Controller.enroll_base}/company/${companyId}`,
    findAllPlansByStatus: status => `${planManagement.Controller.enroll_base}/status/${status}`,
    createPlanEnrollment: () => `${planManagement.Controller.enroll_base}`,
    deleteEnrollmentById: id => `${planManagement.Controller.enroll_base}/${id}`,
    changeStatus: params => `${planManagement.Controller.enroll_base}/change-status/${params.planId}/${params.status}`,
    leavePlan: id => `${planManagement.Controller.enroll_base}/leave-plan/${id}`,
    applyDiscount: params => `${planManagement.Controller.enroll_base}/apply-discount/${params.planId}/${params.discount}`,
  },
}
export default {
  planManagement,
}
