const shipmentLabels = {
  Controller: {
    base: '/api/external/ship/rate/label',
    getAllShippingLabels: pageable => `${shipmentLabels.Controller.base}/query?${pageable}`,
    getShippingLabelByReference: (referenceKey, referenceId) => `${shipmentLabels.Controller.base}/${referenceKey}/${referenceId}`,
    getTrackingsWithSameReference: id => `${shipmentLabels.Controller.base}/${id}`,
    getShippingRates: () => `${shipmentLabels.Controller.base}/list/rates`,
    purchaseShippingLabel: () => `${shipmentLabels.Controller.base}/purchase`,
    voidShippingLabel: id => `${shipmentLabels.Controller.base}/void/${id}`,
    getFbaBoxDimensions: batchId => `${shipmentLabels.Controller.base}/fba/box/dimensions/${batchId}`,

    international: '/api/external/ship/international',
    getCustomsDeclaration: (referenceKey, referenceId) => `${shipmentLabels.Controller.international}/customs/declaration/${referenceKey}/${referenceId}`,
    updateCustomsDeclaration: () => `${shipmentLabels.Controller.international}/customs/declaration`,
    getProductDefaults: inventoryId => `${shipmentLabels.Controller.international}/customs/items/default/inventory/${inventoryId}`,
    deleteCustomsDeclaration: id => `${shipmentLabels.Controller.international}/customs/declaration/${id}`,
  },
}

export default {
  shipmentLabels,
}
