const api2Cart = {
  controller: {
    /*
    * Cart Authorization
    */
    cart: '/api/external/api2cart/cart',
    loginCartParams: prepStoreId => `${api2Cart.controller.cart}/login-params/prepStore/${prepStoreId}`,
    getLoginCartCredentialType: prepStoreId => `${api2Cart.controller.cart}/login-credential_type/prepStore/${prepStoreId}`,
    cartList: () => `${api2Cart.controller.cart}/list`,
    create: prepStoreId => `${api2Cart.controller.cart}/create-or-update/prepStore/${prepStoreId}`,
    createEbayCart: () => `${api2Cart.controller.cart}/create-ebay-cart`,
    createShopifyCart: () => `${api2Cart.controller.cart}/create-shopify-cart`,
    disconnect: prepStoreId => `${api2Cart.controller.cart}/disconnect/prepStore/${prepStoreId}`,
    delete: prepStoreId => `${api2Cart.controller.cart}/delete/prepStore/${prepStoreId}`,
    order: '/api/external/api2cart/order',
    pullStoreOrders: prepStoreId => `${api2Cart.controller.order}/pull-store-orders/${prepStoreId}`,
  },
}
export default {
  api2Cart,
}
