const includeText = '9261'
export default function truncateTracking(tracking) {
  const text = tracking.trim()
  if (text.toLowerCase().startsWith('1LS') || text.toLowerCase().startsWith('1Z')) {
    // 1LS = LS, 1Z = UPS
    return text
  }
  if (text.includes(includeText) && text.endsWith(includeText, 12) && text.length > 15) {
    // USPS
    return text.substring(8)
  }
  if (text.length === 34) {
    // FedEX express
    return text.substring(text.length - 12)
  }
  if (text.length === 22 || text.length === 32) {
    // FedEx Ground
    return text.substring(text.length - 12)
  }
  return text
}
