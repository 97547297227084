const fbmOrder = {
  controller: {
    /*
    * FBM Order
    */
    order: '/api/fbm/order',
    findQuery: pageable => `${fbmOrder.controller.order}/query?${pageable}`,
    updatePrepProductId: (fbmOrderItemId, prepProductId, mskuFnskuPairId) => `${fbmOrder.controller.order}/order-item/${fbmOrderItemId}/set-prep-product-id/${prepProductId}/${mskuFnskuPairId}`,
    unMatchPrepProduct: fbmOrderItemId => `${fbmOrder.controller.order}/order-item/${fbmOrderItemId}/un-match`,
    updateQuantity: () => `${fbmOrder.controller.order}/order-item/update-quantity`,
  },
}
export default {
  fbmOrder,
}
