import find from 'lodash/find'
/**
 * Enum for Merchant Connect Underwriting Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const pricingBasesEnum = Object.freeze({
  HIGHER_THAN_BUY_BOX: { key: 'HIGHER_THAN_BUY_BOX', value: 'HIGHER_THAN_BUY_BOX', text: 'Higher Than Buy Box' },
  LESS_THAN_BUY_BOX: { key: 'LESS_THAN_BUY_BOX', value: 'LESS_THAN_BUY_BOX', text: 'Less Than Buy Box' },
})

function getPricingBase(predicate, predicateKey = 'key') {
  const result = find(pricingBasesEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getPricingBaseList() {
  const options = []
  Object
    .keys(pricingBasesEnum)
    .map(key => options.push(
      {
        value: pricingBasesEnum[key].value,
        text: pricingBasesEnum[key].text,
      },
    ))
  return options
}

export default pricingBasesEnum

export {
  getPricingBase,
  getPricingBaseList,
}
