const membershipSubscribers = {
  Controller: {
    base: '/api/membership/subscribers',
    create: () => `${membershipSubscribers.Controller.base}`,
    delete: id => `${membershipSubscribers.Controller.base}/${id}`,
    cancel: planId => `${membershipSubscribers.Controller.base}/cancel/${planId}`,
    update: () => `${membershipSubscribers.Controller.base}`,
    getListByFilter: planId => `${membershipSubscribers.Controller.base}/list/${planId}`,
    changeToYearly: planId => `${membershipSubscribers.Controller.base}/upgrade-to-yearly/${planId}`,
    getByPageableQuery: pageable => `${membershipSubscribers.Controller.base}/query?${pageable}`,
    findById: id => `${membershipSubscribers.Controller.base}/${id}`,
    cancelCompanyPlan: (companyId, planId) => `${membershipSubscribers.Controller.base}/admin/cancel/company/${companyId}/plan/${planId}`,
  },
}

export default { membershipSubscribers }
