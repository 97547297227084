const costGroup = {

  Controller: {
    base: '/api/finance/cost-group',

    // costGroup Endpoints
    findAll: pageable => `${costGroup.Controller.base}?${pageable}`,
    findAllList: () => `${costGroup.Controller.base}/list`,
    findById: id => `${costGroup.Controller.base}/${id}`,
    create: () => `${costGroup.Controller.base}`,
    update: () => `${costGroup.Controller.base}`,
    delete: id => `${costGroup.Controller.base}/${id}`,
  },
}
export default {
  costGroup,
}
