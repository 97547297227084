/**
 * Enum for multiple item tracking delivery status options.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const deliveryStatusEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'All' },
  NOT_DELIVERED: { key: 'NOT_DELIVERED', value: 'NOT_DELIVERED', text: 'Not Delivered' },
  NOT_FOUND: { key: 'NOT_FOUND', value: 'NOT_FOUND', text: 'Not Found' },
  DELIVERED: { key: 'DELIVERED', value: 'DELIVERED', text: 'Delivered' },
})

function getDeliveryStatusList() {
  const options = []
  Object
    .keys(deliveryStatusEnum)
    .map(key => options.push(
      {
        value: deliveryStatusEnum[key].value,
        text: deliveryStatusEnum[key].text,
      },
    ))
  return options
}

export default deliveryStatusEnum

export {
  getDeliveryStatusList,
}
