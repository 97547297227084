const settings = {
  controller: {
    base: '/api/common/settings',
    findAll: params => `${settings.controller.base}?${params}`,
    findById: id => `${settings.controller.base}/${id}`,
    getByReference: (referenceType, referenceId, referenceKey) => `${settings.controller.base}/get-by-reference/${referenceType}/${referenceId}/${referenceKey}`,
    getByCompany: companyId => `${settings.controller.base}/get-by-company/${companyId}`,
    create: () => `${settings.controller.base}`,
    createOrUpdate: () => `${settings.controller.base}/create-or-update`,
    update: () => `${settings.controller.base}`,
    delete: id => `${settings.controller.base}/${id}`,
  },
}
export default {
  settings,
}
