export default {
  namespaced: true,
  state() {
    return {
      selectedAccountInfo: {},
    }
  },
  mutations: {
    setSelectedAccountInfo(state, payload) {
      state.selectedAccountInfo = {
        accountNo: payload.accountNo,
        companyId: payload.companyId,
        companyName: payload.companyName,
      }
    },
  },
  actions: {
    setSelectedAccountInfo(context, payload) {
      context.commit('setSelectedAccountInfo', payload)
    },
  },
  getters: {
    getSelectedAccountInfo(state) {
      return state.selectedAccountInfo
    },
  },
}
