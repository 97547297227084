export default {
  namespaced: true,
  state() {
    return {
      notificationReadInfo: {},
    }
  },
  mutations: {
    setNotificationReadInfo(state, payload) {
      state.notificationReadInfo = {
        readTime: payload.readTime,
        nextReadTime: payload.nextReadTime,
      }
    },
  },
  actions: {
    setNotificationReadInfo(context, payload) {
      context.commit('setNotificationReadInfo', payload)
    },
  },
  getters: {
    getNotificationReadInfo(state) {
      return state.notificationReadInfo
    },
  },
}
