const prepMaterialPurchase = {

  Controller: {
    base: '/api/prep-material/purchase',

    // prepMaterialPurchase Endpoints
    findByQuery: pageable => `${prepMaterialPurchase.Controller.base}/query?${pageable}`,
    findById: id => `${prepMaterialPurchase.Controller.base}/${id}`,
    create: () => `${prepMaterialPurchase.Controller.base}`,
    update: () => `${prepMaterialPurchase.Controller.base}`,
    delete: id => `${prepMaterialPurchase.Controller.base}/${id}`,
  },
}
export default {
  prepMaterialPurchase,
}
