import * as R from 'ramda'
import { JsonToQueryString } from '@robustshell/utils/index'

export default class Pageable {
  constructor(pageable = {}) {
    if (R.is(Number, pageable.pageNumber) && pageable.pageNumber > 0) {
      this.pageNumber = pageable.pageNumber
    } else {
      this.pageNumber = 1
    }

    this.pageSize = R.is(Number, pageable.pageSize) ? pageable.pageSize : 5
    this.totalElements = R.is(Number, pageable.totalElements) ? pageable.totalElements : 0
    this.offset = this.pageSize
  }

  toCreatePageblePayload() {
    return {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      offset: this.offset,
    }
  }

  toCreatePayload() {
    return {
      pageable: this.toCreatePageblePayload(),
      totalElements: this.totalElements,
    }
  }

  toCreateQueryString() {
    return JsonToQueryString({
      page: this.pageNumber - 1,
      size: this.pageSize,
    })
  }

  setContext(pageable = {}) {
    this.totalElements = R.is(Number, pageable.totalElements) ? pageable.totalElements : 0
  }

  setPageNumber(value) {
    if (R.is(Number, value) && value > 0) {
      this.pageNumber = value
    } else {
      this.pageNumber = 1
    }
  }

  setNumberOfElements(value) {
    this.pageSize = R.is(Number, value) ? value : 20
    this.offset = this.pageSize
  }

  setTotalElementsElements(value) {
    this.totalElements = R.is(Number, value) ? value : 0
  }
}
