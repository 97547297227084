import find from 'lodash/find'
/**
 * Enum for Granularity (Amazon  Sales API v1)
 * The granularity of the grouping of order metrics, based on a unit of time.
 * Specifying granularity=Hour results in a successful request only if the interval
 * specified is less than or equal to 30 days from now. For all other granularities,
 * the interval specified must be less or equal to 2 years from now. Specifying
 * granularity=Total results in order metrics that are aggregated over the entire
 * interval that you specify. If the interval start and end date don’t align with
 * the specified granularity, the head and tail end of the response interval will
 * contain partial data. E.g.: Day to get a daily breakdown of the request interval,
 * where the day boundary is defined by the granularityTimeZone.
 * @readonly
 * @enum {{key: string, value: string|null, text: string}}
 */

const granularityEnum = Object.freeze({
  MONTH: { key: 'MONTH', value: 'Month', text: 'Month' },
  YEAR: { key: 'YEAR', value: 'Year', text: 'Year' },
  TOTAL: { key: 'TOTAL', value: 'Total', text: 'Total' },
})

function getGranularityEnum(predicate, predicateKey = 'key') {
  const result = find(granularityEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: -1, text: 'None' }
}

function getGranularityList() {
  const options = []
  Object
    .keys(granularityEnum)
    .map(key => options.push(
      {
        value: granularityEnum[key].value,
        text: granularityEnum[key].text,
      },
    ))
  return options
}

export default granularityEnum

export {
  getGranularityEnum,
  getGranularityList,
}
