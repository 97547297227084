export default [
  /**
  * User
  */

  {
    path: '/apps/admin/swagger',
    name: 'swagger',
    component: () => import('@/views/apps/admin/Swagger.vue'),
    meta: {
      resource: 'resource_admin',
      action: 'manage',
      pageTitle: 'Swagger',
    },
  },
  {
    path: '/apps/admin/resource-management',
    name: 'resource-management',
    component: () => import('@/views/apps/admin/ResourceManagement.vue'),
    meta: {
      resource: 'resource_admin',
      action: 'manage',
      pageTitle: 'Resource Management',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Admin',
        },
        {
          text: 'Resource Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/admin/maintanence',
    name: 'maintenance',
    component: () => import('@/views/apps/admin/Maintenance.vue'),
    meta: {
      resource: 'resource_admin',
      action: 'manage',
      pageTitle: 'Maintenance',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Admin',
        },
        {
          text: 'Maintenance',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/admin/icons',
    name: 'psh-icons',
    component: () => import('@/views/apps/admin/PSHIcons.vue'),
    meta: {
      resource: 'resource_admin',
      action: 'manage',
      pageTitle: 'PSH Icons',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Admin',
        },
        {
          text: 'PSH Icons',
          active: true,
        },
      ],
    },
  },

]
