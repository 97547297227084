const goshippo = {
  controller: {
    /*
    * Carrier
    */
    carrier: '/api/external/goshippo/carrier',
    // carrierList: () => `${goshippo.controller.carrier}/service`,
    // updateCarrierService: () => `${goshippo.controller.carrier}/update-carrier-service`,
    // findLastOperationByShippingId: orderId => `${goshippo.controller.carrier}/shipment-operation/last/${orderId}`,
    // findOrCreateByShippingId: orderId => `${goshippo.controller.carrier}/shipment-operation/find-or-create/${orderId}`,
    // orderShipProcess: fbmOrderId => `${goshippo.controller.carrier}/shipment-operation/fbm/order/${fbmOrderId}/process`,
    // saveShipmentOperation: () => `${goshippo.controller.carrier}/shipment-operation/save`,
    // getRatesShipmentOperation: () => `${goshippo.controller.carrier}/shipment-operation/save-and-get-rates`,
    // selectRateShipmentOperation: (shippingOperationId, rateId) => `${goshippo.controller.carrier}/shipment-operation/${shippingOperationId}/select-rate/${rateId}`,
    // createShippingLabelShipmentOperation: shippingOperationId => `${goshippo.controller.carrier}/shipment-operation/create-shipping-label/${shippingOperationId}`,
    // voidShippingLabelShipmentOperation: shippingOperationId => `${goshippo.controller.carrier}/shipment-operation/void-shipping-label/${shippingOperationId}`,
    // markAsShip: shippingOperationId => `${goshippo.controller.carrier}/shipment-operation/mark-as-ship/${shippingOperationId}`,
    // saveAndMarkAsShip: () => `${goshippo.controller.carrier}/shipment-operation/save-and-mark-as-ship`,
    // downloadLabelCountIncrement: (shippingOperationId, transactionId) => `${goshippo.controller.carrier}/shipment-operation/${shippingOperationId}/download_label_count_increment/${transactionId}`,
    // cancelShipment: fbmOrderId => `${goshippo.controller.carrier}/shipment-operation/${fbmOrderId}/cancel-shipment`,
    // saveShippingLabel: fbmOrderId => `${goshippo.controller.carrier}/shipment-operation/${fbmOrderId}/save-shipping-label`,
    // updateShippingLabel: () => `${goshippo.controller.carrier}/shipment-operation/update-shipping-label`,
    // getShippingLabels: (fbmOrderId, pageable) => `${goshippo.controller.carrier}/shipment-operation/${fbmOrderId}/get-shipping-labels?${pageable}`,
    // deleteShippingLabel: shippingLabelId => `${goshippo.controller.carrier}/shipment-operation/delete-shipping-label/${shippingLabelId}`,
    // customerProvideShippingLabelCarriers: () => `${goshippo.controller.carrier}/shipment-operation/customer-provide-shipping-label-carriers`,
    getAllShippingLabels: pageable => `${goshippo.controller.carrier}/shipping/label/query?${pageable}`,
    getTrackingsWithSameReference: id => `${goshippo.controller.carrier}/shipping/labels/${id}`,
    voidShippingLabel: id => `${goshippo.controller.carrier}/shipping/label/${id}/void`,
    getShippingRates: () => `${goshippo.controller.carrier}/shipping/label/rates`,
    purchaseShippingLabel: () => `${goshippo.controller.carrier}/shipping/label`,
    getShippingLabelByReference: (referenceKey, referenceId) => `${goshippo.controller.carrier}/shipping/label/find/${referenceKey}/${referenceId}`,
  },
}
export default {
  goshippo,
}
