import find from 'lodash/find'
/**
 * Enum for customer plan categories.
 * @readonly
 * @enum {{key: string, text: string, value: string}}
 */
const customerPlanCategoryEnum = Object.freeze({
  ALL: {
    value: 'all',
    text: 'Plan Category (All)',
    key: 'ALL',
  },
  FBA: {
    value: 'fba',
    text: 'FBA',
    key: 'FBA',
  },
  FBM: {
    value: 'fbm',
    text: 'FBM',
    key: 'FBM',
  },
  WFS: {
    value: 'wfs',
    text: 'WFS',
    key: 'WFS',
  },
  STORAGE: {
    value: 'storage',
    text: 'Storage',
    key: 'STORAGE',
  },
  FORWARDING: {
    value: 'forwarding',
    text: 'Forwarding',
    key: 'FORWARDING',
  },
  DROPSHIP: {
    value: 'dropship',
    text: 'Dropship',
    key: 'DROPSHIP',
  },
  B2B: {
    value: 'b2b',
    text: 'B2B',
    key: 'B2B',
  },
})

function getCustomerPlanCategoryEnum(predicate, predicateKey = 'key') {
  const result = find(customerPlanCategoryEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getCustomerPlanCategoryList() {
  return Object.keys(customerPlanCategoryEnum).map(key => ({ value: customerPlanCategoryEnum[key].value, text: customerPlanCategoryEnum[key].text }))
}

function getCustomerPlanCategoryOptions() {
  return Object.keys(customerPlanCategoryEnum).map(key => ({ value: customerPlanCategoryEnum[key].value, text: customerPlanCategoryEnum[key].text })).filter(item => item.value !== 'all')
}

export default customerPlanCategoryEnum

export { getCustomerPlanCategoryEnum, getCustomerPlanCategoryList, getCustomerPlanCategoryOptions }
