const maintenance = {

  Controller: {
    base: '/api/management/maintenance',

    fixParentChildAsinIssue: params => `${maintenance.Controller.base}/fix-parent-child/${params.companyId}/${params.oldAsin}/${params.newAsin}`,
    fetchInventorySummaryForStore: storeId => `${maintenance.Controller.base}/fetch-inventory-summary-for-store/${storeId}`,
    fetchMissingAmazonCatalogItems: storeId => `${maintenance.Controller.base}/fetch-missing-amazon-catalog-items/${storeId}`,
    updateProductsFromAmazonData: skip => `${maintenance.Controller.base}/update-products-from-amazon-data/${skip}`,
    updateProductsWithMissingImages: () => `${maintenance.Controller.base}/update-products-with-missing-images`,
    cleanInventory: accountNo => `${maintenance.Controller.base}/clean-inventory/${accountNo}`,
    mergeDuplicatedMsku: () => `${maintenance.Controller.base}/merge-duplicated-msku`,
    mergeMskusWithHashCode: () => `${maintenance.Controller.base}/merge-mskus-with-hashcode`,
    mergeDuplicatedFnsku: () => `${maintenance.Controller.base}/merge-duplicated-fnsku`,
    mergeDuplicatedProducts: () => `${maintenance.Controller.base}/merge/products`,
    closeInboundBatches: () => `${maintenance.Controller.base}/close-inbound-batches`,
    fillEmptyUpcFromAmazon: storeId => `${maintenance.Controller.base}/fill-empty-upc-from-amazon/${storeId}`,
  },
}
export default {
  maintenance,
}
