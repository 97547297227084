export default [
  {
    path: '/apps/account/company/:companyId',
    name: 'route-account-my-company',
    component: () => import('@/views/apps/company/MyCompany.vue'),
    meta: {
      resource: 'resource_clients_my_company',
      action: 'read',
      pageTitle: 'My Company',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Account',
        },
        {
          text: 'My Company',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/account/notifications',
    name: 'notifications',
    component: () => import('@/views/apps/account/notifications/Index.vue'),
    meta: {
      resource: 'resource_account_notifications',
      action: 'read',
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Account',
        },
        {
          text: 'Notifications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/common/credentials/account',
    name: 'route-account-company-credentials',
    component: () => import('@/views/apps/company/credentials/Credentials.vue'),
    meta: {
      resource: 'resource_account_company_credentials',
      action: 'read',
      pageTitle: 'Credentials',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Account',
        },
        {
          text: 'Credentials',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/account/company/company-user-edit',
    name: 'apps-company-user-edit',
    component: () => import('@/views/apps/company/company-user-edit/CompanyUserEdit.vue'),
    meta: {
      resource: 'resource_clients_my_company',
      action: 'read',
    },
  },
  {
    path: '/apps/account/plan/my-plans',
    name: 'route-account-plans-my-plans',
    component: () => import('@/views/apps/plan-management/customer-plan/CustomerPlan.vue'),
    meta: {
      resource: 'resource_management_customer_plan_enrollment',
      action: 'read',
      pageTitle: 'My Plans',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Account',
        },
        {
          text: 'My Plans',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/account/plan/enrollment',
    name: 'route-account-plans-plan-enrollment',
    component: () => import('@/views/apps/plan-management/plans/PlanManagement.vue'),
    meta: {
      resource: 'resource_management_customer_plan_enrollment',
      action: 'read',
      pageTitle: 'Plan Enrollment',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Account',
        },
        {
          text: 'My Plans',
          to: '/apps/account/plan/my-plans',
        },
        {
          text: 'Plan Enrollment',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/finance/invoices',
    name: 'accounting-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      resource: 'resource_accounting_invoice_list',
      action: 'read',
      pageTitle: 'Invoices',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Account',
        },
        {
          text: 'Accounting',
        },
        {
          text: 'Invoices',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/finance/transaction/:id',
    name: 'finance-transaction-void-refund',
    component: () => import('@/views/apps/finance/transaction/Index.vue'),
    meta: {
      resource: 'resource_accounting_invoice_list',
      action: 'read',
      pageTitle: 'Invoice & Transaction Details',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Account',
        },
        {
          text: 'Accounting',
        },
        {
          text: 'Invoices',
          to: '/apps/finance/invoices',
        },
        {
          text: 'Invoice & Transaction Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/account/merchant-connect',
    name: 'merchant-connect',
    component: () => import('@/views/apps/account/merchant-connect/Index.vue'),
    meta: {
      resource: 'resource_management_finance_tools',
      action: 'read',
      pageTitle: 'Merchant Connect',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Account',
        },
        {
          text: 'Merchant Connect',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/finance/customer/charges',
    name: 'route-customer-charge',
    component: () => import('@/views/apps/finance/customer-charge/CustomerCharge.vue'),
    meta: {
      resource: 'resource_account_customer_charge',
      action: 'read',
      pageTitle: 'Charges',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Account',
        },
        {
          text: 'Accounting',
        },
        {
          text: 'Charges',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/finance/customer/balances',
    name: 'route-customer-balance',
    component: () => import('@/views/apps/finance/balances/CustomerBalance.vue'),
    meta: {
      resource: 'resource_tools_shipping_label',
      action: 'read',
      pageTitle: 'Balances',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Account',
        },
        {
          text: 'Accounting',
        },
        {
          text: 'Balances',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/invoice/detail/:id',
    name: 'apps-invoice-detail',
    component: () => import('@/views/apps/invoice/invoice-detail/InvoiceDetail.vue'),
    meta: {
      resource: 'resource_accounting_invoice_list',
      action: 'read',
    },
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      resource: 'resource_accounting_invoice_list',
      action: 'read',
    },
  },
]
