<template>
  <div>
    <b-form-select
      v-model="selected"
      :options="options"
      autofocus
      :state="state"
    />
  </div>
</template>

<script>
import find from 'lodash/find'

export default {
  props: {
    state: {
      type: Boolean,
      required: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(val) {
        const value = find(this.options, e => e.value === val)
        if (value) {
          this.$emit('input', val)
        }
      },
    },
  },
  mounted() {},
}
</script>
