import find from 'lodash/find'

/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const rateTypesEnum = Object.freeze({
  PSH_GO_SHIPPO: { key: 'PSH_GO_SHIPPO', value: 'PSH_GO_SHIPPO', text: 'PSH GOSHIPPO RATES' },
  AMAZON_FBM: { key: 'AMAZON_FBM', value: 'AMAZON_FBM', text: 'AMAZON FBM RATES' },
})

function getRateTypesEnum(predicate, predicateKey = 'key') {
  const result = find(rateTypesEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getRateTypesEnumList() {
  const options = []
  Object.keys(rateTypesEnum).forEach(key => {
    options.push({
      value: rateTypesEnum[key].value,
      text: rateTypesEnum[key].text,
    })
  })
  return options
}

export default rateTypesEnum

export {
  getRateTypesEnum,
  getRateTypesEnumList,
}
