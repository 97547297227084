/**
 * Enum for managed keys.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const managedKeysEnum = Object.freeze({
  TENANTCOMPANYNAME: {
    key: 'TENANT_COMPANY_NAME',
    value: 'TENANT_COMPANY_NAME',
    text: 'Tenant Company Name',
  },
  TENANTWEBSITE: {
    key: 'TENANT_WEB_SITE',
    value: 'TENANT_WEB_SITE',
    text: 'Tenant Web Site',
  },
  WAREHOUSEWORKPHONE: {
    key: 'WAREHOUSE_WORK_PHONE',
    value: 'WAREHOUSE_WORK_PHONE',
    text: 'Warehouse Work Phone',
  },
  WAREHOUSEADDRESS: {
    key: 'WAREHOUSE_ADDRESS',
    value: 'WAREHOUSE_ADDRESS',
    text: 'Warehouse Address',
  },
  WAREHOUSEWORKFAX: {
    key: 'WAREHOUSE_WORK_FAX',
    value: 'WAREHOUSE_WORK_FAX',
    text: 'Warehouse Work Fax',
  },
  WAREHOUSEEMAIL: {
    key: 'WAREHOUSE_EMAIL',
    value: 'WAREHOUSE_EMAIL',
    text: 'Warehouse Email',
  },
  BUNDLEEXTRAFEEPERITEM: {
    key: 'BUNDLE_EXTRA_FEE_PER_ITEM',
    value: 'BUNDLE_EXTRA_FEE_PER_ITEM',
    text: 'Bundle/Multipack Extra Fee Per Item',
  },
  DAILYSTORAGEFEEPERQF: {
    key: 'DAILY_STORAGE_FEE_PER_QF',
    value: 'DAILY_STORAGE_FEE_PER_QF',
    text: 'Daily Storage Fee Per Cubic Feet',
  },
  BUNDLEMULTIPACKBASEFEE: {
    key: 'BUNDLE_MULTIPACK_BASE_FEE',
    value: 'BUNDLE_MULTIPACK_BASE_FEE',
    text: 'Bundle/Multipack Base Fee',
  },
  FREESTORAGEDAYCOUNT: {
    key: 'FREE_STORAGE_DAY_COUNT',
    value: 'FREE_STORAGE_DAY_COUNT',
    text: 'Free Storage Day Count',
  },
  MAXCHARGEDBUNDLEITEMCOUNT: {
    key: 'MAX_CHARGED_BUNDLE_ITEM_COUNT',
    value: 'MAX_CHARGED_BUNDLE_ITEM_COUNT',
    text: 'Max Charged Bundle Item Count',
  },
  FBMCHARGEISPERSKU: {
    key: 'FBM_CHARGE_IS_PER_SKU',
    value: 'FBM_CHARGE_IS_PER_SKU',
    text: 'Apply Plan Charge Per SKU',
  },
  FBMEXTRAITEMCHARGE: {
    key: 'FBM_EXTRA_ITEM_CHARGE',
    value: 'FBM_EXTRA_ITEM_CHARGE',
    text: 'FBM Extra Item Charge',
  },
  ENFORCE_PAYMENT_METHOD: {
    key: 'ENFORCE_PAYMENT_METHOD',
    value: 'ENFORCE_PAYMENT_METHOD',
    text: 'Enforce Payment Method',
  },
  TENANT_STORE_PULL_AMZ_RETURN_ORDER: {
    key: 'TENANT_STORE_PULL_AMZ_RETURN_ORDER',
    value: 'TENANT_STORE_PULL_AMZ_RETURN_ORDER',
    text: 'Tenant Store Pull Amazon FBM Return Order',
  },
  FBM_ORDER_AUTO_CLOSE_DELAY_HOUR: {
    key: 'FBM_ORDER_AUTO_CLOSE_DELAY_HOUR',
    value: 'FBM_ORDER_AUTO_CLOSE_DELAY_HOUR',
    text: 'FBM Order Auto Close Delay (Hour)',
  },
  FBM_STORE_ORDER_AUTO_CLOSE_DELAY_HOUR: {
    key: 'FBM_STORE_ORDER_AUTO_CLOSE_DELAY_HOUR',
    value: 'FBM_STORE_ORDER_AUTO_CLOSE_DELAY_HOUR',
    text: 'FBM Store Order Auto Close Delay (Hour)',
  },
  PALLETCONTACT: {
    key: 'PALLET_CONTACT',
    value: 'PALLET_CONTACT',
    text: 'Pallet Contact',
  },
  CURRENTSELECTEDPRINTER: {
    key: 'CURRENT_SELECTED_PRINTER',
    value: 'CURRENT_SELECTED_PRINTER',
    text: 'Current Selected Printer',
  },
  PROBLEMCHARGE: {
    key: 'PROBLEM_CHARGE',
    value: 'PROBLEM_CHARGE',
    text: 'Problem Charge',
  },
  PROBLEMCHARGEISPERITEM: {
    key: 'PROBLEM_CHARGE_IS_PER_ITEM',
    value: 'PROBLEM_CHARGE_IS_PER_ITEM',
    text: 'Charge Is Per Item Count',
  },
  SHIPPINGUPCHARGEMINDOLLAR: {
    key: 'SHIPPING_UPCHARGE_MIN_DOLLAR',
    value: 'SHIPPING_UPCHARGE_MIN_DOLLAR',
    text: 'Shipping Upcharge Min Dollar',
  },
  SHIPPINGUPCHARGEPERCENT: {
    key: 'SHIPPING_UPCHARGE_PERCENT',
    value: 'SHIPPING_UPCHARGE_PERCENT',
    text: 'Shipping Upcharge Percent',
  },
  ITEMSORTFEE: {
    key: 'ITEM_SORT_FEE',
    value: 'ITEM_SORT_FEE',
    text: 'Item Sort Fee',
  },
  ITEM_DISPOSAL_FEE: {
    key: 'ITEM_DISPOSAL_FEE',
    value: 'ITEM_DISPOSAL_FEE',
    text: 'Item Disposal Fee',
  },
  SHIPMENTBATCHEMAILNOTIFICATION: {
    key: 'SHIPMENT_BATCH_EMAIL_NOTIFICATION',
    value: 'SHIPMENT_BATCH_EMAIL_NOTIFICATION',
    text: 'Shipment Batch Email Notifications',
    group: 'EMAIL',
  },
  SHIPMENTBATCHEMAILNOTIFICATIONENABLED: {
    key: 'SHIPMENT_BATCH_EMAIL_NOTIFICATION_ENABLED',
    value: 'SHIPMENT_BATCH_EMAIL_NOTIFICATION_ENABLED',
    text: 'Sending Shipment Batch Emails Enabled',
  },
  SHIPMENTBATCHTOPICS: {
    key: 'SHIPMENT_BATCH_TOPICS',
    value: 'SHIPMENT_BATCH_TOPICS',
    text: 'Shipment Batch Phases',
  },
  SHIPMENTBATCHSHIPPED: {
    key: 'SHIPPED',
    value: 'SHIPPED',
    text: 'Shipped',
  },
  SHIPMENTBATCHCHECKEDIN: {
    key: 'CHECKED_IN',
    value: 'CHECKED_IN',
    text: 'Checked In',
  },
  SHIPMENTBATCHCLOSED: {
    key: 'CLOSED',
    value: 'CLOSED',
    text: 'Closed',
  },
  EMAILNOTIFICATIONFREQUENCY: {
    key: 'EMAIL_NOTIFICATION_FREQUENCY',
    value: 'EMAIL_NOTIFICATION_FREQUENCY',
    text: 'Email Notification Frequency',
  },
  EMAILNOTIFICATIONRECEIVERS: {
    key: 'EMAIL_NOTIFICATION_RECEIVERS',
    value: 'EMAIL_NOTIFICATION_RECEIVERS',
    text: 'Email Notification Receivers',
  },
  EMAIL: {
    key: 'EMAIL',
    value: 'EMAIL',
    text: 'E-mail',
  },
  NOTIFICATION: {
    key: 'NOTIFICATION',
    value: 'NOTIFICATION',
    text: 'Notification',
    disabled: false,
  },
  PERBOXCHARGE: {
    key: 'PER_BOX_CHARGE',
    value: 'PER_BOX_CHARGE',
    text: 'Per Box Charge',
    disabled: false,
  },
  BARCODE_TEMPLATE: {
    key: 'BARCODE_TEMPLATE',
    value: 'BARCODE_TEMPLATE',
    text: 'Barcode Template',
    disabled: false,
  },
  PUBLIC_RESOURCE: {
    key: 'PUBLIC_RESOURCE',
    value: 'PUBLIC_RESOURCE',
    text: 'Public Resource',
    disabled: false,
  },
  WFS_LTL_CONTACT_INFO: {
    key: 'WFS_LTL_CONTACT_INFO',
    value: 'WFS_LTL_CONTACT_INFO',
    text: 'Pallet Contact',
  },
})

export default managedKeysEnum
