const invoice = {

  Controller: {
    base: '/api/finance/invoice',
    // invoice Endpoints
    findById: id => `${invoice.Controller.base}/id/${id}`,
    getInvoiceInfoById: invoiceId => `${invoice.Controller.base}/get-invoice-info/${invoiceId}`,
    fetchAllByQuery: pageable => `${invoice.Controller.base}/query?${pageable}`,
    dryRunInvoicesForCompaniesByWarehouse: (warehouseId, accountNo) => `${invoice.Controller.base}/dry-run-invoices/${warehouseId}/${accountNo}`,
    calculatePreviewTotal: () => `${invoice.Controller.base}/preview/total`,
    calculateTotalDue: () => `${invoice.Controller.base}/due/total`,
    payInvoiceByIdAndAmount: params => `${invoice.Controller.base}/pay/${params.id}/${params.amount}`,
    payInvoiceByCreditCard: (invoiceId, amount, creditCardId) => `${invoice.Controller.base}/pay/${invoiceId}/${amount}/cc/${creditCardId}`,
    generateInvoices: () => `${invoice.Controller.base}/generate-invoice`,
    generateInvoiceByBatchName: params => `${invoice.Controller.base}/generate-invoice?${params}`,
    findAllItemsByInvoiceId: (invoiceId, pageable) => `${invoice.Controller.base}/invoice-items/${invoiceId}?${pageable}`,
    findAllItemsCategoriesByInvoiceId: (invoiceId, pageable) => `${invoice.Controller.base}/invoice-cost-category/${invoiceId}?${pageable}`,
    getDataForPrintInvoice: invoiceId => `${invoice.Controller.base}/print-invoice/${invoiceId}`,
    create: () => `${invoice.Controller.base}`,
    update: () => `${invoice.Controller.base}`,
    delete: id => `${invoice.Controller.base}/${id}`,
    voidTransaction: (id, transactionId, amount) => `${invoice.Controller.base}/void/${id}/transaction/${transactionId}/amount/${amount}`,
    refundTransaction: (id, transactionId, amount) => `${invoice.Controller.base}/refund/${id}/transaction/${transactionId}/amount/${amount}`,
  },
}
export default {
  invoice,
}
