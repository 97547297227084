export default {
  SP: {
    value: 'SP',
    key: 'SP',
    text: 'Small parcel delivery (SPD)',
    disabled: false,
  },
  LTL: {
    value: 'LTL',
    key: 'LTL',
    text: 'Less than truckload (LTL)',
    disabled: false,
  },
}
