const stax = {
  controller: {
    /*
    * Stax
    */
    base: '/api/external/stax',
    getMerchantInfo: companyId => `${stax.controller.base}/merchant/${companyId}`,
    getMerchantRegistrationData: companyId => `${stax.controller.base}/merchant/registration/${companyId}`,
    enrollMerchant: () => `${stax.controller.base}/merchant/enroll`,
    userEphemeralToken: userId => `${stax.controller.base}/user-ephemeral-token/${userId}`,
    getTenantWebsitePaymentsToken: companyId => `${stax.controller.base}/merchant/website-payments-token/${companyId}`,
    getAllCreditCardsByCompanyId: companyId => `${stax.controller.base}/credit-card/${companyId}`,
    createCreditCardToken: () => `${stax.controller.base}/credit-card/create`,
    updateDefaultCreditCard: id => `${stax.controller.base}/credit-card/set-default/${id}`,
    updateCreditCardAddress: id => `${stax.controller.base}/credit-card/update/${id}`,
    deleteCreditCard: id => `${stax.controller.base}/credit-card/delete/${id}`,
    getTransactions: () => `${stax.controller.base}/transactions-by-filter`,
    getTransactionsByInvoiceId: (id, pageable) => `${stax.controller.base}/transactions-by-invoice/${id}?${pageable}`,
    getDeposits: () => `${stax.controller.base}/deposits/query`,
    getDisputes: () => `${stax.controller.base}/disputes-by-filter`,
    getTeamMembers: () => `${stax.controller.base}/merchant/team-members`,
    createTeamMember: () => `${stax.controller.base}/merchant/team-members/create`,
  },
}
export default {
  stax,
}
