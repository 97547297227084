const productCategory = {

  Controller: {
    base: '/api/product/category',

    // productCategory Endpoints
    findAll: params => `${productCategory.Controller.base}${params}`,
    findById: id => `${productCategory.Controller.base}/${id}`,
    findByName: name => `${productCategory.Controller.base}/name/${name}`,
    create: () => `${productCategory.Controller.base}`,
    update: () => `${productCategory.Controller.base}/update`,
    delete: id => `${productCategory.Controller.base}/${id}`,
  },
}
export default {
  productCategory,
}
