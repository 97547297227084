<!-- eslint-disable import/no-dynamic-require -->
<template>
  <div>
    <div :class="round ? `round bg-${color}` : `rounded bg-${color}`" :style="{ width: size, height: size, fontSize: fontSize, backgroundColor: color, backgroundImage:`url(${src})`}" class="logo p-half d-flex align-items-center justify-content-center">
      <slot>
        <span v-if="!src" class="text-capitalize">
          {{ text }}
        </span>
        <img else :src="src" :width="size" :class="round ? 'round' : 'rounded'">
      </slot>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    size: {
      type: String,
      default: '72px',
    },
    color: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    round: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fontSize() {
      const maxContainerWidth = 200
      const minFontSize = 9
      const maxFontSize = 72
      const width = parseInt(this.size, 10)
      const containerWidth = Math.min(width, maxContainerWidth)

      const numLetters = this.text.length
      const fontSize = containerWidth / (numLetters)
      return `${Math.floor(Math.max(minFontSize, Math.min(fontSize, maxFontSize)))}px`
    },
  },
}
</script>

<style lang="scss" scoped>
  .logo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
