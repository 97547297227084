import find from 'lodash/find'

/**
 * Enum for Task Types.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const taskTypesEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'All Task Types' },
  SORTING: { key: 'SORTING', value: 'SORTING', text: 'Sorting' },
  RECEIVE_ITEM: { key: 'RECEIVE_ITEM', value: 'RECEIVE_ITEM', text: 'Receive Item' },
  PROBLEM: { key: 'PROBLEM', value: 'PROBLEM', text: 'Problem' },
  SHIPMENT: { key: 'SHIPMENT', value: 'SHIPMENT', text: 'Shipment' },
  OTHER: { key: 'OTHER', value: 'OTHER', text: 'Other' },
})

function getTaskTypesEnum(predicate, predicateKey = 'key') {
  const result = find(taskTypesEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getTaskTypesEnumList() {
  const options = []
  Object.keys(taskTypesEnum).forEach(key => {
    options.push({
      value: taskTypesEnum[key].value,
      text: taskTypesEnum[key].text,
    })
  })
  return options
}

export default taskTypesEnum

export {
  getTaskTypesEnum,
  getTaskTypesEnumList,
}
