const packageReceiveBatch = {

  Controller: {
    base: '/api/inbound/package-receive-batch',

    findAll: pageable => `${packageReceiveBatch.Controller.base}?${pageable}`,
    findById: id => `${packageReceiveBatch.Controller.base}/${id}`,
    // params format: {warehouse: 'id', beginDate: 'date', endDate: 'date', status: 'all|open|complete|missing'}
    // query format: key1=value1&key2=value2...
    getByQuery: pageable => `${packageReceiveBatch.Controller.base}/query?${pageable}`,
    create: () => `${packageReceiveBatch.Controller.base}`,
    update: () => `${packageReceiveBatch.Controller.base}/update`,
    // params: {id: 'batchId', status: 'open|complete|missing'}
    changeStatus: params => `${packageReceiveBatch.Controller.base}/${params.id}/${params.status}`,
    delete: id => `${packageReceiveBatch.Controller.base}/${id}`,
    deleteMany: () => `${packageReceiveBatch.Controller.base}/delete-many`,
  },
}
export default {
  packageReceiveBatch,
}
