export default [
  {
    path: '/apps/inventory/amazon-inventory',
    name: 'amazon-inventory',
    component: () => import('@/views/apps/inventory/view/AmazonInventory.vue'),
    meta: {
      resource: 'resource_inventory_prep_inventory',
      action: 'read',
      pageTitle: 'Amazon Inventory',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'Amazon Inventory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventory/:itemAction',
    name: 'prep-inventory',
    component: () => import('@/views/apps/inventory/Inventory.vue'),
    meta: {
      resource: 'resource_inventory_prep_inventory',
      action: 'read',
      pageTitle: 'Prep Inventory',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'Prep Inventory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventory/:itemAction',
    name: 'wfs-inventory',
    component: () => import('@/views/apps/inventory/Inventory.vue'),
    meta: {
      resource: 'resource_inventory_wfs_inventory',
      action: 'read',
      pageTitle: 'WFS Inventory',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'WFS Inventory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventory/:itemAction',
    name: 'fbm-inventory',
    component: () => import('@/views/apps/inventory/Inventory.vue'),
    meta: {
      resource: 'resource_inventory_fbm_inventory',
      action: 'read',
      pageTitle: 'FBM Inventory',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'FBM Inventory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventory/:itemAction',
    name: 'forwarding-inventory',
    component: () => import('@/views/apps/inventory/Inventory.vue'),
    meta: {
      resource: 'resource_inventory_forwarding_inventory',
      action: 'read',
      pageTitle: 'Forwarding Inventory',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'Forwarding Inventory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventory/:itemAction',
    name: 'storage-inventory',
    component: () => import('@/views/apps/inventory/Inventory.vue'),
    meta: {
      resource: 'resource_inventory_storage_inventory',
      action: 'read',
      pageTitle: 'Storage Inventory',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'Storage Inventory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventory/:itemAction',
    name: 'return-inventory',
    component: () => import('@/views/apps/inventory/Inventory.vue'),
    meta: {
      resource: 'resource_inventory_return_inventory',
      action: 'read',
      pageTitle: 'Return Inventory',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'Return Inventory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventory/:itemAction',
    name: 'removal-order-inventory',
    component: () => import('@/views/apps/inventory/Inventory.vue'),
    meta: {
      resource: 'resource_inventory_removal_order_inventory',
      action: 'read',
      pageTitle: 'Removal Order Inventory',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'Removal Order Inventory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/inventory/:itemAction',
    name: 'dropship',
    component: () => import('@/views/apps/inventory/Inventory.vue'),
    meta: {
      resource: 'resource_inventory_dropship_inventory',
      action: 'read',
      pageTitle: 'Dropship Inventory',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'Dropship Inventory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/reports/dispositions',
    name: 'dispositions',
    component: () => import('@/views/apps/reports/amazon-reports/Removals.vue'),
    meta: {
      resource: 'resource_inventory_prep_inventory',
      action: 'read',
      pageTitle: 'Removals',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Inventory',
        },
        {
          text: 'Dispositions',
          active: true,
        },
      ],
    },
  },
]
