import find from 'lodash/find'
/**
 * Enum for Merchant Connect Underwriting Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const shippingLabelTypesEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'Type (All)' },
  FBM_FORCE: { key: 'FBM_FORCE', value: 'FBM_FORCE', text: 'FBM Force' },
  FBA_FORCE: { key: 'FBA_FORCE', value: 'FBA_FORCE', text: 'FBA Force' },
  MANUAL: { key: 'MANUAL', value: 'MANUAL', text: 'Manual' },
  PROBLEM: { key: 'PROBLEM', value: 'PROBLEM', text: 'Problem' },
})

function getShippingLabelTypeEnum(predicate, predicateKey = 'key') {
  const result = find(shippingLabelTypesEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getShippingLabelTypeList() {
  const options = []
  Object
    .keys(shippingLabelTypesEnum)
    .map(key => options.push(
      {
        value: shippingLabelTypesEnum[key].value,
        text: shippingLabelTypesEnum[key].text,
      },
    ))
  return options
}

export default shippingLabelTypesEnum

export {
  getShippingLabelTypeEnum,
  getShippingLabelTypeList,
}
