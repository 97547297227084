/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import Vue from 'vue'
import { isEmpty, isNaN } from 'lodash/lang'

const title = value => {
  if (!value) return ''
  value = value.toString()
  value = value.replace(/[^a-zA-Z0-9]/g, ' ')
  value = value.charAt(0).toUpperCase() + value.slice(1)
  value = value.split(/(?=[A-Z-0-9])/g)
  value = value.length === 1 ? value.toString() : value.join(' ')
  return value
}

Vue.filter('capitalize', value => {
  if (!value) return ''
  value = value.toString()
  value = value.replace(/[^a-zA-Z ]/g, ' ')
  value = value.toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('title', value => title(value))

const formatDate = (value, formatting = {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
}) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

Vue.filter('formatDate', value => formatDate(value))

Vue.filter('yesno', value => {
  if (!value) return ''
  if (value === true || value === 'yes' || value === 'YES' || value === 'Yes') {
    value = 'Yes'
  } else if (value === false || value === 'no' || value === 'NO' || value === 'No') {
    value = 'No'
  }

  return value
})

Vue.filter('objectToText', value => {
  if (isEmpty(value)) return ''

  value = Object.entries(value).reduce(
    (txt, [key, val], index) => {
      txt += typeof val === 'string' ? `${title(key)}: ${val} ` : ''
      return txt
    }, '',
  )
  return value
})

Vue.filter('cleanNotification', value => {
  if (isEmpty(value)) return ''

  value = Object.entries(value).reduce(
    (txt, [key, val], index) => {
      txt += typeof val === 'string' ? `<span class="font-weight-bolder">${title(key)}:</span> <span class="font-italic">${val}</span> ` : ''
      return txt
    }, '',
  )
  return value
})

Vue.filter('formatCurrency', value => {
  if (!value && value !== 0) return '0.00'
  const numberValue = parseFloat(value)
  if (isNaN(numberValue)) return '0.00'
  return numberValue.toLocaleString(undefined, { style: 'currency', currency: 'USD' })
})

Vue.filter('formatPercentage', value => {
  if (!value && value !== 0) return '0.00%'
  const numberValue = parseFloat(value)
  if (isNaN(numberValue)) return '0.00%'
  return `${(numberValue * 100).toFixed(2)}%`
})
