export default {
  namespaced: true,
  state() {
    return {
      chatHubConnection: {},
    }
  },
  mutations: {
    setChatHubConnection(state, payload) {
      state.chatHubConnection = payload
    },
  },
  actions: {
    setChatHubConnection(context, payload) {
      context.commit('setChatHubConnection', payload)
    },
  },
  getters: {
    getChatHubConnection(state) {
      return state.chatHubConnection
    },
  },
}
