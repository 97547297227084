const prepMaterialInventory = {

  Controller: {
    base: '/api/prep-material/inventory',

    // prepMaterialInventory Endpoints
    findAll: pageable => `${prepMaterialInventory.Controller.base}?${pageable}`,
    getByQuery: pageable => `${prepMaterialInventory.Controller.base}/query?${pageable}`,
    getChargeSelect: () => `${prepMaterialInventory.Controller.base}/charge/select`,
    findById: id => `${prepMaterialInventory.Controller.base}/${id}`,
    findByTitle: title => `${prepMaterialInventory.Controller.base}/title/${title}`,
    create: () => `${prepMaterialInventory.Controller.base}`,
    update: () => `${prepMaterialInventory.Controller.base}`,
    delete: id => `${prepMaterialInventory.Controller.base}/${id}`,
    decrement: () => `${prepMaterialInventory.Controller.base}/decrement/material/quantity`,
    adjust: () => `${prepMaterialInventory.Controller.base}/adjust/material/quantity`,
  },
}
export default {
  prepMaterialInventory,
}
