export default {
  i_will_label: {
    value: 'SELLER_LABEL',
    key: 'SELLER_LABEL',
    text: 'I will label all my items',
    disabled: false,
  },
  fba_reject: {
    value: 'AMAZON_LABEL_ONLY',
    key: 'AMAZON_LABEL_ONLY',
    text: 'FBA should label my items and reject items that cannot be labeled',
    disabled: false,
  },
  fba_and_i: {
    value: 'AMAZON_LABEL_PREFERRED',
    key: 'AMAZON_LABEL_PREFERRED',
    text: 'FBA should label my items, however I will label items as neccessary',
    disabled: false,
  },
}
