<template>
  <div>
    <b-form-select
      v-model="selected"
      :options="options"
      autofocus
      :state="state"
      :disabled="disabled"
      @change="changeEvent"
    />
  </div>
</template>

<script>
import find from 'lodash/find'
import { ValueToEnum } from '@robustshell/utils/index'
import is from 'ramda/es/is'

export default {
  props: {
    state: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    enum: {
      type: Object,
      required: true,
    },
    changed: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    options: [],
  }),
  computed: {
    selected: {
      get() {
        if (is(String, this.value)) {
          return ValueToEnum(this.enum, this.value).value
        }
        return this.value
      },
      set(val) {
        const value = find(this.options, e => e.value === val)
        if (value) {
          this.$emit('input', val)
        }
      },
    },
  },
  mounted() {
    Object.keys(this.enum).map(key => this.options.push({ value: this.enum[key].value, text: this.enum[key].text, disabled: this.enum[key].disabled }))
  },
  methods: {
    changeEvent(val) {
      this.changed(val)
    },
  },
}
</script>
