/* eslint-disable no-underscore-dangle */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import RobustShellComponents from '@robustshell/components/index'
import Enums from '@robustshell/mixins/enum'
import i18n from '@/libs/i18n'
import VueBarcode from '@chenfengyuan/vue-barcode'
import DxCommons from '@core/dev-extreme/mixins/commons'
import DxGrid from '@core/dev-extreme/mixins/grid'
import themes from 'devextreme/ui/themes'
import GlobalMixin from './globalMixin'
import router from './router'
import store from './store'
import App from './App.vue'
import endpoints from './http/endpoints/index'
import PAvatar from './components/psh/PAvatar.vue'
import PLazyImage from './components/psh/PLazyImage.vue'
import PIcon from './components/psh/PIcon.vue'
import CopyButton from './components/psh/CopyButton.vue'
import './plugins'
import '@robustshell/filters/index'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/page-overlay'

import uuidPlugin from './plugins/uuidv4'

Vue.use(VueCompositionAPI)

Vue.use(uuidPlugin)
// Composition API

let theme = 'generic.dark'
localStorage.getItem('theme-skin')
if (localStorage.getItem('theme-skin') && localStorage.getItem('theme-skin') === 'dark') {
  theme = 'generic.dark'
} else if (localStorage.getItem('theme-skin') && localStorage.getItem('theme-skin') === 'light') {
  theme = 'generic.light'
} else {
  theme = 'generic.dark'
}
themes.current(theme)

Vue.prototype.$color = themes.current() === 'generic.dark' ? '#dedede' : ''

// BSV Plugin Registration
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(endpoints)

Vue.mixin(DxCommons)
Vue.mixin(DxGrid)

Vue.mixin(GlobalMixin)
Vue.use(Enums, { namespace: '$enums' })
Vue.component(VueBarcode.name, VueBarcode)
Vue.component('p-avatar', PAvatar)
Vue.component('p-lazy-img', PLazyImage)
Vue.component('p-icon', PIcon)
Vue.component('copy-button', CopyButton)
RobustShellComponents()

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@/assets/icons/icon-font/icons.css')

Vue.config.productionTip = false
themes.initialized(() => new Vue({
  router,
  i18n,
  store,
  created() {
    if (localStorage.getItem('theme-component-skin') && localStorage.getItem('theme-component-skin') === 'generic.nightblue') {
      localStorage.setItem('theme-component-skin', 'generic.dark')
    }
  },
  render: h => h(App),
}).$mount('#app'))
