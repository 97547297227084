const membershipManagement = {

  Controller: {
    type_base: '/api/membership/types',
    group_base: '/api/membership/groups',
    plan_base: '/api/membership/plans',
    subscriber_base: '/api/membership/subscribers',
    enum_base: '/api/membership/enums',
    charge_base: '/api/membership/charges',
    typedetail_base: '/api/membership/type-details',
    plandetail_base: '/api/membership/plan-details',

    // #region charge Endpoints
    addCharge: () => `${membershipManagement.Controller.charge_base}`,
    deleteCharge: id => `${membershipManagement.Controller.charge_base}/${id}`,
    editCharge: () => `${membershipManagement.Controller.charge_base}`,
    getCharges: pageable => `${membershipManagement.Controller.charge_base}/page?${pageable}`,
    getCharge: id => `${membershipManagement.Controller.charge_base}/${id}`,
    getChargesUnpaid: pageable => `${membershipManagement.Controller.charge_base}/un-paid${pageable}`,
    // #endregion

    // #region group Endpoints
    addGroup: () => `${membershipManagement.Controller.group_base}`,
    deleteGroup: id => `${membershipManagement.Controller.group_base}/${id}`,
    editGroup: () => `${membershipManagement.Controller.group_base}`,
    getGroups: () => `${membershipManagement.Controller.group_base}`,
    getGroupsByTypeId: typeid => `${membershipManagement.Controller.group_base}/type-id?membership-type-id=${typeid}`,
    // #endregion

    // #region plan Endpoints
    addPlan: () => `${membershipManagement.Controller.plan_base}`,
    addPlanWithGroup: () => `${membershipManagement.Controller.plan_base}/with-group`,
    deletePlan: id => `${membershipManagement.Controller.plan_base}/${id}`,
    editPlan: id => `${membershipManagement.Controller.plan_base}/${id}`,
    getPlansByTypeId: typeid => `${membershipManagement.Controller.plan_base}/by-type-id?membership-type-id=${typeid}`,
    getPlan: id => `${membershipManagement.Controller.plan_base}/${id}`,
    // #endregion

    // #region plandetail Endpoints
    addPlanDetail: () => `${membershipManagement.Controller.plandetail_base}`,
    addPlanDetailAsBulk: () => `${membershipManagement.Controller.plandetail_base}/membership-plan-id-and-membership-type-id-in`,
    deletePlanDetail: id => `${membershipManagement.Controller.plandetail_base}/${id}`,
    editPlanDetail: id => `${membershipManagement.Controller.plandetail_base}/${id}`,
    getPlanDetails: () => `${membershipManagement.Controller.plandetail_base}`,
    getPlanDetailsByPlanId: planid => `${membershipManagement.Controller.plandetail_base}/membership-plan-id?membership-plan-id=${planid}`,
    getPlanDetail: id => `${membershipManagement.Controller.plandetail_base}/${id}`,
    // #endregion

    // #region subscriber Endpoints
    addSubscriber: () => `${membershipManagement.Controller.subscriber_base}`,
    deleteSubscriber: id => `${membershipManagement.Controller.subscriber_base}/${id}`,
    editSubscriber: id => `${membershipManagement.Controller.subscriber_base}/${id}`,
    getSubscriber: id => `${membershipManagement.Controller.subscriber_base}/${id}`,
    getSubscribersForCompany: (typeid, companyid) => `${membershipManagement.Controller.subscriber_base}/by-company-id?tenant_id=PUBLIC&company-id=${companyid}&membership-type-id=${typeid}`,
    getSubscribersForECommerce: (typeid, companyid) => `${membershipManagement.Controller.subscriber_base}/by-ecommerce-store-id?tenant_id=PUBLIC&ecommerce-store-id=${companyid}&membership-type-id=${typeid}`,
    getSubscriberByTypeId: typeid => `${membershipManagement.Controller.subscriber_base}/by-type-id?tenant_id=PUBLIC&membership-type-id=${typeid}`,
    // #endregion

    // #region enum Endpoints
    getEnumsAll: () => `${membershipManagement.Controller.enum_base}`,
    getEnumsAudienceTypes: () => `${membershipManagement.Controller.enum_base}/audience-types`,
    getEnumsGroupTypes: () => `${membershipManagement.Controller.enum_base}/group-types`,
    getEnumsChargeSchedules: () => `${membershipManagement.Controller.enum_base}/charge-schedules`,
    getEnumsSubscriptionStates: () => `${membershipManagement.Controller.enum_base}/subscription-states`,
    // #endregion

    // #region type Endpoints
    addType: () => `${membershipManagement.Controller.type_base}`,
    deleteType: id => `${membershipManagement.Controller.type_base}/${id}`,
    editType: id => `${membershipManagement.Controller.type_base}/${id}`,
    getTypes: () => `${membershipManagement.Controller.type_base}`,
    getType: id => `${membershipManagement.Controller.type_base}/${id}`,
    // #endregion

    // #region typedetail Endpoints
    addTypeDetail: () => `${membershipManagement.Controller.typedetail_base}`,
    deleteTypeDetail: id => `${membershipManagement.Controller.typedetail_base}/${id}`,
    editTypeDetail: id => `${membershipManagement.Controller.typedetail_base}/${id}`,
    getTypeDetails: () => `${membershipManagement.Controller.typedetail_base}`,
    getTypeDetail: id => `${membershipManagement.Controller.typedetail_base}/${id}`,
    getTypeDetailsByTypeId: id => `${membershipManagement.Controller.typedetail_base}/membership-type-id?membership-type-id=${id}`,
    // #endregion
  },
}

export default {
  membershipManagement,
}
