const shopify = {
  controller: {
    /*
    * Shopify
    */
    shopify: '/api/external/shopify',
    generateUserAuthorizationUrl: prepStoreId => `${shopify.controller.shopify}/generate-user-authorization-url/${prepStoreId}`,
    shopifyAuthorization: () => `${shopify.controller.shopify}/store/access-token`,
    unauthorizeStore: storeId => `${shopify.controller.shopify}/uninstall/${storeId}`,
    createShopifyCart: () => `${shopify.controller.shopify}/create-shopify-cart`,
  },
}
export default {
  shopify,
}
