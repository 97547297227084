const membershipCharges = {
  Controller: {
    base: '/api/membership/charges',
    create: () => `${membershipCharges.Controller.base}`,
    delete: id => `${membershipCharges.Controller.base}/${id}`,
    update: () => `${membershipCharges.Controller.base}`,
    getListByFilter: planId => `${membershipCharges.Controller.base}/list?${planId}`,
    getByPageableQuery: pageable => `${membershipCharges.Controller.base}/query?${pageable}`,
    findById: id => `${membershipCharges.Controller.base}/${id}`,
    /* payd membership charge by credit card id */
    payCharges: cartId => `${membershipCharges.Controller.base}/credit/cart/${cartId}/paid`,
    /* get membership charges by company id */
    getDuePlans: () => `${membershipCharges.Controller.base}/due/plans`,
    payDuePlan: planId => `${membershipCharges.Controller.base}/due/plans/pay/${planId}`,
  },
}

export default { membershipCharges }
