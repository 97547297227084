import find from 'lodash/find'
/**
 * Enum for Merchant Connect Underwriting Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const expenseRepeatingTypeEnum = Object.freeze({
  OneTime: { key: 'OneTime', value: 'OneTime', text: 'One-Time' },
  Daily: { key: 'Daily', value: 'Daily', text: 'Daily' },
  Weekly: { key: 'Weekly', value: 'Weekly', text: 'Weekly' },
  Monthly: { key: 'Monthly', value: 'Monthly', text: 'Monthly' },
  Yearly: { key: 'Yearly', value: 'Yearly', text: 'Yearly' },
})

function getExpenseRepeatingEnum(predicate, predicateKey = 'key') {
  const result = find(expenseRepeatingTypeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getExpenseRepeatingTypeList() {
  const options = []
  Object
    .keys(expenseRepeatingTypeEnum)
    .map(key => options.push(
      {
        value: expenseRepeatingTypeEnum[key].value,
        text: expenseRepeatingTypeEnum[key].text,
      },
    ))
  return options
}

export default expenseRepeatingTypeEnum

export {
  getExpenseRepeatingEnum,
  getExpenseRepeatingTypeList,
}
