export default {
  fba: {
    value: 'FBA',
    key: 'fba',
    text: 'FBA',
    disabled: false,
  },
  fbm: {
    value: 'FBM',
    key: 'fbm',
    text: 'FBM',
    disabled: false,
  },
  return: {
    value: 'RETURN',
    key: 'return',
    text: 'Return',
    disabled: false,
  },
  removal_order: {
    value: 'REMOVAL_ORDER',
    key: 'removal_order',
    text: 'Removal Order',
    disabled: false,
  },
  forwarding: {
    value: 'FORWARDING',
    key: 'forwarding',
    text: 'Forwarding',
    disabled: false,
  },
  storage: {
    value: 'STORAGE',
    key: 'storage',
    text: 'Storage',
    disabled: false,
  },
  dropship: {
    value: 'DROPSHIP',
    key: 'dropship',
    text: 'Dropship',
    disabled: false,
  },
  b2b: {
    value: 'B2B',
    key: 'b2b',
    text: 'B2B',
    disabled: false,
  },
  wfs: {
    value: 'WFS',
    key: 'wfs',
    text: 'WFS',
    disabled: true,
  },
}
