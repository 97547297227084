import find from 'lodash/find'
/**
 * Enum for Amazon Reports.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const reportDateRangeEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'All' },
  LAST_MONTH: { key: 'LAST_MONTH', value: 'LAST_MONTH', text: 'Current Month' },
  LAST_3_MONTHS: { key: 'LAST_3_MONTHS', value: 'LAST_3_MONTHS', text: 'Last 3 Months' },
  LAST_6_MONTHS: { key: 'LAST_6_MONTHS', value: 'LAST_6_MONTHS', text: 'Last 6 Months' },
  LAST_12_MONTHS: { key: 'LAST_12_MONTHS', value: 'LAST_12_MONTHS', text: 'Last 12 Months' },
  CUSTOM: { key: 'CUSTOM', value: 'CUSTOM', text: 'Custom' },
})

function getReportDateRangeEnum(predicate, predicateKey = 'key') {
  const result = find(reportDateRangeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getListReportDateRangeEnum() {
  const result = Object.keys(reportDateRangeEnum).map(key => reportDateRangeEnum[key])
  return result
}

export default reportDateRangeEnum

export {
  getListReportDateRangeEnum,
  getReportDateRangeEnum,
}
