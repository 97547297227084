const basicStats = {

  Controller: {
    base: '/api/reporting',

    getBasicStats: () => `${basicStats.Controller.base}/basic-stats`,
    getCombinedFbmStats: () => `${basicStats.Controller.base}/basic-stats/combined-fbm-stats`,
    getDashboardStats: () => `${basicStats.Controller.base}/dashboard-stats/counts`,
    getDashboardStatsTopN: () => `${basicStats.Controller.base}/dashboard-stats/top-n-item`,
    getCombinedData: () => `${basicStats.Controller.base}/dashboard-stats/combined-data`,
    getStatsByList: () => `${basicStats.Controller.base}/dashboard-stats/counts/all`,

    getWorkerPerformance: () => `${basicStats.Controller.base}/warehouse-employees-perf`,
  },
}
export default {
  basicStats,
}
