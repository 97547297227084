<template>
  <span class="badge" :style="getColor">
    <slot>{{ getText }}</slot>
  </span>
</template>

<script>
import { ValueToEnum, InvertColor } from '@robustshell/utils/index'

export default {
  // props: ['value', 'enum'],
  props: {
    value: {
      required: true,
    },
    enum: {
      required: true,
      type: Object,
    },
    enumPredicate: {
      required: false,
      type: String,
      default: function () {
        return 'text'
      },
    },
  },
  computed: {
    getText() {
      return this.getEnum().text
    },
    getColor() {
      const color = {
        color: InvertColor(this.getEnum().color, true),
        backgroundColor: this.getEnum().color,
      }
      return color
    },
  },
  methods: {
    getEnum() {
      return ValueToEnum(this.enum, this.value, this.enumPredicate)
    },
  },
}
</script>
