import find from 'lodash/find'

const NotificationFrequencyEnum = Object.freeze({
  ANY_CHANGE: {
    value: 'ANY_CHANGE',
    text: 'Any change',
    key: 'ANY_CHANGE',
    disabled: false,
  },
  ONCE_A_DAY: {
    value: 'ONCE_A_DAY',
    text: 'Once a day',
    key: 'ONCE_A_DAY',
    disabled: false,
  },
  // ONCE_A_WEEK: {
  //   value: 'ONCE_A_WEEK',
  //   text: 'Once a week',
  //   key: 'ONCE_A_WEEK',
  //   disabled: false,
  // },
})

function getFrequencyEnum(predicate, predicateKey = 'key') {
  const result = find(NotificationFrequencyEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getFreqeuncyEnumList() {
  const options = []
  Object
    .keys(NotificationFrequencyEnum)
    .map(key => options.push(
      {
        value: NotificationFrequencyEnum[key].value,
        text: NotificationFrequencyEnum[key].text,
      },
    ))
  return options
}

export default NotificationFrequencyEnum

export {
  getFrequencyEnum,
  getFreqeuncyEnumList,
}
