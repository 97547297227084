const membershipPlansDetails = {
  Controller: {
    base: '/api/membership/plans/details',
    create: () => `${membershipPlansDetails.Controller.base}`,
    delete: id => `${membershipPlansDetails.Controller.base}/${id}`,
    update: () => `${membershipPlansDetails.Controller.base}`,
    getListByFilter: planId => `${membershipPlansDetails.Controller.base}/list/${planId}`,
    findById: id => `${membershipPlansDetails.Controller.base}/${id}`,
  },
}

export default { membershipPlansDetails }
