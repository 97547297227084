const reports = {
  Controller: {
    base: '/api/reporting/item-tracking',

    findItemsByAnyCode: () => `${reports.Controller.base}/status-by-any-code`,
    findTrackingItemsByBatch: () => `${reports.Controller.base}/batch`,
  },
}
export default {
  reports,
}
