export default {
  none: {
    value: 'none',
    key: 'none',
    text: 'Resolution Type',
    disabled: false,
  },
  notResolvedYet: {
    value: 'not_resolved_yet',
    key: 'not_resolved_yet',
    text: 'Not Resolved Yet',
    disabled: false,
  },
  dispose: {
    value: 'dispose',
    key: 'dispose',
    text: 'Dispose',
    disabled: false,
  },
  donate: {
    value: 'donate',
    key: 'donate',
    text: 'Donate',
    disabled: false,
  },
  returnToCustomer: {
    value: 'return_to_customer',
    key: 'return_to_customer',
    text: 'Return to Customer',
    disabled: false,
  },
  returnToSupplier: {
    value: 'return_to_supplier',
    key: 'return_to_supplier',
    text: 'Return to Supplier',
    disabled: false,
  },
  investigate: {
    value: 'investigate',
    key: 'investigate',
    text: 'Investigate',
    disabled: false,
  },
  reimburse: {
    value: 'reimburse',
    key: 'reimburse',
    text: 'Reimburse',
    disabled: false,
  },
  changed_item_action: {
    value: 'changed_item_action',
    key: 'changed_item_action',
    text: 'Changed Item Action',
    disabled: false,
  },
  use_alternative_asin: {
    value: 'use_alternative_asin',
    key: 'use_alternative_asin',
    text: 'Use Alternative ASIN',
    disabled: false,
  },
  data_entered: {
    value: 'data_entered',
    key: 'data_entered',
    text: 'Data Entered',
    disabled: false,
  },
  restock_to_inbound: {
    value: 'restock_to_inbound',
    key: 'restock_to_inbound',
    text: 'Restock to Inbound',
    disabled: false,
  },
  resolved: {
    value: 'resolved',
    key: 'resolved',
    text: 'Resolved',
    disabled: false,
  },
}
