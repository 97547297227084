/**
 * Enum for Dx DataGrid Names.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const dataGridNamesEnum = Object.freeze({
  INBOUND_SHIPMENT_ITEMS_GRID: { key: 'INBOUND_SHIPMENT_ITEMS_GRID', value: 'INBOUND_SHIPMENT_ITEMS_GRID', text: 'Inbound Shipment Items Grid' },
  INBOUND_SHIPMENT_BATCHES_GRID: { key: 'INBOUND_SHIPMENT_BATCHES_GRID', value: 'INBOUND_SHIPMENT_BATCHES_GRID', text: 'Inbound Shipment Batches Grid' },
  INBOUND_RECEIVE_BATCH_GRID: { key: 'INBOUND_RECEIVE_BATCH_GRID', value: 'INBOUND_RECEIVE_BATCH_GRID', text: 'Inbound Receive Batch Grid' },
  INVENTORY_GRID: { key: 'INVENTORY_GRID', value: 'INVENTORY_GRID', text: 'Inventory Grid' },
  PROBLEMS_GRID: { key: 'PROBLEMS_GRID', value: 'PROBLEMS_GRID', text: 'Problems Grid' },
  OUTBOUND_SHIPMENT_BATCHES_GRID: { key: 'OUTBOUND_SHIPMENT_BATCHES_GRID', value: 'OUTBOUND_SHIPMENT_BATCHES_GRID', text: 'Outbound Shipment Batches Grid' },
  OUTBOUND_FBA_BATCHING_GRID: { key: 'OUTBOUND_FBA_BATCHING_GRID', value: 'OUTBOUND_FBA_BATCHING_GRID', text: 'Outbound FBA Batching Grid' },
})

export default dataGridNamesEnum
