const chat = {
  controller: {
    /*
    * Chat
    */
    base: '/api/chat-user',
    getChatUsers: () => `${chat.controller.base}/query`, // mycompany
    assignChatUser: () => `${chat.controller.base}`, // my company
    updateChatUser: () => `${chat.controller.base}`, //  my company
    deleteChatUser: id => `${chat.controller.base}/${id}`, // my company
    getTenantWarehouses: () => `${chat.controller.base}/warehouses`, // my company
    findUsersByWarehouseId: id => `${chat.controller.base}/users/warehouse/${id}`, // my company
    getChatUser: id => `${chat.controller.base}/${id}`,
    findContactUsersByWarehouseId: (userId, accountNo) => `${chat.controller.base}/contact/${userId}/customer/${accountNo}`,
  },
}
export default {
  chat,
}
