import DxSelectBox from 'devextreme-vue/select-box'
import DxDropDownBox from 'devextreme-vue/drop-down-box'
import DxList, { DxItemDragging, DxMenuItem } from 'devextreme-vue/list'
import DxTextBox from 'devextreme-vue/text-box'
import DxNumberBox from 'devextreme-vue/number-box'
import DxAutocomplete from 'devextreme-vue/autocomplete'
import DxCheckBox from 'devextreme-vue/check-box'
import DxRadioGroup from 'devextreme-vue/radio-group'
import DxTextArea from 'devextreme-vue/text-area'
import DxButton from 'devextreme-vue/button'
import DxButtonGroup from 'devextreme-vue/button-group'
import DxDateBox from 'devextreme-vue/date-box'
import DxTagBox from 'devextreme-vue/tag-box'
import { DxLoadIndicator } from 'devextreme-vue/load-indicator'
import DxToolbar from 'devextreme-vue/toolbar'
import DxTabs from 'devextreme-vue/tabs'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import { DxScrollView } from 'devextreme-vue/scroll-view'
import DxForm, {
  DxItem,
  DxSimpleItem,
  DxEmptyItem,
  DxGroupItem,
  DxLabel,
  DxTabbedItem,
  DxTab,
  DxTabPanelOptions,
  DxButtonItem,
} from 'devextreme-vue/form'
import DxValidationGroup from 'devextreme-vue/validation-group'
import DxValidator, {
  DxRequiredRule,
  DxCustomRule,
  DxCompareRule,
  DxEmailRule,
  DxPatternRule,
  DxStringLengthRule,
  DxRangeRule,
  DxAsyncRule,
} from 'devextreme-vue/validator'
import DxPopup, {
  DxPosition,
  DxToolbarItem,
} from 'devextreme-vue/popup'
import { DxFileUploader } from 'devextreme-vue/file-uploader'
import { DxProgressBar } from 'devextreme-vue/progress-bar'
import DxDropDownButton from 'devextreme-vue/drop-down-button'
import { DxSwitch } from 'devextreme-vue/switch'
import DxTabPanel, { DxItem as DxPanelItem } from 'devextreme-vue/tab-panel'
import { DxPopover } from 'devextreme-vue/popover'

export default {
  components: {
    'dx-util-select-box': DxSelectBox,
    'dx-util-tag-box': DxTagBox,
    'dx-util-drop-down-box': DxDropDownBox,
    'dx-util-text-box': DxTextBox,
    'dx-util-number-box': DxNumberBox,
    'dx-util-autocomplete': DxAutocomplete,
    'dx-util-validation-group': DxValidationGroup,
    'dx-util-validator': DxValidator,
    'dx-util-required-rule': DxRequiredRule,
    'dx-util-custom-rule': DxCustomRule,
    'dx-util-compare-rule': DxCompareRule,
    'dx-util-email-rule': DxEmailRule,
    'dx-util-pattern-rule': DxPatternRule,
    'dx-util-string-length-rule': DxStringLengthRule,
    'dx-util-range-rule': DxRangeRule,
    'dx-util-async-rule': DxAsyncRule,
    'dx-util-check-box': DxCheckBox,
    'dx-util-radio-group': DxRadioGroup,
    'dx-util-date-box': DxDateBox,
    'dx-util-text-area': DxTextArea,
    'dx-util-button': DxButton,
    'dx-util-drop-down-button': DxDropDownButton,
    'dx-util-form': DxForm,
    'dx-util-item': DxItem,
    'dx-util-tab': DxTab,
    'dx-util-tabbed-item': DxTabbedItem,
    'dx-util-tab-panel-options': DxTabPanelOptions,
    'dx-util-simple-item': DxSimpleItem,
    'dx-util-group-item': DxGroupItem,
    'dx-util-empty-item': DxEmptyItem,
    'dx-util-label': DxLabel,
    'dx-util-button-item': DxButtonItem,
    'dx-util-list': DxList,
    'dx-util-item-dragging': DxItemDragging,
    'dx-util-menu-item': DxMenuItem,
    'dx-util-popup': DxPopup,
    'dx-util-position': DxPosition,
    'dx-util-toolbar-item': DxToolbarItem,
    'dx-util-load-indicator': DxLoadIndicator,
    'dx-util-toolbar': DxToolbar,
    'dx-util-button-group': DxButtonGroup,
    'dx-util-file-uploader': DxFileUploader,
    'dx-util-tabs': DxTabs,
    'dx-util-load-panel': DxLoadPanel,
    'dx-util-scroll-view': DxScrollView,
    'dx-util-progress-bar': DxProgressBar,
    'dx-util-switch': DxSwitch,
    'dx-util-tab-panel': DxTabPanel,
    'dx-util-panel-item': DxPanelItem,
    'dx-util-popover': DxPopover,
  },
}
