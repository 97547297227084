const filemanagement = {
  FolderPlanController: {
    base: '/api/file/folder-plan',
    findAll: pageable => `${filemanagement.FolderPlanController.base}?${pageable}`,
    findAllTree: () => `${filemanagement.FolderPlanController.base}/tree`,
    find: id => `${filemanagement.FolderPlanController.base}/${id}`,
    delete: id => `${filemanagement.FolderPlanController.base}/${id}`,
    create: () => `${filemanagement.FolderPlanController.base}`,
    update: () => `${filemanagement.FolderPlanController.base}`,
    folderFormats: () => `${filemanagement.FolderPlanController.base}/folder-formats`,
  },
  FileManagementController: {
    base: '/api/file-system/devx-file-manager',
    serviceFolderMultipartFileUpload: (serviceName, companyId, entityId, documentClass) => `${filemanagement.FileManagementController.base}/service-folder/${serviceName}/${companyId}/${entityId}/${documentClass}/upload`,
    serviceFolderMultipartFileDownload: fileCloudId => `${filemanagement.FileManagementController.base}/service-folder/${fileCloudId}/download`,
    serviceFolderMultipartFileDelete: fileCloudId => `${filemanagement.FileManagementController.base}/service-folder/${fileCloudId}/delete`,
    getCompanyFolderPathByAccountNo: accountNo => `${filemanagement.FileManagementController.base}/get-company-path/${accountNo}`,
    getCurrentCompanyType: () => `${filemanagement.FileManagementController.base}/get-current-company-type`,
    serviceFolderDirectoryLists: (serviceName, companyId, entityId, documentClass) => `${filemanagement.FileManagementController.base}/service-folder/${serviceName}/${companyId}/${entityId}/${documentClass}/lists`,
  },
}
export default {
  filemanagement,
}
