<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch, onUnmounted } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  useWindowSize, useCssVar, // useEventSource,
} from '@vueuse/core'
import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  watch: {
    // versionData: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     // const data = JSON.parse(this.versionData)
    //     const versionUID = localStorage.getItem('version-data-uid')

    //     if (this.versionData === null) return
    //     if (this.versionData !== versionUID) {
    //       this.$swal({
    //         title: 'Please reload the page.',
    //         text: 'A New version is available for PSH App',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Reload the page',
    //         customClass: {
    //           confirmButton: 'btn btn-primary',
    //           cancelButton: 'btn btn-outline-danger ml-1',
    //         },
    //         buttonsStyling: false,
    //       }).then((async result => {
    //         if (result.value) {
    //           localStorage.setItem('version-data-uid', this.versionData)

    //           window.location.reload()
    //         }
    //       }))
    //     }
    //   },
    // },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout
    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    // const baseURL = process.env.VUE_APP_API_URL
    // const { data: versionData, close } = useEventSource(`${baseURL}/api/sse/web/release-info`, ['WEB_CLIENT_RELEASE'], { autoReconnect: true })

    onUnmounted(() => {
      // close()
    })

    return {
      skinClasses,
      enableScrollToTop,
      // versionData,
    }
  },
}
</script>
