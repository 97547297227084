<template>
  <span
    v-clipboard:copy="text"
    v-clipboard:success="onCopy"
    v-clipboard:error="onError"
    v-b-tooltip.hover="{ variant: 'warning', placement: 'left' }"
    title="Copy"
    class="hover-cursor-pointer"
    :class="cssClass"
  >
    <b-icon :icon="icon" variant="warning" :title="iconHint" />
  </span>
</template>

<script>
import { Notify } from '@robustshell/utils/index'

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    cssClass: {
      type: String,
      required: false,
      default: () => '',
    },
    icon: {
      type: String,
      required: false,
      default: 'back',
    },
    iconHint: {
      type: String,
      required: false,
      default: 'Copy',
    },
  },
  methods: {
    onCopy(e) {
      Notify.info(`Copied to clipboard: ${e.text}`, 'Copy')
    },
    onError(e) {
      Notify.danger('Failed to copy texts')
    },
  },
}
</script>

<style lang="scss" scoped>
  .hover-cursor-pointer {
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
    }
  }
</style>
