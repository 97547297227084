const ship = {
  Controller: {
    base: '/api/ship',
    // ClientShipmentItem
    findItemById: id => `${ship.Controller.base}/csv-upload/${id}`,
    findAllItemsByBatchId: (batchId, pageable) => `${ship.Controller.base}/csv-upload/batchId/${batchId}?${pageable}`,
    findAllItemsByCompanyIdAndStatus: (companyId, status, pageable) => `${ship.Controller.base}/csv-upload/company/${companyId}/${status}?${pageable}`,
    findReceivePageViewByQuery: pageable => `${ship.Controller.base}/csv-upload/query/receive-page-items?${pageable}`,
    findFbaShipmentBatchParentItemByQuery: pageable => `${ship.Controller.base}/csv-upload/query/fba-shipment-batch-items?${pageable}`,
    findByQuery: pageable => `${ship.Controller.base}/csv-upload/query?${pageable}`,
    findItemsByProductId: pageable => `${ship.Controller.base}/csv-upload/items-by-product?${pageable}`,
    getBySearchTerm: pageable => `${ship.Controller.base}/csv-upload/search?${pageable}`,
    getBySearchList: pageable => `${ship.Controller.base}/csv-upload/search/list?${pageable}`,
    csvUpload: params => `${ship.Controller.base}/csv-upload/create-many?batch_name=${params.batchName}&ecommerce_store=${params.store}&warehouseId=${params.warehouse}&receiveInventory=${params.receiveInventory}`,
    createItem: () => `${ship.Controller.base}/csv-upload/create`,
    createMissingItems: () => `${ship.Controller.base}/csv-upload/create-missing-items`,
    updateItem: () => `${ship.Controller.base}/csv-upload/update`,
    upsertItem: () => `${ship.Controller.base}/csv-upload/upsert`,
    queryUncompleted: pageable => `${ship.Controller.base}/csv-upload/query/uncompleted?${pageable}`,
    updateDraftItem: () => `${ship.Controller.base}/csv-upload/update-draft-item`,
    updateItemStatus: () => `${ship.Controller.base}/csv-upload/update-item-status`,
    updateMskuFnsku: itemId => `${ship.Controller.base}/csv-upload/update-msku-fnsku/${itemId}`,
    moveItems: () => `${ship.Controller.base}/csv-upload/move-items`,
    changeItemQuantity: params => `${ship.Controller.base}/csv-upload/update-quantity/${params.id}/${params.currentCount}/${params.newCount}`,
    receiveItems: () => `${ship.Controller.base}/csv-upload/receive-item`,
    receiveItemsByIdList: planId => `${ship.Controller.base}/csv-upload/receive-item-list/plan/${planId}`,
    receiveItemsDamaged: (batchId, id, count, packageScanId) => `${ship.Controller.base}/csv-upload/receive-item-damaged/${batchId}/${id}/${count}/${packageScanId}`,
    deleteItemById: id => `${ship.Controller.base}/csv-upload/${id}`,
    deleteManyItemsById: () => `${ship.Controller.base}/csv-upload/delete-many`,
    amazonInventorySummary: id => `${ship.Controller.base}/csv-upload/get-amazon-inventory-summary/${id}`,
    getShipmentPlanStats: batchId => `${ship.Controller.base}/csv-upload/batch/stats/${batchId}`,
    findAmazonRanks: pageable => `${ship.Controller.base}/csv-upload/stats/uploaded?${pageable}`,
    inboundItemUpdate: () => `${ship.Controller.base}/csv-upload/update/info`,
    resolveItemStatus: (id, resolution) => `${ship.Controller.base}/csv-upload/resolution/${id}/${resolution}`,

    // ClientShipmentBatch
    findBatchById: id => `${ship.Controller.base}/batch/${id}`,
    findAllBatches: pageable => `${ship.Controller.base}/batch?${pageable}`,
    findAllBatchesByQuery: pageable => `${ship.Controller.base}/batch/query?${pageable}`,
    findAllBatchesByQueryWarehouse: pageable => `${ship.Controller.base}/batch/all/query?${pageable}`,
    findAllBatchesForCurrentCompanyByQuery: (params, pageable) => `${ship.Controller.base}/batch/query?warehouseId=${params.warehouseId}&storeId=${params.storeId}&beginDate=${params.startDate}&endDate=${params.endDate}&status=${params.status}&${pageable}`,
    createBatch: () => `${ship.Controller.base}/batch/create`,
    updateBatch: () => `${ship.Controller.base}/batch`,
    completeBatchById: id => `${ship.Controller.base}/batch/${id}/complete`,
    deleteBatchById: id => `${ship.Controller.base}/batch/${id}`,
    submitDraftBatch: id => `${ship.Controller.base}/batch/submit-draft-batch/${id}`,

    // Client Bundle management
    findBundleById: id => `${ship.Controller.base}/bundle/${id}`,
    findItemsByBundleId: bundleId => `${ship.Controller.base}/bundle/bundle-items/${bundleId}`,
    findBundlesByQuery: (p, pageable) => `${ship.Controller.base}/bundle/query?warehouseId=${p.warehouseId}&companyId=${p.companyId}&accountNo=${p.accountNo}&storeId=${p.storeId}&batchId=${p.batchId}&status=${p.status}&packType=${p.packType}&title=${p.title}&upc=${p.upc}&asin=${p.asin}&${pageable}`,
    createBundle: () => `${ship.Controller.base}/bundle`,
    updateBundle: () => `${ship.Controller.base}/bundle/update`,
    deleteBundleById: id => `${ship.Controller.base}/bundle/${id}`,

    // Tracking management
    createTracking: () => `${ship.Controller.base}/csv-upload/tracking`,
    findTrackingsByItemId: id => `${ship.Controller.base}/csv-upload/tracking/by-item/${id}`,
    deleteTrackingById: id => `${ship.Controller.base}/csv-upload/tracking/${id}`,
    findItemsByTrackingId: (companyId, trackingId) => `${ship.Controller.base}/csv-upload/company/${companyId}/tracking/${trackingId}`,
    updateNotes: () => `${ship.Controller.base}/csv-upload/update/notes`,

    downloadBase: '/api/report/csv/download',
    getListToDownload: pageable => `${ship.Controller.downloadBase}/ship/csv-upload/search?${pageable}`,
  },
  // TODO Merge this with the above service
  BundleController: {
    base: '/api/ship/bundle',
    findByQuery: (params, pageable) => `${ship.BundleController.base}/query?${params}&${pageable}`,
    findBundleByProductId: params => `${ship.BundleController.base}/company/${params.companyId}/${params.productId}`,
    create: () => `${ship.BundleController.base}`,
    deleteById: id => `${ship.BundleController.base}/${id}`,
  },
}
export default {
  ship,
}
