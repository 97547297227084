export default {
  all: {
    value: 'all',
    key: 'all',
    text: 'All',
    disabled: true,
  },
  open: {
    value: 'open',
    key: 'open',
    text: 'Working',
    disabled: false,
  },
  boxing: {
    value: 'boxing',
    key: 'boxing',
    text: 'Boxing',
    disabled: false,
  },
  box_dims: {
    value: 'box_dims',
    key: 'box_dims',
    text: 'Shipping',
    disabled: false,
  },
  closed: {
    value: 'closed',
    key: 'closed',
    text: 'Closed',
    disabled: true,
  },
  all_but_closed: {
    value: 'all_but_closed',
    key: 'all_but_closed',
    text: 'Not Closed',
    disabled: true,
  },
}
