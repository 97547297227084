const billOfLading = {
  controller: {
    /*
    * FBM Order
    */
    bol: '/api/outbound/outbound-shipping/batch/bill-of-lading',
    getBolByProNumber: proNumber => `${billOfLading.controller.bol}/pro-number/${proNumber}`,
    getBolJobByBatchName: batchName => `${billOfLading.controller.bol}/batch-name/${batchName}`,
    addBolCharges: () => `${billOfLading.controller.bol}/pro-number/charge`,
  },
}
export default {
  billOfLading,
}
