/**
 * Enum for settings type. Created manualy
 * @readonly
 * @enum {{key: string, value: string}}
 */
const SettingsTypeKeyEnum = Object.freeze({
  CURRENT_USER_THEME_SETTING: { key: 'CURRENT_USER_THEME_SETTING', value: 'CURRENT_USER_THEME_SETTING' },
})

export default SettingsTypeKeyEnum
