import find from 'lodash/find'
/**
 * Enum for Company Sizes
 * @readonly
 * @enum {{key: string, value: string|null, text: string}}
 */

const companySizeEnum = Object.freeze({
  All: { key: 'All', value: 0, text: 'All companies' },
  '500+': { key: '500+', value: 500, text: '500+ units' },
  '2000+': { key: '2000+', value: 2000, text: '2000+ units' },
  '5000+': { key: '5000+', value: 5000, text: '5000+ units' },
  '10000+': { key: '10000+', value: 10000, text: '10000+ units' },
})

function getCompanySizeEnum(predicate, predicateKey = 'key') {
  const result = find(companySizeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: -1, text: 'None' }
}

function getCompanySizeList() {
  const options = []
  Object
    .keys(companySizeEnum)
    .map(key => options.push(
      {
        value: companySizeEnum[key].value,
        text: companySizeEnum[key].text,
      },
    ))
  return options
}

export default companySizeEnum

export {
  getCompanySizeEnum,
  getCompanySizeList,
}
