const membershipTypes = {
  Controller: {
    base: '/api/membership/types',
    create: () => `${membershipTypes.Controller.base}`,
    delete: id => `${membershipTypes.Controller.base}/${id}`,
    update: () => `${membershipTypes.Controller.base}`,
    getListByFilter: () => `${membershipTypes.Controller.base}/list`,
    getByPageableQuery: pageable => `${membershipTypes.Controller.base}/query${pageable}`,
    findById: id => `${membershipTypes.Controller.base}/${id}`,
  },
}

export default { membershipTypes }
