const problemManagement = {

  Controller: {
    base: '/api/problem-management/problem',

    // problemManagement Endpoints
    findAll: pageable => `${problemManagement.Controller.base}?${pageable}`,
    findById: id => `${problemManagement.Controller.base}/${id}`,
    findAllByType: type => `${problemManagement.Controller.base}/type/${type}`,
    findAllByQuery: params => `${problemManagement.Controller.base}/query?${params}`,
    findAllByStatus: status => `${problemManagement.Controller.base}/status/${status}`,
    findAllByProblemResolutionType: resolution => `${problemManagement.Controller.base}/resolution/${resolution}`,
    findAllByCompanyId: companyId => `${problemManagement.Controller.base}/company/${companyId}`,
    findAllByCompanyIdAndParams: (companyId, type, status) => `${problemManagement.Controller.base}/company/${companyId}/${type}/${status}`,
    create: () => `${problemManagement.Controller.base}`,
    takeAction: () => `${problemManagement.Controller.base}/take-action`,
    assignLocation: () => `${problemManagement.Controller.base}/assign-location`,
    update: () => `${problemManagement.Controller.base}`,
    delete: id => `${problemManagement.Controller.base}/${id}`,
    createMissingItemProblem: () => `${problemManagement.Controller.base}/create/missing`,
    downloadBase: '/api/report/csv/download',
    getListToDownload: params => `${problemManagement.Controller.downloadBase}/problem/query?${params}`,

  },
}
export default {
  problemManagement,
}
