const amazonReports = {
  Controller: {
    base: '/api/external/amazon',

    getRestockRecommendations: pageable => `${amazonReports.Controller.base}/restock-recommendations/query?${pageable}`,
    getOrderMetrics: storeId => `${amazonReports.Controller.base}/sales/order-metrics/${storeId}`,
    getMonthlySales: pageable => `${amazonReports.Controller.base}/sales/reports/monthly/query?${pageable}`,
    getInventoryByMsku: (storeId, msku) => `${amazonReports.Controller.base}/listing/get-by-msku/${storeId}/${msku}`,
    deleteAmazonListing: (storeId, msku) => `${amazonReports.Controller.base}/listing/delete/${storeId}/${msku}`,
    updateAmazonOnHandInventory: (storeId, msku, quantity) => `${amazonReports.Controller.base}/listing/update/quantity/${storeId}/${msku}/${quantity}`,

    inventoryBase: '/api/inventory',

    getInventoryHistoricalValues: pageable => `${amazonReports.Controller.inventoryBase}/history/query?${pageable}`,

    amazon: '/api/report/amz/monthly/sales',
    getMonthlySalesSummary: pageable => `${amazonReports.Controller.amazon}/query?${pageable}`,
    createMonthlySalesSummary: () => `${amazonReports.Controller.amazon}`,
    getItemsByMonthId: (monthId, pageable) => `${amazonReports.Controller.amazon}/item/${monthId}?${pageable}`,
    updateItemCost: () => `${amazonReports.Controller.amazon}/item`,
    deleteSalesSummary: id => `${amazonReports.Controller.amazon}/${id}`,
    getAmazonReport: pageable => `${amazonReports.Controller.amazon}/report?${pageable}`,
    getAmazonMonthlyReport: () => `${amazonReports.Controller.amazon}/chart-data`,
    getAmazonSumReport: () => `${amazonReports.Controller.amazon}/sum`,
    getAmazonSupplierReport: () => `${amazonReports.Controller.amazon}/suppliers`,
    salesByQuery: pageable => `${amazonReports.Controller.amazon}/by/query?${pageable}`,
    returnsByQuery: pageable => `${amazonReports.Controller.amazon}/return/query?${pageable}`,
    dashboardDataByQuery: () => `${amazonReports.Controller.amazon}/dashboard/query`,
    dashboardComparison: () => `${amazonReports.Controller.amazon}/dashboard/comparison`,
    dashboardSales: () => `${amazonReports.Controller.amazon}/dashboard/cost/overview`,
    dashboardIncomeStatement: () => `${amazonReports.Controller.amazon}/dashboard/income/statement`,
    updateBuyCost: () => `${amazonReports.Controller.amazon}/update/cost`,

    amz: '/api/report/amz',
    reimbursementsByQuery: pageable => `${amazonReports.Controller.amz}/reimbursements/query?${pageable}`,
    removalsByQuery: () => `${amazonReports.Controller.amz}/removal/query`,
    createRemoval: () => `${amazonReports.Controller.amz}/removal`,
    updateRemovalQuantity: (itemId, quantity) => `${amazonReports.Controller.amz}/removal/update/${itemId}/quantity/${quantity}`,
  },
  Download: {
    base: '/api/user-profile/download/',
    downloadTemplate: () => `${amazonReports.Download.base}/costupdateexceltemplate`,
  },
  Stats: {
    base: '/api/stats/inbound/item/cost-sales',
    getInboundItemCostSales: pageable => `${amazonReports.Stats.base}?${pageable}`,
  },
}
export default {
  amazonReports,
}
