export default {
  none: {
    value: 'none',
    key: 'none',
    text: 'Problem Status',
    disabled: false,
  },
  open: {
    value: 'open',
    key: 'open',
    text: 'Open',
    disabled: false,
  },
  waitingActionFromCustomer: {
    value: 'waiting_action_from_customer',
    key: 'waiting_action_from_customer',
    text: 'Waiting Action From Customer',
    disabled: false,
  },
  waitingActionFromPrepCenter: {
    value: 'waiting_action_from_prep_center',
    key: 'waiting_action_from_prep_center',
    text: 'Waiting Action From Prep Center',
    disabled: false,
  },
  investigating: {
    value: 'investigating',
    key: 'investigating',
    text: 'Investigating',
    disabled: false,
  },
  closedWithResolution: {
    value: 'closed_with_resolution',
    key: 'closed_with_resolution',
    text: 'Closed With Resolution',
    disabled: false,
  },
  lost: {
    value: 'lost',
    key: 'lost',
    text: 'Lost',
    disabled: false,
  },
  noActionTakenClosed: {
    value: 'no_action_taken_closed',
    key: 'no_action_taken_closed',
    text: 'No Action Taken Closed',
    disabled: false,
  },
}
