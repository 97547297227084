/**
 * Enum for notification preferences details.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const notificationPreferenceEnum = Object.freeze({
  TOPIC_NAME: {
    key: 'TOPIC_NAME',
    value: 'topicName',
    text: 'Notification Topic Name',
  },
  RECEIVERS: {
    key: 'RECEIVERS',
    value: 'receivers',
    text: 'Notification Receivers',
  },
  ENABLED: {
    key: 'NOTIFICATION_ENABLED',
    value: 'notificationEnabled',
    text: 'Notification Enabled',
  },
  FREQUENCY: {
    key: 'FREQUENCY',
    value: 'frequency',
    text: 'Notification Frequency',
  },
  SUB_TOPICS: {
    key: 'SUB_TOPICS',
    value: 'topics',
    text: 'Notification Sub Topics',
  },
})

export default notificationPreferenceEnum
