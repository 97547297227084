import Vue from 'vue'
import RobustShellGridPagination from './Grid/robustshell-grid-pagination.vue'
import RobustShellEnumCombobox from './Combobox/robustshell-enum-combobox.vue'
import RobustShellCombobox from './Combobox/robustshell-combobox.vue'
import RobustShellSelectbox from './Combobox/robustshell-selectbox.vue'
import RobustshellEnumBadge from './Enum/robustshell-enum-badge.vue'

export default function UseRobustShellComponent() {
  Vue.component('robustshell-grid-pagination', RobustShellGridPagination)
  Vue.component('robustshell-enum-combobox', RobustShellEnumCombobox)
  Vue.component('robustshell-combobox', RobustShellCombobox)
  Vue.component('robustshell-selectbox', RobustShellSelectbox)
  Vue.component('robustshell-enum-badge', RobustshellEnumBadge)
}
