export default {
  CRITICAL: {
    value: '0',
    text: 'Critical',
    key: 'CRITICAL',
    disabled: false,
    variant: 'danger',
  },
  MAJOR: {
    value: '1',
    text: 'Major',
    key: 'MAJOR',
    disabled: false,
    variant: 'primary',
  },
  MEDIUM: {
    value: '2',
    text: 'Medium',
    key: 'MEDIUM',
    disabled: false,
    variant: 'light',
  },
  LOW: {
    value: '3',
    text: 'Low',
    key: 'LOW',
    disabled: false,
    variant: 'warning',
  },
  INFO: {
    value: '4',
    text: 'Info',
    key: 'INFO',
    disabled: false,
    variant: 'info',
  },
}
