export default {
  select: {
    value: null,
    key: 'select',
    text: 'Move Reason',
    disabled: false,
  },
  restock_inventory: {
    value: 'restock_inventory',
    key: 'restock_inventory',
    text: 'Restock Inventory',
    disabled: false,
  },
  gated: {
    value: 'gated',
    key: 'gated',
    text: 'Gated',
    disabled: false,
  },
  missing_bundle_piece: {
    value: 'missing_bundle_piece',
    key: 'missing_bundle_piece',
    text: 'Missing Bundle Piece',
    disabled: false,
  },
  not_in_system: {
    value: 'not_in_system',
    key: 'not_in_system',
    text: 'Not In System',
    disabled: false,
  },
  no_store_listing: {
    value: 'no_store_listing',
    key: 'no_store_listing',
    text: 'No Store Listing',
    disabled: false,
  },
  mis_pick: {
    value: 'mis_pick',
    key: 'mis_pick',
    text: 'Mispick',
    disabled: false,
  },
  picture_not_match: {
    value: 'picture_not_match',
    key: 'picture_not_match',
    text: 'Picture Not Match',
    disabled: false,
  },
  asin_issue: {
    value: 'asin_issue',
    key: 'asin_issue',
    text: 'ASIN Issue',
    disabled: false,
  },
  hazmat: {
    value: 'hazmat',
    key: 'hazmat',
    text: 'Hazmat',
    disabled: false,
  },
  amazon_storage_limit: {
    value: 'amazon_storage_limit',
    key: 'amazon_storage_limit',
    text: 'Amazon Storage Limit',
    disabled: false,
  },
}
