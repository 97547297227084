/**
 * Enum for Membership Group Types
 * @readonly
 * @enum {{key: string, value: string|null, text: string}}
 */

const membershipSubscriptionStatusEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'All', text: 'All' },
  ACTIVE: { key: 'ACTIVE', value: 'Active', text: 'Active' },
  CANCELLED: { key: 'CANCELLED', value: 'Cancelled', text: 'Cancelled' },
  CLOSED: { key: 'CLOSED', value: 'Closed', text: 'Closed' },
})

function getSubscriptionStatusList() {
  const options = []
  Object
    .keys(membershipSubscriptionStatusEnum)
    .map(key => options.push(
      {
        value: membershipSubscriptionStatusEnum[key].value,
        text: membershipSubscriptionStatusEnum[key].text,
      },
    ))
  return options
}

export default membershipSubscriptionStatusEnum

export {
  getSubscriptionStatusList,
}
