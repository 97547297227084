import find from 'lodash/find'
/**
 * Enum for Buyer Type (Amazon  Sales API v1)
 * Filters the results by the buyer type that you specify,
 * B2B (business to business) or B2C (business to customer).
 * Example: B2B, if you want the response to include order metrics for only B2B buyers.
* @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const buyerTypeEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'All', text: 'Buyer Type (All)' },
  B2B: { key: 'B2B', value: 'B2B', text: 'B2B' },
  B2C: { key: 'B2C', value: 'B2C', text: 'B2C' },
})

function getBuyerTypeEnum(predicate, predicateKey = 'key') {
  const result = find(buyerTypeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: -1, text: 'None' }
}

function getBuyerTypeList() {
  const options = []
  Object
    .keys(buyerTypeEnum)
    .map(key => options.push(
      {
        value: buyerTypeEnum[key].value,
        text: buyerTypeEnum[key].text,
      },
    ))
  return options
}

export default buyerTypeEnum

export {
  getBuyerTypeEnum,
  getBuyerTypeList,
}
