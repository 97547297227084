export default {
  inbound: {
    value: 'inbound',
    key: 'inbound',
    text: 'Inbound',
    disabled: false,
    variant: 'warning',
  },
  inventory: {
    value: 'inventory',
    key: 'inventory',
    text: 'Inventory',
    disabled: false,
    variant: 'success',
  },
}
