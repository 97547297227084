/**
 * Enum for Membership Types
 * @readonly
 * @enum {{key: string, value: string|null, text: string}}
 */

const membershipAudienceTypeEnum = Object.freeze({
  MERCHANT: { key: 'MERCHANT', value: 'MERCHANT', text: 'MERCHANT' },
  WAREHOUSE: { key: 'WAREHOUSE', value: 'WAREHOUSE', text: 'WAREHOUSE' },
  // COMPANY: { key: 'COMPANY', value: 'COMPANY', text: 'COMPANY' },
  // STORE: { key: 'STORE', value: 'STORE', text: 'STORE' },
  // USER: { key: 'USER', value: 'USER', text: 'USER' },
})

function getAudienceTypeList() {
  const options = []
  Object
    .keys(membershipAudienceTypeEnum)
    .map(key => options.push(
      {
        value: membershipAudienceTypeEnum[key].value,
        text: membershipAudienceTypeEnum[key].text,
      },
    ))
  return options
}

export default membershipAudienceTypeEnum

export {
  getAudienceTypeList,
}
