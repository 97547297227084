import find from 'lodash/find'

/**
 * Enum for Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */
const statusEnum = Object.freeze({
  OPEN: { key: 'OPEN', value: 'OPEN', text: 'Open' },
  WORKING: { key: 'WORKING', value: 'WORKING', text: 'Working' },
  COMPLETED: { key: 'COMPLETED', value: 'COMPLETED', text: 'Completed' },
  ON_HOLD: { key: 'ON_HOLD', value: 'ON_HOLD', text: 'On Hold' },
})

function getStatusEnumValue(predicate, predicateKey = 'key') {
  const result = find(statusEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getStatusEnumList() {
  const options = []
  Object.keys(statusEnum).forEach(key => {
    options.push({
      value: statusEnum[key].value,
      text: statusEnum[key].text,
    })
  })
  return options
}

export default statusEnum

export {
  getStatusEnumValue,
  getStatusEnumList,
}
