import mailTenant from '@/http/endpoints/mail/mailTenant'
import mailLog from '@/http/endpoints/mail/mailLog'
import ebay from '@/http/endpoints/external/ebay'
import shopify from '@/http/endpoints/external/shopify'
import system from './system'
import company from './company'
import common from './common'
import frontend from './fontend'
import inventory from './inventory'
import outboundShipping from './outboundShipping'
import ship from './ship'
import prepMaterial from './prepMaterial/prepMaterial'
import prepMaterialInventory from './prepMaterial/prepMaterialInventory'
import prepMaterialPurchase from './prepMaterial/prepMaterialPurchase'
import problemManagement from './problemManagement'
import costGroup from './finance/costGroup'
import invoiceItems from './finance/invoiceItems'
import invoice from './finance/invoice'
import prepItemCost from './finance/prepItemCost'
import itemCharge from './finance/itemCharge'
import customerCharges from './finance/customerCharges'
import customerBalances from './finance/customerBalances'
import product from './product'
import upcAndCodes from './product/upcAndCodes'
import products from './product/products'
import productCategory from './product/productCategory'
import manufacturer from './product/manufacturer'
import packageReceiveBatch from './inbound/packageReceiveBatch'
import packageScan from './inbound/packageScan'
import receivedSortedItems from './inbound/receivedSortedItems'
import sortItem from './inbound/sort-item'
import carriers from './common/carriers'
import planManagement from './plan/planManagement'
import warehouseLocation from './warehouseLocation'
import warehouse from './warehouse'
import pricing from './pricing'
import reports from './reports/reports'
import amazonReports from './reports/amazon'
import fileManagement from './filemanagement/fileManagement'
import mailTopic from './mail/mailTopic'
import mailTopicCompany from './mail/mailTopicCompany'
import basicStats from './stats/basicStats'
import notification from './notification/notification'
import batchProcess from './batchProcess'
import externalAmazon from './external'
import api2Cart from './external/api2cart'
import goshippo from './external/goshippo'
import stax from './external/stax'
import settings from './common/settings'
import tenant from './tenant'
import fbmOrder from './outbound/fbmOrder'
import maintenance from './common/maintenance'
import printerManagement from './printer/printerManagement'
import membershipManagement from './membership/membershipManagement'
import chat from './chat/chat'
import chatManagement from './chat/chatManagement'
import fbm from './fbm/fbm.js'
import billOfLading from './outbound/billOfLading'
import membershipTypes from './membership/types'
import membershipTypeDetails from './membership/typeDetails'
import membershipPlans from './membership/plans'
import membershipPlanDetails from './membership/planDetails'
import membershipSubscribers from './membership/subscribers'
import membershipCharges from './membership/charges'
import label from './external/label.js'
import wfs from './wfs'
import shipmentLabels from './external/shipmentLabels'

const endpoints = {
  ...system,
  ...company,
  ...common,
  ...frontend,
  ...inventory,
  ...outboundShipping,
  ...ship,
  ...prepMaterial,
  ...prepMaterialInventory,
  ...prepMaterialPurchase,
  ...problemManagement,
  ...costGroup,
  ...invoiceItems,
  ...invoice,
  ...prepItemCost,
  ...itemCharge,
  ...customerCharges,
  ...customerBalances,
  ...product,
  ...upcAndCodes,
  ...products,
  ...productCategory,
  ...manufacturer,
  ...packageReceiveBatch,
  ...packageScan,
  ...receivedSortedItems,
  ...sortItem,
  ...carriers,
  ...planManagement,
  ...warehouse,
  ...warehouseLocation,
  ...pricing,
  ...amazonReports,
  ...reports,
  ...fileManagement,
  ...mailTopic,
  ...mailTopicCompany,
  ...mailTenant,
  ...mailLog,
  ...basicStats,
  ...notification,
  ...batchProcess,
  ...externalAmazon,
  ...api2Cart,
  ...stax,
  ...settings,
  ...tenant,
  ...fbmOrder,
  ...goshippo,
  ...maintenance,
  ...ebay,
  ...shopify,
  ...printerManagement,
  ...membershipManagement,
  ...chat,
  ...chatManagement,
  ...fbm,
  ...billOfLading,
  ...membershipTypes,
  ...membershipTypeDetails,
  ...membershipPlans,
  ...membershipPlanDetails,
  ...membershipCharges,
  ...membershipSubscribers,
  ...label,
  ...wfs,
  ...shipmentLabels,
}
export default endpoints
