<template>
  <span>
    <i :class="isClicked ? 'icon-feather-check text-muted': 'icon-feather-copy text-warning'" class="picon " role="button" @click="copyIt" />
  </span>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['copyValue'],
  data() {
    return {
      isClicked: false,
    }
  },
  methods: {
    async copyIt() {
      this.isClicked = true
      await navigator.clipboard.writeText(this.copyValue)
      setTimeout(() => {
        this.isClicked = false
      }, 3000)
    },
  },
}
</script>

<style lang="scss" scoped>
span {
  margin-left: 4px;
  margin-right: 4px;
}
</style>
