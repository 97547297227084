/* eslint-disable no-unused-vars */
import { $themeConfig } from '@themeConfig'
import settingsService from '@/http/requests/common/settings'
import themes from 'devextreme/ui/themes'
import settingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import SettingsTypeKeyEnum from '@/enums/settings-type-key.enum'

export default {
  namespaced: true,
  state: {
    layout: {
      isRTL: $themeConfig.layout.isRTL,
      skin: localStorage.getItem('theme-skin') || $themeConfig.layout.skin,
      dxTheme: localStorage.getItem('theme-component-skin') || $themeConfig.layout.dxTheme,
      routerTransition: $themeConfig.layout.routerTransition,
      type: localStorage.getItem('theme-layout-type') || $themeConfig.layout.type,
      contentWidth: $themeConfig.layout.contentWidth,
      menu: {
        hidden: $themeConfig.layout.menu.hidden,
      },
      navbar: {
        type: $themeConfig.layout.navbar.type,
        backgroundColor: $themeConfig.layout.navbar.backgroundColor,
      },
      footer: {
        type: $themeConfig.layout.footer.type,
      },
    },
  },
  getters: {},
  mutations: {
    TOGGLE_RTL(state) {
      state.layout.isRTL = !state.layout.isRTL
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj)
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj)
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      localStorage.setItem('theme-layout-type', val)
      state.layout.type = val
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin
      localStorage.setItem('theme-skin', skin)
      if (skin === 'dark') document.body.classList.add('dark-layout')
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
    },
    UPDATE_DX_SKIN(state, skin) {
      let dxTheme = ''
      switch (skin) {
        case 'light':
          dxTheme = 'generic.light'
          break
        case 'dark':
          dxTheme = 'generic.dark'
          break
        default:
          dxTheme = 'generic.dark'
          break
      }

      state.layout.dxTheme = dxTheme
      localStorage.setItem('theme-component-skin', dxTheme)
      themes.current(dxTheme)
    },
    async UPDATE_USERSETTING(state, value) {
      const { skin, dxTheme, type } = state.layout
      const userData = JSON.parse(localStorage.getItem('userData'))
      const data = {
        themeSkin: skin,
        themeComponentSkin: dxTheme,
        themeLayoutType: type,
      }
      const userThemeSettings = {
        companyId: userData.companyId,
        referenceId: userData.id,
        referenceType: referenceTypeEnum.USER.value,
        settingType: settingsTypeEnum.THEMESETTINGS.value,
        settingKey: SettingsTypeKeyEnum.CURRENT_USER_THEME_SETTING.value,
        description: `${userData.fullname} theme settings`,
        settingName: `${userData.fullname} theme settings`,
        value: JSON.stringify(data),
      }
      await settingsService.createOrUpdate(userThemeSettings)
    },
  },
  actions: {
    TOGGLE_RTL(context, payload) {
      context.commit('TOGGLE_RTL', payload)
    },
    UPDATE_FOOTER_CONFIG(context, payload) {
      context.commit('UPDATE_FOOTER_CONFIG', payload)
    },
    UPDATE_NAVBAR_CONFIG(context, payload) {
      context.commit('UPDATE_NAVBAR_CONFIG', payload)
    },
    UPDATE_ROUTER_TRANSITION(context, payload) {
      context.commit('UPDATE_ROUTER_TRANSITION', payload)
    },
    UPDATE_CONTENT_WIDTH(context, payload) {
      context.commit('UPDATE_CONTENT_WIDTH', payload)
    },
    UPDATE_NAV_MENU_HIDDEN(context, payload) {
      context.commit('UPDATE_NAV_MENU_HIDDEN', payload)
    },
    async UPDATE_LAYOUT_TYPE(context, payload) {
      await context.commit('UPDATE_LAYOUT_TYPE', payload)
      await context.commit('UPDATE_USERSETTING', null)
    },
    async UPDATE_SKIN(context, payload) {
      await context.commit('UPDATE_SKIN', payload)
      await context.commit('UPDATE_DX_SKIN', payload)
      await context.commit('UPDATE_USERSETTING', null)
    },
  },
}
