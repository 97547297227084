const customerBalances = {

  Controller: {
    base: '/api/finance/customer/balances',

    // customerBalances Endpoints
    findById: id => `${customerBalances.Controller.base}/id/${id}`,
    findAllByCompanyId: companyId => `${customerBalances.Controller.base}/company/${companyId}`,
    findAllByStoreId: storeId => `${customerBalances.Controller.base}/store/${storeId}`,
    findAllByInvoiceId: invoiceId => `${customerBalances.Controller.base}/invoice/${invoiceId}`,
    // params format: {companyId: 'id', stores: [id1,id2,..], warehouseId: 'id', balanceType: [1,2,...], beginDate: 'date', endDate: 'date'}
    findAllByQuery: params => `${customerBalances.Controller.base}/query?companyId=${params.companyId}&stores=${params.storeIds}&warehouseId=${params.warehouseId}&balanceType=${params.chargeType}&beginDate=${params.beginDate}&endDate=${params.endDate}`,
    create: () => `${customerBalances.Controller.base}`,
    update: () => `${customerBalances.Controller.base}`,
    delete: id => `${customerBalances.Controller.base}/${id}`,

    // customer balances for shippin label
    fetchBalancesByType: pageable => `${customerBalances.Controller.base}/query?${pageable}`,
    createBalanceTransaction: () => `${customerBalances.Controller.base}/deposit`,
    getBalanceData: () => `${customerBalances.Controller.base}/balance`,
  },
}
export default {
  customerBalances,
}
