<template>
  <i v-if="isBiIcon"
    :style="{fontSize: size, color: color, padding: 0 }"
    :class="`bi ${iconName} text-${color} picon`"
  />
  <i v-else
    :style="{fontSize: size, color: color, padding: 0 }"
    :class="`icon-${iconName} text-${color}`"
  />
</template>

<script>
// if scss variables sent as prop it will be used, if css color properties (#FfefeF or aqua, lime ...) is sent they will be used for coloring.
export default {
  props: {
    name: {
      type: String,
      default: 'feather-circle',
    },
    size: {
      type: String,
      default: '18px',
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    isBiIcon() {
      return this.name.startsWith('bi-')
    },
    // isPshIcon() {
    //   return this.name.startsWith('psh-')
    // },
    iconName() {
      return this.name || 'feather-circle'
    },
  },
}
</script>

<style lang="scss" scoped>
.picon {
  line-height: 0;
  padding: 0;
}
</style>
