const receivedSortedItem = {

  Controller: {
    base: '/api/inbound/received-sorted-item',

    findById: id => `${receivedSortedItem.Controller.base}/${id}`,
    findAllByQuery: (query, pageable) => `${receivedSortedItem.Controller.base}/query?${query}&${pageable}`,
    findAllBySearchTerm: pageable => `${receivedSortedItem.Controller.base}/search?${pageable}`,
    createOrAdd: () => `${receivedSortedItem.Controller.base}`,
    changeToDamagedItems: id => `${receivedSortedItem.Controller.base}/receive-item-damaged/${id}`,
    changeItemQuantity: params => `${receivedSortedItem.Controller.base}/update-quantity/${params.id}/${params.currentCount}/${params.newCount}`,
    update: () => `${receivedSortedItem.Controller.base}/update`,
    sendBackItems: () => `${receivedSortedItem.Controller.base}/send-back-sorted`,
    deleteById: id => `${receivedSortedItem.Controller.base}/${id}`,
  },
}
export default {
  receivedSortedItem,
}
