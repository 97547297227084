const chatManagement = {

  Controller: {
    room: '/api/chat/room',
    auth: '/api/chat/auth',
    message: '/api/chat/message',

    // Room Controller Endpoints
    getRoomList: () => `${chatManagement.Controller.room}/user`,
    getRoomUserByRoomIdAndUserId: (roomId, userId) => `${chatManagement.Controller.room}/user/${roomId}/${userId}`,
    createRoom: () => `${chatManagement.Controller.room}`,
    deleteRoom: id => `${chatManagement.Controller.room}/${id}`,
    addUser: () => `${chatManagement.Controller.room}/user/add`,

    // Message Controller Endpoints
    getMessages: roomId => `${chatManagement.Controller.message}/room/${roomId}`,
    sendMessage: () => `${chatManagement.Controller.message}/send`,
    updateMessage: id => `${chatManagement.Controller.message}/${id}`,
    deleteMessage: id => `${chatManagement.Controller.message}/${id}`,
    markMessageSeen: id => `${chatManagement.Controller.message}/seen/${id}`,
    getUnReadCount: () => `${chatManagement.Controller.message}/unread-count`,

    // Auth Controller Endpoints
    generateSocketToken: () => `${chatManagement.Controller.auth}/token`,
    generateRefreshToken: () => `${chatManagement.Controller.auth}/refresh-token`,
  },
}

export default {
  chatManagement,
}
