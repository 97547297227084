import find from 'lodash/find'
/**
 * Enum for client shipment item status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const clientShipmentItemStatusEnum = Object.freeze({
  all: {
    key: 'all', value: 12, text: 'Status (All)', visible: true,
  },
  intransit: {
    key: 'intransit', value: 0, text: 'Intransit', visible: true,
  },
  partially_delivered: {
    key: 'partially_delivered', value: 1, text: 'Partially Delivered', visible: true,
  },
  delivered: {
    key: 'delivered', value: 2, text: 'Delivered', visible: true,
  },
  received: {
    key: 'received', value: 3, text: 'Received', visible: false,
  },
  inprep: {
    key: 'inprep', value: 4, text: 'Inprep', visible: false,
  },
  stored: {
    key: 'stored', value: 5, text: 'Stored', visible: false,
  },
  shipped: {
    key: 'shipped', value: 6, text: 'Shipped', visible: false,
  },
  fulfilled: {
    key: 'fulfilled', value: 7, text: 'Fulfilled', visible: true,
  },
  returned: {
    key: 'returned', value: 8, text: 'Returned', visible: false,
  },
  disposed: {
    key: 'disposed', value: 9, text: 'Disposed', visible: false,
  },
  blocked: {
    key: 'blocked', value: 10, text: 'Blocked', visible: true,
  },
  archived: {
    key: 'archived', value: 11, text: 'Archived', visible: false,
  },
})

export default clientShipmentItemStatusEnum

function getClientShipmentItemStatusEnum(predicate, predicateKey = 'key') {
  const result = find(clientShipmentItemStatusEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getClientShipmentItemStatusEnumList() {
  const options = []
  Object
    .keys(clientShipmentItemStatusEnum)
    .filter(key => clientShipmentItemStatusEnum[key].visible)
    .map(key => options.push(
      {
        value: clientShipmentItemStatusEnum[key].value,
        text: clientShipmentItemStatusEnum[key].text,
      },
    ))
  return options
}

export {
  getClientShipmentItemStatusEnum,
  getClientShipmentItemStatusEnumList,
}
