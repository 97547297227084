/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  state() {
    return {
      batchBoxes: [],
    }
  },
  mutations: {
    setBatchBoxes(state, payload) {
      state.batchBoxes.splice(0, state.batchBoxes.length)
      state.batchBoxes = [...payload]
    },
    addBatchBoxes(state, payload) {
      state.batchBoxes.push(payload)
    },
    clearBatchBoxes(state, payload) {
      state.batchBoxes.splice(0, state.batchBoxes.length)
    },
  },
  actions: {
    setBatchBoxes(context, payload) {
      context.commit('setBatchBoxes', payload)
    },
    addBatchBoxes(context, payload) {
      context.commit('addBatchBoxes', payload)
    },
    clearBatchBoxes(context, payload) {
      context.commit('clearBatchBoxes', payload)
    },
  },
  getters: {
    getBatchBoxes(state) {
      return state.batchBoxes
    },
  },
}
