import { v4 as uuidv4 } from 'uuid'

const uuidPlugin = {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$uid = uuidv4
  },
}

export default uuidPlugin
