export default {
  PROBLEM: {
    value: 'PROBLEM',
    text: 'Problem',
    key: 'PROBLEM',
    disabled: false,
  },
  BATCH_SHIPMENT: {
    value: 'BATCH_SHIPMENT',
    text: 'Batch Shipment',
    key: 'BATCH_SHIPMENT',
    disabled: false,
  },
  ITEM_QUANTITY_CHANGE: {
    value: 'ITEM_QUANTITY_CHANGE',
    text: 'Item Quantity Change',
    key: 'ITEM_QUANTITY_CHANGE',
    disabled: false,
  },
  INBOUND_ITEM_STATUS_CHANGE: {
    value: 'INBOUND_ITEM_STATUS_CHANGE',
    text: 'Inbound Item Status Changed',
    key: 'INBOUND_ITEM_STATUS_CHANGE',
    disabled: false,
  },
}
