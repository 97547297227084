import find from 'lodash/find'
/**
 * Enum for Tenants Types
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const tenantTypesEnum = Object.freeze({
  SERVING: { key: 'SERVING', value: 'SERVING', text: 'Serving' },
  INDIVIDUAL: { key: 'INDIVIDUAL', value: 'INDIVIDUAL', text: 'Individual' },
  PARTNER: { key: 'PARTNER', value: 'PARTNER', text: 'Partner' },
})

function getTenantTypeEnum(predicate, predicateKey = 'key') {
  const result = find(tenantTypesEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getTenantTypeList() {
  const options = []
  Object
    .keys(tenantTypesEnum)
    .map(key => options.push(
      {
        value: tenantTypesEnum[key].value,
        text: tenantTypesEnum[key].text,
      },
    ))
  return options
}

export default tenantTypesEnum

export {
  getTenantTypeEnum,
  getTenantTypeList,
}
