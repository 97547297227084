const frontend = {

  auditPages: {
    // Controllorın url prefixi
    base: '/pages',

  },

  homePage: {
    base: '/',
    dashBoardHomePath: () => `${frontend.homePage.base}`,
  },

  accountPages: {
    // Controllorın url prefixi
    base: '/pages',
    registerPage: () => `${frontend.accountPages.base}/register`,
    minimumPasswordLength: () => 4,
    forgetPasswordPage: () => `${frontend.accountPages.base}/forgot-password`,
    resetPasswordPage: () => `${frontend.accountPages.base}/reset-password`,
    lockScreenPage: () => `${frontend.accountPages.base}/lock-screen`,
    notAuthorizedPage: () => `${frontend.accountPages.base}/not-authorized`,
    loginPage: () => `${frontend.accountPages.base}/login`,

  },
  authorityPages: {
    // Controllorın url prefixi
    base: '/pages',
    authorityPage: () => `${frontend.accountPages.base}/authority`,
  },
  outboundPages: {
    base: '/apps/outbound',
    fbaShippingPage: () => `${frontend.outboundPages.base}/fba-shipping`,
  },
  userPages: {
    base: '/pages',
  },
  userProfilePages: {
    base: '/pages',
  },
}
export default {
  frontend,
}
