const mailTopic = {
  Controller: {
    base: '/api/mail/mail-topic',
    findAll: params => `${mailTopic.Controller.base}${params}`,
    findById: id => `${mailTopic.Controller.base}/${id}`,
    create: () => `${mailTopic.Controller.base}`,
    update: () => `${mailTopic.Controller.base}`,
    delete: id => `${mailTopic.Controller.base}/${id}`,
  },
}
export default {
  mailTopic,
}
