const manufacturer = {

  Controller: {
    base: '/api/product/manufacturer',

    // manufacturer Endpoints
    findAll: params => `${manufacturer.Controller.base}${params}`,
    findById: id => `${manufacturer.Controller.base}/${id}`,
    findByName: name => `${manufacturer.Controller.base}/name/${name}`,
    create: () => `${manufacturer.Controller.base}`,
    update: () => `${manufacturer.Controller.base}`,
    delete: id => `${manufacturer.Controller.base}/${id}`,
  },
}
export default {
  manufacturer,
}
