export default [
  {
    path: '/apps/outbound/outbound-shipping/:channel?',
    name: 'fba-outbound-shipping',
    component: () => import('@/views/apps/outbound/shipment-batches/ShipmentBatches.vue'),
    meta: {
      resource: 'resource_outbound_shipment_batches',
      action: 'read',
      pageTitle: 'Shipment Batches',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'FBA',
        },
        {
          text: 'Shipment Batches',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/outbound-shipping/:channel?',
    name: 'wfs-outbound-shipping',
    component: () => import('@/views/apps/outbound/shipment-batches/ShipmentBatches.vue'),
    meta: {
      resource: 'resource_outbound_shipment_batches',
      action: 'read',
      pageTitle: 'Shipment Batches',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'WFS',
        },
        {
          text: 'Shipment Batches',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/outbound-shipping/batch-items',
    name: 'outbound-shipping-batch-item',
    component: () => import('@/views/apps/outbound/shipment-batches/ShipmentItems.vue'),
    meta: {
      resource: 'resource_outbound_shipment_batches',
      action: 'read',
      pageTitle: 'Shipment Items',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'Shipment Batches',
          to: '/outbound-shipping',
        },
        {
          text: 'Items',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/fbm-shipments',
    name: 'fbm-shipments',
    component: () => import('@/views/apps/outbound/fbm-shipments/FbmShipments.vue'),
    meta: {
      resource: 'outbound_fbm_shipment',
      action: 'read',
      pageTitle: 'FBM Shipments',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'FBM Shipments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/fbm-force',
    name: 'fbm-force',
    component: () => import('@/views/apps/outbound/fbm-shipping/FbmForce.vue'),
    meta: {
      resource: 'resource_outbound_fbm_force_main',
      action: 'read',
      pageTitle: 'FBM Force',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'FBM Force',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/fbm-force/pick-list',
    name: 'fbm-pick-list',
    component: () => import('@/views/apps/outbound/fbm-shipping/components/FbmPickList.vue'),
    meta: {
      resource: 'resource_outbound_fbm_force_main',
      action: 'read',
      pageTitle: 'FBM Pick List',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'FBM Force',
          to: '/apps/outbound/fbm-force',
        },
        {
          text: 'FBM Pick List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/fbm-force/buy-bulk-shipping',
    name: 'buy-bulk-shipping',
    component: () => import('@/views/apps/outbound/fbm-shipping/components/BuyBulkShipping.vue'),
    meta: {
      resource: 'resource_outbound_fbm_force_main',
      action: 'read',
      pageTitle: 'Buy Shipping Label in Bulk',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'FBM Force',
          to: '/apps/outbound/fbm-force',
        },
        {
          text: 'Buy Shipping Label in Bulk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/fbm-force/orders-file-upload',
    name: 'orders-file-upload',
    component: () => import('@/views/apps/outbound/fbm-shipping/components/orders-file-upload/OrdersFileUpload.vue'),
    meta: {
      resource: 'resource_outbound_fbm_force_main',
      action: 'read',
      pageTitle: 'FBM Orders Upload',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'FBM Force',
          to: '/apps/outbound/fbm-force',
        },
        {
          text: 'Orders File Upload',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/fba-shipments',
    name: 'fba-shipments',
    component: () => import('@/views/apps/outbound/fba-shipments/AccountSelect.vue'),
    meta: {
      resource: 'resource_outbound_fba_force_main',
      action: 'read',
      pageTitle: 'FBA Force',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'Account Select',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/fba-shipments/:accountNo?/:workflow?/:batchId?/:channel?',
    name: 'fba-shipments-workflow',
    component: () => import('@/views/apps/outbound/fba-shipments/FbaShipments.vue'),
    meta: {
      resource: 'resource_outbound_fba_force_main',
      action: 'read',
      pageTitle: 'FBA Shipments',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'Account Select',
          to: '/apps/outbound/fba-shipments',
        },
        {
          text: 'FBA Shipments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/snapshot/pending-batches/:channel?',
    name: 'fba-pending-batches',
    component: () => import('@/views/dashboard/statistics/PendingBatches.vue'),
    meta: {
      resource: 'resource_outbound_shipment_batches',
      action: 'read',
      pageTitle: 'Open Shipment Plans',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard/snapshot',
        },
        {
          text: 'FBA',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'Open Shipment Plans',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/snapshot/pending-batches/:channel?',
    name: 'wfs-pending-batches',
    component: () => import('@/views/dashboard/statistics/PendingBatches.vue'),
    meta: {
      resource: 'resource_outbound_shipment_batches',
      action: 'read',
      pageTitle: 'Open Shipment Plans',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard/snapshot',
        },
        {
          text: 'WFS',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'Open Shipment Plans',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/fba-bol',
    name: 'fba-bols',
    component: () => import('@/views/apps/outbound/fba-bols/FbaBols.vue'),
    meta: {
      resource: 'resource_outbound_fba_bill_of_lading',
      action: 'read',
      pageTitle: 'FBA Bill of Ladings',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'FBA Bill of Ladings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/wfs',
    name: 'wfs-account-select',
    component: () => import('@/views/apps/outbound/wfs/AccountSelect.vue'),
    meta: {
      resource: 'resource_wfs',
      action: 'read',
      pageTitle: 'WFS Force',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'Account Select',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/wfs/:workflow?/:accountNo?/:companyId?/:storeId?/:shipmentPlanId?',
    name: 'wfs-shipments-workflow',
    component: () => import('@/views/apps/outbound/wfs/WfsShipments.vue'),
    meta: {
      resource: 'resource_wfs',
      action: 'read',
      pageTitle: 'WFS Shipments',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'Account Select',
          to: '/apps/outbound/wfs',
        },
        {
          text: 'WFS Shipments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/outbound/wfs/shipping/templates',
    name: 'wfs-shipping-templates',
    component: () => import('@/views/apps/outbound/wfs/WfsTemplates.vue'),
    meta: {
      resource: 'resource_wfs',
      action: 'read',
      pageTitle: 'WFS Shipping Templates',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Outbound',
        },
        {
          text: 'WFS Shipping Templates',
          active: true,
        },
      ],
    },
  },
]
