import find from 'lodash/find'
/**
 * Enum for Merchant Connect Underwriting Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const expenseSourceTypeEnum = Object.freeze({
  Amazon: { key: 'Amazon', value: 'Amazon', text: 'Amazon' },
  Generated: { key: 'Generated', value: 'Generated', text: 'Generated' },
  Manual: { key: 'Manual', value: 'Manual', text: 'Manual' },
  WFS: { key: 'WFS', value: 'WFS', text: 'WFS' },
})

function getExpenseSourceTypeEnum(predicate, predicateKey = 'key') {
  const result = find(expenseSourceTypeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getExpenseSourceTypeList() {
  const options = []
  Object
    .keys(expenseSourceTypeEnum)
    .map(key => options.push(
      {
        value: expenseSourceTypeEnum[key].value,
        text: expenseSourceTypeEnum[key].text,
      },
    ))
  return options
}

export default expenseSourceTypeEnum

export {
  getExpenseSourceTypeEnum,
  getExpenseSourceTypeList,
}
