import find from 'lodash/find'
/**
 * Enum for customer plan status.
 * @readonly
 * @enum {{key: string, text: string, value: string}}
 */
const customerPlanStatusEnum = Object.freeze({
  RESERVED_ALL: {
    value: 'reserved_all',
    text: 'Status (All)',
    key: 'RESERVED_ALL',
  },
  ACTIVE: {
    value: 'active',
    text: 'Active',
    key: 'ACTIVE',
  },
  PASSIVE: {
    value: 'passive',
    text: 'Passive',
    key: 'PASSIVE',
  },
  PENDING: {
    value: 'pending',
    text: 'Pending',
    key: 'PENDING',
  },
  REJECTED: {
    value: 'rejected',
    text: 'Rejected',
    key: 'REJECTED',
  },
})

function getCustomerPlanStatusEnum(predicate, predicateKey = 'key') {
  const result = find(customerPlanStatusEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getCustomerPlanStatusList() {
  return Object.keys(customerPlanStatusEnum).map(key => ({ value: customerPlanStatusEnum[key].value, text: customerPlanStatusEnum[key].text }))
}

function getCustomerPlanStatusOptions() {
  return Object.keys(customerPlanStatusEnum).map(key => ({ value: customerPlanStatusEnum[key].value, text: customerPlanStatusEnum[key].text })).filter(item => item.value !== 'reserved_all')
}

function getPlanStatusList() {
  return Object.keys(customerPlanStatusEnum).map(key => ({ value: customerPlanStatusEnum[key].value, text: customerPlanStatusEnum[key].text })).filter(item => item.value !== 'pending' && item.value !== 'rejected')
}

function getPlanStatusOptions() {
  return Object.keys(customerPlanStatusEnum).map(key => ({ value: customerPlanStatusEnum[key].value, text: customerPlanStatusEnum[key].text })).filter(item => item.value !== 'pending' && item.value !== 'rejected' && item.value !== 'reserved_all')
}

export default customerPlanStatusEnum

export {
  getCustomerPlanStatusEnum,
  getCustomerPlanStatusList,
  getCustomerPlanStatusOptions,
  getPlanStatusList,
  getPlanStatusOptions,
}
