export default [
  {
    path: '/apps/printermanagement/vendors',
    name: 'printer-vendors',
    component: () => import('@/views/apps/printer-managements/vendor/Vendor.vue'),
    meta: {
      resource: 'resource_admin',
      action: 'read',
      pageTitle: 'Vendor',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Printer Management',
        },
        {
          text: 'Vendor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/printermanagement/printerlabels',
    name: 'printerlabels',
    component: () => import('@/views/apps/printer-managements/labels/Labels.vue'),
    meta: {
      resource: 'resource_admin',
      action: 'read',
      pageTitle: 'Labels',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Printer Management',
        },
        {
          text: 'Labels',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/printermanagement/printersystemdata',
    name: 'printer-system-data',
    component: () => import('@/views/apps/printer-managements/systemdata/SystemData.vue'),
    meta: {
      resource: 'resource_admin',
      action: 'read',
      pageTitle: 'System Data',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Printer Management',
        },
        {
          text: 'System Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/printermanagement/testpage',
    name: 'printer-test-page',
    component: () => import('@/views/apps/printer-managements/printouttest/PrintOutTest.vue'),
    meta: {
      resource: 'resource_admin',
      action: 'read',
      pageTitle: 'Test Page',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Printer Management',
        },
        {
          text: 'Test Page',
          active: true,
        },
      ],
    },
  },
]
