/* eslint-disable no-unused-vars */
import Vue from 'vue'

Vue.config.errorHandler = (err, vm, info) => {
  // TODO: handle server side
}

window.onerror = (message, source, lineno, colno, error) => {
  // TODO: handle server side
}
