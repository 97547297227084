import find from 'lodash/find'
/**
 * Enum for Merchant Connect Underwriting Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const marketplaceEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'All Marketplaces' },
  AmazonCom: { key: 'Amazon.com', value: 'Amazon.com', text: 'Amazon.com' },
  AmazonCa: { key: 'Amazon.ca', value: 'Amazon.ca', text: 'Amazon.ca' },
  AmazonBr: { key: 'Amazon.com.br', value: 'Amazon.com.br', text: 'Amazon.com.br' },
  AmazonMx: { key: 'Amazon.com.mx', value: 'Amazon.com.mx', text: 'Amazon.com.mx' },
})

function getMarketplaceEnum(predicate, predicateKey = 'key') {
  const result = find(marketplaceEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getMarketplaceList() {
  const options = []
  Object
    .keys(marketplaceEnum)
    .map(key => options.push(
      {
        value: marketplaceEnum[key].value,
        text: marketplaceEnum[key].text,
      },
    ))
  return options
}

export default marketplaceEnum

export {
  getMarketplaceEnum,
  getMarketplaceList,
}
