import find from 'lodash/find'
/**
 * Enum for Amazon Reports.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const reportTypeEnum = Object.freeze({
  ASIN: { key: 'ASIN', value: 'ASIN', text: 'ASIN' },
  MSKU: { key: 'MSKU', value: 'MSKU', text: 'MSKU' },
  SUPPLIER: { key: 'SUPPLIER', value: 'SUPPLIER', text: 'Supplier' },
  CATEGORY: { key: 'CATEGORY', value: 'CATEGORY', text: 'Category' },
})

function getReportTypeEnum(predicate, predicateKey = 'key') {
  const result = find(reportTypeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getListReportTypeEnum() {
  const result = Object.keys(reportTypeEnum).map(key => reportTypeEnum[key])
  return result
}

export default reportTypeEnum

export {
  getListReportTypeEnum,
  getReportTypeEnum,
}
