const system = {
  auditController: {
    base: '/management/audits',

    getAll: () => `${system.auditController.base}`,
    getByDates: (fromDate, toDate) => `${system.auditController.base}/?${fromDate ? `fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}`,
    get: id => `${system.auditController.base}/${id}`,
  },

  accountController: {
    // Controllorın url prefixi
    base: '/api',

    registerAccount: () => `${system.accountController.base}/register`,
    activateAccount: code => `${system.accountController.base}/activate/${code}`,
    isAuthenticated: () => `${system.accountController.base}/authenticate`,
    // get return UserDTO
    getAccount: () => `${system.accountController.base}/account`,
    // post UserDTO
    saveAccount: () => `${system.accountController.base}/account`,
    // post UserDTO
    saveUserSettingsGeneral: () => `${system.accountController.base}/account/user-settings-general`,
    // post PasswordChangeDTO
    changePassword: () => `${system.accountController.base}/account/change-password`,
    // Post @RequestBody String mail
    requestPasswordReset: () => `${system.accountController.base}/account/reset-password/init`,

    // Post KeyAndPasswordVM
    finishPasswordReset: () => `${system.accountController.base}/account/reset-password/finish`,

    // Resend activation code
    resendActivationCode: email => `${system.accountController.base}/resend-activation-code/${email}`,

  },
  userJWTController: {
    base: '/api',
    // post login vm return jwt token and vueUserData
    authorize: () => `${system.userJWTController.base}/authenticate`,
  },

  userController: {
    base: '/api',

    // @PostMapping("/users")
    // @PreAuthorize("hasAuthority(\"" + AuthoritiesConstants.ADMIN + "\")")
    // ApiResponse<UserInfo> createUser(@Valid @RequestBody UserDTO userDTO)
    createUser: () => `${system.userController.base}/users`,

    // @PutMapping("/users")
    // @PreAuthorize("hasAuthority(\"" + AuthoritiesConstants.ADMIN + "\")")
    // public ApiResponse<UserDTO> updateUser(@Valid @RequestBody UserDTO userDTO)
    updateUser: () => `${system.userController.base}/users`,

    fetchQuery: name => `${system.userController.base}/users/name/${name}`,

    getCurrentUser: () => `${system.userController.base}/users/current-user`,

    // @GetMapping("/users")
    // public ApiResponse<List<UserDTO>> getAllUsers(Pageable pageable)
    getAllUsers: () => `${system.userController.base}/users`,

    getUserInfoBasicByQuery: pageable => `${system.userController.base}/users/basic-info?${pageable}`,

    getAllAccountManagers: () => `${system.userController.base}/users/account-managers`,
    getAllProblemManagers: () => `${system.userController.base}/users/problem-managers`,
    getAllManagers: warehouseId => `${system.userController.base}/users/warehouses/${warehouseId}/managers`,

    // @GetMapping("/users/authorities")
    // @PreAuthorize("hasAuthority(\"" + AuthoritiesConstants.ADMIN + "\")")
    getAuthorities: () => `${system.userController.base}/users/authorities`,

    refreshAuthData: () => `${system.userController.base}/users/refresh-auth-data`,

    // @GetMapping("/users/has-authority/{authorityName}")
    // public Boolean hasAuthority(@PathVariable  String authorityName) {
    hasAuthority: authorityName => `${system.userController.base}/users/has-authority/${authorityName}`,

    // @GetMapping("/users/{login:" + Constants.LOGIN_REGEX + "}")
    // public ResponseEntity<UserDTO> getUser(@PathVariable String login) {
    getUser: login => `${system.userController.base}/users/${login}`,

    getCompanyUser: (companyId, userId) => `${system.userController.base}/users/company/${companyId}/user/${userId}`,

    // @GetMapping("/users/profile/{id}")
    // public ApiResponse<UserDTO> getUser(@PathVariable Long userId) {
    getUserProfile: id => `${system.userController.base}/users/profile/${id}`,

    // @DeleteMapping("/users/{login:" + Constants.LOGIN_REGEX + "}")
    // @PreAuthorize("hasAuthority(\"" + AuthoritiesConstants.ADMIN + "\")")
    // public ResponseEntity<Void> deleteUser(@PathVariable String login) {
    deleteUserByUsername: login => `${system.userController.base}/users/${login}`,
    unSubscribeSettingsNotification: (settingKey, referenceId) => `${system.userController.base}/current-user/unsubscribe-settings-notification/${settingKey}/${referenceId}`,
    subscribeMainTopic: () => `${system.userController.base}/current-user-subscribed-update-main-topics`,
    currentUserSubscribedMainTopics: () => `${system.userController.base}/current-user-subscribed-main-topics`,

  },
  userProfileController: {
    base: '/api',
    createOrUpdateUserProfile: () => `${system.userProfileController.base}/create-or-update-user-profile`,
    downloadExcelTemplate: () => `${system.userProfileController.base}/user-profile/download/exceltemplate`,
    getActiveUserProfile: () => `${system.userProfileController.base}/user-profile/active-user`,
    getActiveCompany: () => `${system.userProfileController.base}/active-company`,
  },

  roleController: {
    base: '/api/user-management/role',
    getRoles: () => `${system.roleController.base}`,
    createRole: () => `${system.roleController.base}`,
    updateRole: () => `${system.roleController.base}`,
    deleteRole: roleId => `${system.roleController.base}/${roleId}`,
    getRole: roleId => `${system.roleController.base}/${roleId}`,
    getRolesByCompanyId: companyId => `${system.roleController.base}/company/${companyId}`,
    getUserSubRoles: companyId => `${system.roleController.base}/user-sub-roles/${companyId}`,
    getCompanyRolesExceptCompanyOwnerRole: accountNo => `${system.roleController.base}/company/current-company-roles-except-company-owner-role/${accountNo}`,
  },

  resourceController: {
    base: '/api/user-management/resource',
    getRosources: () => `${system.resourceController.base}`,
    createRosource: () => `${system.resourceController.base}`,
    deleteRosource: resourceId => `${system.resourceController.base}/${resourceId}`,
    getRosource: resourceId => `${system.resourceController.base}/${resourceId}`,
    getCompanyGrantableResource: roleId => `${system.resourceController.base}/company-grantable-resource?roleId=${roleId}`,
    checkRouterResourceMapping: () => `${system.resourceController.base}/check-router-resource-mapping`,
  },
  resourceActionController: {
    base: '/api/user-management/resource',
    getRoleResourceActionByRoleId: roleId => `${system.roleController.base}/resources/${roleId}`,
  },
  roleResourceActionController: {
    base: '/api/user-management/role-resource-action',
    getRoleResourceActionById: id => `${system.roleResourceActionController.base}/${id}`,
    getTemplateRoleResourceActionByCompanyTypeAndResourceId: (companyType, resourceId) => `${system.roleResourceActionController.base}/company-type/${companyType}/resource/${resourceId}`,
    grantResourceByCompanyType: (resourceId, companyType) => `${system.roleResourceActionController.base}/${resourceId}/company-type/${companyType}`,
    getRoleResourceActionByRoleId: roleId => `${system.roleResourceActionController.base}/role/${roleId}`,
    getAvailableResources: newRoleId => `${system.roleResourceActionController.base}/available/resources/role/${newRoleId}`,
    createRoleResourceAction: () => `${system.roleResourceActionController.base}/assign-permissions`,
    deleteRoleResourceAction: id => `${system.roleResourceActionController.base}/${id}`,
    updateRoleResourceAction: () => `${system.roleResourceActionController.base}`,
    companyGetRoleResourceAction: (accountNo, roleId, resourceId) => `${system.roleResourceActionController.base}/company-get-role-resource-action/${accountNo}/${roleId}/${resourceId}`,
    companyUpdateRoleResourceAction: accountNo => `${system.roleResourceActionController.base}/company-update-role-resource-action/${accountNo}`,
    companyRemoveRoleResourceAction: (accountNo, roleId, resourceId) => `${system.roleResourceActionController.base}/company-remove-role-resource-action/${accountNo}/${roleId}/${resourceId}`,
  },

  tenantController: {
    base: '/api/organization/tenant',
    servingTenantMerchantRegisterLink: () => `${system.tenantController.base}/tenant/serving/merchant/register/link`,
    findAll: () => `${system.tenantController.base}`,
    findByTenantId: tenantId => `${system.tenantController.base}/${tenantId}`,
    findById: id => `${system.tenantController.base}/${id}`,
    findByUserName: userName => `${system.tenantController.base}/users/${userName}`,
    getUserTenants: userId => `${system.tenantController.base}/user-tenant-list/${userId}`,
    getTenantInfo: key => `${system.tenantController.base}/tenant-info/${key}`,
    create: () => `${system.tenantController.base}`,
    updateUserTenant: (userId, tenantId) => `${system.tenantController.base}/change-user-active-tenant/${userId}/${tenantId}`,
    delete: tenantId => `${system.tenantController.base}/${tenantId}`,
  },

  selfTenantController: {
    base: '/api/self/tenant',

    register: () => `${system.selfTenantController.base}`,
  },

  emailController: {
    base: '/api/system/email',
    findAllTemplate: () => `${system.emailController.base}/templates`,
    findAllTemplateTopic: () => `${system.emailController.base}/topics`,
    findTypes: () => `${system.emailController.base}/types`,
    findHtmlContentByEmailVariable: () => `${system.emailController.base}/html-content`,
    sendEmailByEmailVariableTemplate: () => `${system.emailController.base}/send-email`,
    sendInviteMerchantRegistrationLink: () => `${system.emailController.base}/send/invite/merchant/registration/link`,
  },

  departmentController: {
    base: '/api/company/department',
    createDefaultDepartments: tenantId => `${system.departmentController.base}/create/default/departments/${tenantId}`,
    getTenantDepartments: () => `${system.departmentController.base}/departments`,
    createDepartment: () => `${system.departmentController.base}/create`,
    updateDepartment: () => `${system.departmentController.base}/update`,
    deleteDepartment: departmentId => `${system.departmentController.base}/delete/${departmentId}`,
    associateDepartmentWarehouseUser: (departmentId, warehouseUserId, associate) => `${system.departmentController.base}/associate/department/${departmentId}/warehouse/user/${warehouseUserId}/${associate}`,
    associateCustomerCompanyWarehouseUser: (departmentId, customerCompanyId, warehouseUserId, associate) => `${system.departmentController.base}/associate/department/${departmentId}/customer/company/${customerCompanyId}/warehouse/user/${warehouseUserId}/${associate}`,
    getDepartmentUsers: departmentId => `${system.departmentController.base}/${departmentId}/users`,
    getDepartmentsByUserId: userId => `${system.departmentController.base}/user/${userId}/departments`,
    getDepartmentUsersAssociateCustomerCompany: (departmentId, customerCompanyId) => `${system.departmentController.base}/${departmentId}/users/associate/customer/company/${customerCompanyId}`,
  },
  sseController: {
    base: '/api/sse',
    triggerNewWebRelease: () => `${system.sseController.base}/web/trigger-release`,
  },
}
export default {
  system,
}
