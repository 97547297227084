const shipmentOrderLabel = {
  Controller: {
    base: '/api/external/ship/order/label',
    // Bu ucun kullanımı eski FBM'de mi kontrol edilecek.
    getShippingLabels: (pageable, fbmOrderId) => `${shipmentOrderLabel.Controller.base}/${fbmOrderId}?${pageable}`,

    deleteShippingLabel: shippingLabelId => `${shipmentOrderLabel.Controller.base}/${shippingLabelId}`,
    getFbmForceShippingLabelList: fbmOrderId => `${shipmentOrderLabel.Controller.base}/fbm/${fbmOrderId}`,

    deleteShippingLabelBox: shippingLabelId => `${shipmentOrderLabel.Controller.base}/${shippingLabelId}/box/item`,
    createShippingLabelBox: shippingLabelId => `${shipmentOrderLabel.Controller.base}/${shippingLabelId}/box/item`,
    getRemainingBoxItems: fbmOrderId => `${shipmentOrderLabel.Controller.base}/fbm/${fbmOrderId}/box/remaining/item`,

    customer: '/api/external/ship/customer/label',
    // Customer ve BOL shipping labellarının eklenmesi
    createShippingLabel: () => `${shipmentOrderLabel.Controller.customer}`,
    updateShippingLabel: () => `${shipmentOrderLabel.Controller.customer}`,
    getCarriers: () => `${shipmentOrderLabel.Controller.customer}/carriers`,

    file: '/api/file/public/resource',
    uploadShippingLabel: () => `${shipmentOrderLabel.Controller.file}/shipping/label/upload`,
  },
}

export default {
  shipmentOrderLabel,
}
