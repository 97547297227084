const product = {

  Controller: {
    base: 'api/product/products',

    getAll: () => `${product.Controller.base}`,
  },
}
export default {
  product,
}
