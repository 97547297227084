/**
 * Enum for reference type.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const referenceTypeEnum = Object.freeze({
  COMPANY: { key: 'COMPANY', value: 'COMPANY' },
  USER: { key: 'USER', value: 'USER' },
  STORE: { key: 'STORE', value: 'STORE' },
  WAREHOUSE: { key: 'WAREHOUSE', value: 'WAREHOUSE' },
  FRANCHISE: { key: 'FRANCHISE', value: 'FRANCHISE' },
})

export default referenceTypeEnum
