const tenant = {
  controller: {
    /*
    * Tenant Settings
    */
    settings: '/api/tenant-settings',
    checkApiKey: apiKey => `${tenant.controller.settings}/check-api-key/${apiKey}`,
    checkTenant: tenantId => `${tenant.controller.settings}/check-tenant/${tenantId}`,
    currentTenantSettings: () => `${tenant.controller.settings}/current-tenant-settings`,
    findAll: params => `${tenant.controller.settings}${params}`,
    findById: id => `${tenant.controller.settings}/${id}`,
    findByKeyAndCurrentTenantId: key => `${tenant.controller.settings}/list/${key}`,
    findByKey: key => `${tenant.controller.settings}/last/${key}`,
    findByKeyAndReference: (key, reference) => `${tenant.controller.settings}/find/${key}/reference/${reference}`,
    create: () => `${tenant.controller.settings}`,
    update: () => `${tenant.controller.settings}`,
    delete: id => `${tenant.controller.settings}/${id}`,

    file: '/api/file/public/resource',
    uploadLogo: () => `${tenant.controller.file}/upload`,
  },
}
export default {
  tenant,
}
