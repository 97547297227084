/* eslint-disable no-unused-vars */
import indexOf from 'lodash/indexOf'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
// Modules
import app from './app'
import appConfig from './app-config'
import appLocal from './app-local'
import verticalMenu from './vertical-menu'
import receiveBatch from './receive-batch'
import fbaShipment from './fba-shipment'
import batchBoxes from './fba-shipment/batch-boxes'
import systemNotification from './system-notification'
import signalr from './signalr'
import wfsForce from './wfs-force'

Vue.use(Vuex)

const env = process.env.VUE_APP_APPLICATION_MODE
const securityOptions = {
  encodingType: '',
  encryptionSecret: undefined,
  isCompression: false,
}
if (env === 'production' || env === 'staging') {
  Object.assign(securityOptions, {
    encodingType: 'aes',
    isCompression: false,
    encryptionSecret: '9Cp2oDLEYS9PnRC00QQMmq6XGGART8BB',
  })
}

const secureState = new SecureLS(securityOptions)

const dataState = createPersistedState({
  key: 'persisted_state',
  paths: [
    'fbaShipment.batchAccountInfo',
    'fbaShipment.selectedBactInfo',
    'fbaShipment.printerSettings',
    'wfsForce.accountInfo',
    'wfsForce.printerSettings',
    'receiveBatch.selectedAccountInfo',
    'systemNotification.notificationReadInfo',
    'appLocal.uploadedShipmentItemsParams',
  ],
  storage: {
    getItem: key => secureState.get(key),
    setItem: (key, value) => secureState.set(key, value),
    removeItem: key => secureState.remove(key),
  },
  reducer: (state, paths) => {
    const fbaForcePath = indexOf(paths, 'fbaShipment.batchAccountInfo')
    if (fbaForcePath !== -1) {
      if (state.fbaShipment.initial) {
        state.fbaShipment.selectedBactInfo = {}
        state.fbaShipment.initial = false
      }
      return state
    }
    const wfsForcePath = indexOf(paths, 'wfsForce.accountInfo')
    if (wfsForcePath !== -1) {
      if (state.wfsForce.initial) {
        state.wfsForce.accountInfo = {}
        state.wfsForce.initial = false
      }
      return state
    }

    return state
  },
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    appLocal,
    verticalMenu,
    receiveBatch,
    fbaShipment,
    batchBoxes,
    systemNotification,
    signalr,
    wfsForce,
  },
  plugins: [dataState],
  strict: process.env.DEV,
})
