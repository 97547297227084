export default [
  {
    path: '/apps/settings/printer',
    name: 'printer-settings',
    component: () => import('@/views/apps/settings/PrinterSettings.vue'),
    meta: {
      resource: 'resource_printer_settings',
      action: 'read',
      pageTitle: 'Printer Settings',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Settings',
        },
        {
          text: 'Printer Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/settings/general',
    name: 'general-settings',
    component: () => import('@/views/apps/settings/GeneralSettings.vue'),
    meta: {
      resource: 'resource_management_finance_tools',
      action: 'read',
      pageTitle: 'Fee Settings',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Settings',
        },
        {
          text: 'General Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/settings/fee',
    name: 'fee-settings',
    component: () => import('@/views/apps/settings/FeeSettings.vue'),
    meta: {
      resource: 'resource_management_global_settings',
      action: 'read',
      pageTitle: 'Fee Settings',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Settings',
        },
        {
          text: 'Fee Settings',
          active: true,
        },
      ],
    },
  },
]
