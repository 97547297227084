export default {
  none: {
    value: 'none',
    key: 'none',
    text: 'none',
    disabled: false,
  },
  warehouseLocation: {
    value: 'warehouse-location',
    key: 'warehouse-location',
    text: 'warehouse-location',
    disabled: false,
  },
  files: {
    value: 'files',
    key: 'files',
    text: 'files',
    disabled: false,
  },
  outboundShipment: {
    value: 'outbound-shipment',
    key: 'outbound-shipment',
    text: 'outbound-shipment',
    disabled: false,
  },
}
