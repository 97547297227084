export default {
  none: {
    value: 'none',
    key: 'none',
    text: 'Problem Type',
    disabled: false,
  },
  damaged: {
    value: 'damaged',
    key: 'damaged',
    text: 'Damaged',
    disabled: false,
  },
  mispickMisship: {
    value: 'mispick_misship',
    key: 'mispick_misship',
    text: 'Mispick-Misship',
    disabled: false,
  },
  alarmTag: {
    value: 'alarm_tag',
    key: 'alarm_tag',
    text: 'Alarm Tag',
    disabled: false,
  },
  hazmat: {
    value: 'hazmat',
    key: 'hazmat',
    text: 'Hazmat',
    disabled: false,
  },
  dirtyOrUsed: {
    value: 'dirty_or_used',
    key: 'dirty_or_used',
    text: 'Dirty or Used',
    disabled: false,
  },
  expirationDateIssue: {
    value: 'expiration_date_issue',
    key: 'expiration_date_issue',
    text: 'Expiration Date Issue',
    disabled: false,
  },
  customerChangedMind: {
    value: 'customer_changed_mind',
    key: 'customer_changed_mind',
    text: 'Customer Changed Mind',
    disabled: false,
  },
  missingItem: {
    value: 'missing_item',
    key: 'missing_item',
    text: 'Missing Item',
    disabled: false,
  },
  not_delivered: {
    value: 'not_delivered',
    key: 'not_delivered',
    text: 'Not Delivered',
  },
  listing_asin_issue: {
    value: 'listing_asin_issue',
    key: 'listing_asin_issue',
    text: 'Listing ASIN Issue',
    disabled: false,
  },
  asin_input_error: {
    value: 'asin_input_error',
    key: 'asin_input_error',
    text: 'ASIN Input Error',
    disabled: false,
  },
  item_mismatch: {
    value: 'item_mismatch',
    key: 'item_mismatch',
    text: 'Item Mismatch',
    disabled: false,
  },
  missing_item_data_entry: {
    value: 'missing_item_data_entry',
    key: 'missing_item_data_entry',
    text: 'Missing Item Data Entry',
    disabled: false,
  },
}
