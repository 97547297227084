export default {
  // region: {
  //   value: 0,
  //   key: 'REGION',
  //   text: 'REGION',
  //   icon: 'BoxIcon',
  //   color: '#ffa500',
  //   disabled: true,
  // },
  bin: {
    value: 0,
    key: 'B',
    text: 'BIN',
    icon: 'BoxIcon',
    color: '#ff1493',
    disabled: false,
  },
  wire: {
    value: 1,
    key: 'W',
    text: 'WIRE',
    icon: 'BoxIcon',
    color: '#1e90ff',
    disabled: false,
  },
  shelf: {
    value: 2,
    key: 'S',
    text: 'SHELF',
    icon: 'BoxIcon',
    color: '#ffceff',
    disabled: false,
  },
  rack: {
    value: 3,
    key: 'R',
    text: 'RACK',
    icon: 'BoxIcon',
    color: '#ffff00',
    disabled: false,
  },
  level: {
    value: 4,
    key: 'L',
    text: 'LEVEL',
    icon: 'BoxIcon',
    color: '#008000',
    disabled: false,
  },
  black: {
    value: 5,
    key: 'BLK',
    text: 'BLACK',
    icon: 'BoxIcon',
    color: '#008000',
    disabled: false,
  },
  gray: {
    value: 6,
    key: 'GRY',
    text: 'GRAY',
    icon: 'BoxIcon',
    color: '#008000',
    disabled: false,
  },
  blue: {
    value: 7,
    key: 'BLU',
    text: 'BLUE',
    icon: 'BoxIcon',
    color: '#008000',
    disabled: false,
  },
  red: {
    value: 8,
    key: 'RED',
    text: 'RED',
    icon: 'BoxIcon',
    color: '#008000',
    disabled: false,
  },
  dock: {
    value: 9,
    key: 'D',
    text: 'DOCK',
    icon: 'BoxIcon',
    color: '#0084ff',
    disabled: false,
  },
  desk: {
    value: 10,
    key: 'DES',
    text: 'DESK',
    icon: 'BoxIcon',
    color: '#e54f00',
    disabled: false,
  },
  aisle: {
    value: 11,
    key: 'A',
    text: 'AISLE',
    icon: 'BoxIcon',
    color: '#5f9ea0',
    disabled: false,
  },
  zone: {
    value: 12,
    key: 'Z',
    text: 'ZONE',
    icon: 'BoxIcon',
    color: '#ff0000',
    disabled: false,
  },
  warehouse: {
    value: 13,
    key: 'W',
    text: 'WAREHOUSE',
    icon: 'BoxIcon',
    color: '#800080',
    disabled: false,
  },
  flatDolly: {
    value: 14,
    key: 'F',
    text: 'FLATDOLLY',
    icon: 'BoxIcon',
    color: '#800080',
    disabled: false,
  },
  position: {
    value: 15,
    key: 'P',
    text: 'POSITION',
    icon: 'BoxIcon',
    color: '#800080',
    disabled: false,
  },
  pallet: {
    value: 16,
    key: 'PL',
    text: 'PALLET',
    icon: 'BoxIcon',
    color: '#3d3d3d',
    disabled: false,
  },
  amazonBag: {
    value: 17,
    key: 'AB',
    text: 'AMZ_BAG',
    icon: 'BoxIcon',
    color: '#3d3d3d',
    disabled: false,
  },
  box: {
    value: 18,
    key: 'BO',
    text: 'BOX',
    icon: 'BoxIcon',
    color: '#3d3d3d',
    disabled: false,
  },
}
