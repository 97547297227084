const ebay = {
  controller: {
    /*
    * Ebay
    */
    ebay: '/api/external/ebay',
    generateUserAuthorizationUrl: () => `${ebay.controller.ebay}/generate-user-authorization-url`,
    exchangeCodeForAccessToken: () => `${ebay.controller.ebay}/exchange-code-for-access-token`,
    connectStore: () => `${ebay.controller.ebay}/connect-ebay-store`,
  },
}
export default {
  ebay,
}
