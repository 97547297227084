const batchProcess = {

  Controller: {
    base: '/api/jobs/batch-process',

    // warehouseLocation Endpoints
    findByType: type => `${batchProcess.Controller.base}/type/${type}`,
    findByTypeAndStatus: (type, status) => `${batchProcess.Controller.base}/type/${type}/status/${status}`,
    create: () => `${batchProcess.Controller.base}`,
  },
}
export default {
  batchProcess,
}
