<template>
  <div v-if="page.totalElements > 0" class="row mx-1 mt-2">
    <div class="col-sm text-sm-left text-center mb-1 mb-sm-0">
      <span class="text-muted small align-middle">Page <b>{{ pageable.pageNumber }}</b> of <b>{{ totalPages }}</b>, total
        <b>{{ page.totalElements }}</b> entries</span>
    </div>
    <div class="col-sm">
      <b-pagination
        v-if="page.totalElements"
        v-model="thisCurrentPage"
        class="justify-content-center justify-content-sm-end float-right"
        :total-rows="page.totalElements"
        :per-page="thisPerPage"
      />

      <b-select
        v-model="thisPerPage"
        size="sm"
        :options="perPageOptions"
        class="mr-1 mb-2 d-inline-block w-auto float-right"
      />
    </div>
  </div>
</template>

<script>
import Pageable from './pageable'

export default {
  props: {
    options: {
      type: Pageable,
      required: true,
      default: new Pageable({}),
    },
    changed: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data: () => ({
    perPageOptions: [5, 10, 20, 30, 40, 50, 100, 200],
  }),
  computed: {
    pageable: {
      get() {
        return this.options.toCreatePageblePayload()
      },
    },
    page: {
      get() {
        return this.options.toCreatePayload()
      },
    },
    totalPages: {
      get() {
        return Math.ceil(this.page.totalElements / this.thisPerPage)
      },
    },
    thisPerPage: {
      get() {
        return this.pageable.pageSize
      },
      set(val) {
        this.options.setNumberOfElements(val)
      },
    },
    thisCurrentPage: {
      get() {
        return this.pageable.pageNumber
      },
      set(val) {
        this.options.setPageNumber(val)
      },
    },
  },
  watch: {
    thisPerPage() {
      this.changed()
    },
    // eslint-disable-next-line no-unused-vars
    thisCurrentPage(newPage, oldPage) {
      this.changed()
    },
  },
  created() {},
  mounted() {},
}
</script>
