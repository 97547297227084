const warehouseLocation = {

  Controller: {
    base: '/api/warehouse-location',

    // warehouseLocation Endpoints
    findAll: pageable => `${warehouseLocation.Controller.base}?${pageable}`,
    findById: id => `${warehouseLocation.Controller.base}/${id}`,
    findByLocation: location => `${warehouseLocation.Controller.base}/location/${location}`,
    findByParent: parent => `${warehouseLocation.Controller.base}/parent/${parent}`,
    findByParentLevel: parent => `${warehouseLocation.Controller.base}/parent-level/${parent}`,
    findByParentType: type => `${warehouseLocation.Controller.base}/type/${type}`,
    findAllSubLocationsById: id => `${warehouseLocation.Controller.base}/tree/${id}`,
    findRootPath: id => `${warehouseLocation.Controller.base}/location/${id}/root-path`,
    getFirstLevel: id => `${warehouseLocation.Controller.base}/warehouse/${id}/first-level`,
    setParentLocation: () => `${warehouseLocation.Controller.base}/set-parent-location`,
    create: () => `${warehouseLocation.Controller.base}`,
    createBulk: () => `${warehouseLocation.Controller.base}/create-bulk`,
    update: () => `${warehouseLocation.Controller.base}/update`,
    deleteMany: () => `${warehouseLocation.Controller.base}/delete-many`,
    delete: (id, accepted) => `${warehouseLocation.Controller.base}/${id}/force/${accepted}`,
    getDeepestLevel: () => `${warehouseLocation.Controller.base}/tree`,
    updateLocationName: (id, location) => `${warehouseLocation.Controller.base}/update/location/${id}/${location}`,
  },
  MoveBatchController: {
    base: '/api/inventory/move/batch',
    getList: pageable => `${warehouseLocation.MoveBatchController.base}/query?${pageable}`,
    moveAllItemsByBatchId: (moveBatchId, location) => `${warehouseLocation.MoveBatchController.base}/${moveBatchId}/location/${location}`,
    moveSelectedItems: () => `${warehouseLocation.MoveBatchController.base}/items`,
    create: () => `${warehouseLocation.MoveBatchController.base}`,
    delete: id => `${warehouseLocation.MoveBatchController.base}/${id}`,
  },
  MoveBatchItemController: {
    base: '/api/inventory/move/item',
    getByQuery: pageable => `${warehouseLocation.MoveBatchItemController.base}/query?${pageable}`,
    create: () => `${warehouseLocation.MoveBatchItemController.base}`,
    updateQuantity: (id, quantity) => `${warehouseLocation.MoveBatchItemController.base}/update/${id}/quantity/${quantity}`,
    delete: id => `${warehouseLocation.MoveBatchItemController.base}/${id}`,
  },
  LpnController: {
    base: '/api/warehouse/location/lpn',
    getList: () => `${warehouseLocation.LpnController.base}/query`,
    getByLpn: lpn => `${warehouseLocation.LpnController.base}/lpn/${lpn}`,
    getByLocation: location => `${warehouseLocation.LpnController.base}/location/${location}`,
    setLocation: (id, location) => `${warehouseLocation.LpnController.base}/${id}/location/${location}`,
    create: () => `${warehouseLocation.LpnController.base}`,
    delete: id => `${warehouseLocation.LpnController.base}/${id}`,
  },
}
export default {
  warehouseLocation,
}
