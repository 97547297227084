import DxDataGrid, {
  DxAnimation,
  DxAsyncRule,
  DxAt,
  DxBoundaryOffset,
  DxColCountByScreen,
  DxCollision,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxColumnFixingTexts,
  DxColumnHeaderFilter,
  DxColumnLookup,
  DxCursorOffset,
  DxCustomOperation,
  DxDataGridHeaderFilter,
  DxDataGridHeaderFilterTexts,
  DxEditing,
  DxEditingTexts,
  DxExport,
  DxExportTexts,
  DxFilterBuilder,
  DxFilterBuilderPopup,
  DxFilterOperationDescriptions,
  DxFilterPanel,
  DxFilterPanelTexts,
  DxFilterRow,
  DxGroupingTexts,
  DxHeaderFilter,
  DxLoadPanel,
  DxLookup,
  DxMasterDetail,
  DxPager,
  DxPaging,
  DxRowDragging,
  DxScrolling,
  DxSearchPanel,
  DxSelection,
  DxSortByGroupSummaryInfo,
  DxSorting,
  DxStateStoring,
  DxSummary,
  DxSummaryTexts,
  DxTexts,
  DxToolbarItem,
  DxTotalItem,
  DxButton,
  DxPopup,
  DxForm,
  DxRemoteOperations,
  DxToolbar,
  DxItem,
} from 'devextreme-vue/data-grid'

export default {
  components: {
    DxDataGrid,
    DxAnimation,
    DxAsyncRule,
    DxAt,
    DxBoundaryOffset,
    DxColCountByScreen,
    DxCollision,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxColumnFixingTexts,
    DxColumnHeaderFilter,
    DxColumnLookup,
    DxCursorOffset,
    DxCustomOperation,
    DxDataGridHeaderFilter,
    DxDataGridHeaderFilterTexts,
    DxEditing,
    DxEditingTexts,
    DxExport,
    DxExportTexts,
    DxFilterBuilder,
    DxFilterBuilderPopup,
    DxFilterOperationDescriptions,
    DxFilterPanel,
    DxFilterPanelTexts,
    DxFilterRow,
    DxGroupingTexts,
    DxHeaderFilter,
    DxLoadPanel,
    DxLookup,
    DxMasterDetail,
    DxPager,
    DxPaging,
    DxRowDragging,
    DxScrolling,
    DxSearchPanel,
    DxSelection,
    DxSortByGroupSummaryInfo,
    DxSorting,
    DxStateStoring,
    DxSummary,
    DxSummaryTexts,
    DxTexts,
    DxToolbarItem,
    DxTotalItem,
    DxButton,
    DxPopup,
    DxForm,
    DxRemoteOperations,
    DxToolbar,
    DxItem,
  },
}
