export default [
  /**
  * User
  */
  {
    path: '/apps/reports/restock-recommendations',
    name: 'restock-recommendations',
    component: () => import('@/views/apps/reports/restock/RestockRecommendations.vue'),
    meta: {
      resource: 'resource_reports',
      action: 'read',
      pageTitle: 'Restock Recommendations',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Reports',
        },
        {
          text: 'Restock Recommendations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/reports/order-metrics',
    name: 'order-metrics',
    component: () => import('@/views/apps/reports/orders/OrderMetrics.vue'),
    meta: {
      resource: 'resource_reports',
      action: 'read',
      pageTitle: 'Order Metrics',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Reports',
        },
        {
          text: 'Order Metrics',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/reports/amazon-ranks',
    name: 'amazon-ranks',
    component: () => import('@/views/apps/reports/amazon-ranks/AmazonRanks.vue'),
    meta: {
      resource: 'resource_reports',
      action: 'read',
      pageTitle: 'Amazon Ranks',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Reports',
        },
        {
          text: 'Amazon Ranks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/reports/amazon/sales/reports/monthly',
    name: 'amazon-monthly-sales',
    component: () => import('@/views/apps/reports/amazon-sales/AmazonMonthlySales.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'Amazon Monthly Sales Metrics',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Reports',
        },
        {
          text: 'Amazon Monthly Sales Metrics',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/reports/amazon/inventory/historical-values',
    name: 'amazon-historical-inventory-values',
    component: () => import('@/views/apps/reports/historical-inventory-value/HistoricalInventoryValue.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'Historical Inventory Values',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Reports',
        },
        {
          text: 'Historical Inventory Values',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/reports/amazon/sales/monthly-snapshots',
    name: 'amazon-monthly-sales-snapshots',
    component: () => import('@/views/apps/reports/sales/MonthlySalesSnapshots.vue'),
    meta: {
      resource: 'resource_reports',
      action: 'read',
      pageTitle: 'Monthly Sales Snapshots',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Reports',
        },
        {
          text: 'Monthly Sales Snapshots',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/apps/reports/amazon/items/cost-stats',
  //   name: 'amazon-item-cost-stats',
  //   component: () => import('@/views/apps/reports/costs/ItemCostStats.vue'),
  //   meta: {
  //     resource: 'resource_private_report',
  //     action: 'read',
  //     pageTitle: 'Item Cost Statistics',
  //     breadcrumb: [
  //       {
  //         text: 'Home',
  //         to: '/dashboard',
  //       },
  //       {
  //         text: 'Reports',
  //       },
  //       {
  //         text: 'Item Cost Stats',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/apps/amazon/reports/sales',
    name: 'amazon-sales-report',
    component: () => import('@/views/apps/reports/amazon-reports/Sales.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'Amazon Sales Report',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'Sales Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/amazon/reports/returns',
    name: 'amazon-returns-report',
    component: () => import('@/views/apps/reports/amazon-reports/Returns.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'Amazon Returns Report',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'Returns Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/amazon/reports/reimbursements',
    name: 'amazon-reimbursements-report',
    component: () => import('@/views/apps/reports/amazon-reports/Reimbursements.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'Amazon Reimbursements Report',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'Reimbursements Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/amazon/reports/:reportType',
    name: 'amazon-asin-report',
    component: () => import('@/views/apps/reports/amazon-reports/AmazonReports.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'ASIN Report',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'ASIN Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/amazon/reports/:reportType',
    name: 'amazon-msku-report',
    component: () => import('@/views/apps/reports/amazon-reports/AmazonReports.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'MSKU Report',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'MSKU Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/amazon/reports/:reportType',
    name: 'amazon-supplier-report',
    component: () => import('@/views/apps/reports/amazon-reports/AmazonReports.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'Supplier Report',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'Supplier Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/amazon/reports/:reportType',
    name: 'amazon-category-report',
    component: () => import('@/views/apps/reports/amazon-reports/AmazonReports.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'Category Report',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'Category Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/amazon/expenses',
    name: 'amazon-expenses',
    component: () => import('@/views/apps/reports/incomes-expenses/Expenses.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'Expenses',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'Other Expenses',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/amazon/incomes',
    name: 'amazon-incomes',
    component: () => import('@/views/apps/reports/incomes-expenses/Incomes.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'Incomes',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'Other Incomes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/amazon/reporting-dashboard',
    name: 'amazon-reporting-dashboard',
    component: () => import('@/views/apps/reports/amazon-reports/ReportsDashboard.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'Reporting Dashboard',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'Reporting Dashboard',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/apps/amazon/removals',
  //   name: 'amazon-removals-report',
  //   component: () => import('@/views/apps/reports/amazon-reports/Removals.vue'),
  //   meta: {
  //     resource: 'resource_admin',
  //     action: 'read',
  //     pageTitle: 'Removals',
  //     breadcrumb: [
  //       {
  //         text: 'Home',
  //         to: '/dashboard',
  //       },
  //       {
  //         text: 'Amazon Reports',
  //       },
  //       {
  //         text: 'Removals',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/apps/amazon/sales/upload-data',
    name: 'amazon-sales-upload',
    component: () => import('@/views/apps/reports/amazon-reports/sales-file-upload/SalesFileUpload.vue'),
    meta: {
      resource: 'resource_customer_reports',
      action: 'read',
      pageTitle: 'Product Cost Import',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'Amazon Sales',
          to: '/apps/amazon/reports/sales',
        },
        {
          text: 'Product Cost Import',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/amazon/profit-and-loss',
    name: 'amazon-profit-and-loss',
    component: () => import('@/views/apps/reports/amazon-reports/IncomeStatement.vue'),
    meta: {
      resource: 'resource_admin',
      action: 'read',
      pageTitle: 'Amazon Profit & Loss',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Amazon Reports',
        },
        {
          text: 'Amazon Sales',
          to: '/apps/amazon/reports/sales',
        },
        {
          text: 'Amazon Profit & Loss',
          active: true,
        },
      ],
    },
  },
]
