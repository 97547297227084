export default [
  {
    path: '/apps/memberships/management/',
    name: 'membership-management',
    component: () => import('@/views/apps/membership/MembershipManagement.vue'),
    meta: {
      resource: 'resource_admin',
      action: 'read',
      pageTitle: 'Membership Management',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Membership Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/memberships/subscribers',
    name: 'membershipsubscribers',
    component: () => import('@/views/apps/membership/membership-subscribers/MembershipSubscribers.vue'),
    props: route => ({
      qPlanId: parseInt(route.query.qPlanId, 10) || null,
    }),
    meta: {
      resource: 'resource_clients_my_company',
      action: 'read',
      pageTitle: 'Membership Subscribers',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Membership Subscribers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/memberships/charges',
    name: 'membershipcharges',
    component: () => import('@/views/apps/membership/membership-charges/MembershipCharges.vue'),
    meta: {
      resource: 'resource_clients_my_company',
      action: 'read',
      pageTitle: 'Membership Charges',
      breadcrumb: [
        {
          text: 'Home',
          to: '/dashboard',
        },
        {
          text: 'Membership Charges',
          active: true,
        },
      ],
    },
  },
]
