export default {
  basic: {
    value: 'basic',
    key: 'basic',
    text: 'Basic',
    disabled: false,
  },
  standard: {
    value: 'standard',
    key: 'standard',
    text: 'Standard',
    disabled: false,
  },
  professional: {
    value: 'professional',
    key: 'professional',
    text: 'Professional',
    disabled: false,
  },
  enterprise: {
    value: 'enterprise',
    key: 'enterprise',
    text: 'Enterprise',
    disabled: false,
  },
}
