export default {
  individual: {
    value: 'individual',
    key: 'individual',
    text: 'Individual Products',
    disabled: false,
  },
  case_packed: {
    value: 'case_packed',
    key: 'case_packed',
    text: 'Case Packed',
    disabled: false,
  },
}
