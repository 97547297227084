import find from 'lodash/find'
/**
 * Enum for Chat User Types of Tenants.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const chatUserTypesEnum = Object.freeze({
  PROBLEM_MANAGER: { key: 'PROBLEM_MANAGER', value: 'ProblemManager', text: 'Problem Manager' },
  ACCOUNT_MANAGER: { key: 'ACCOUNT_MANAGER', value: 'AccountManager', text: 'Account Manager' },
  SUPERVISOR: { key: 'SUPERVISOR', value: 'Supervisor', text: 'Supervisor' },
})

function getChatUserTypeEnum(predicate, predicateKey = 'key') {
  const result = find(chatUserTypesEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getChatUserTypeList() {
  const options = []
  Object
    .keys(chatUserTypesEnum)
    .map(key => options.push(
      {
        value: chatUserTypesEnum[key].value,
        text: chatUserTypesEnum[key].text,
      },
    ))
  return options
}

export default chatUserTypesEnum

export {
  getChatUserTypeEnum,
  getChatUserTypeList,
}
