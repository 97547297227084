const customerCharges = {

  Controller: {
    base: '/api/finance/customer/charges',

    // customerCharges Endpoints
    findById: id => `${customerCharges.Controller.base}/id/${id}`,
    findAllByCompanyId: companyId => `${customerCharges.Controller.base}/company/${companyId}`,
    findAllByStoreId: storeId => `${customerCharges.Controller.base}/store/${storeId}`,
    findAllByInvoiceId: invoiceId => `${customerCharges.Controller.base}/invoice/${invoiceId}`,
    findAllByProblemId: problemId => `${customerCharges.Controller.base}/problem/${problemId}`,
    // params format: {companyId: 'id', stores: [id1,id2,..], warehouseId: 'id', chargeType: [1,2,...], beginDate: 'date', endDate: 'date'}
    findAllByQuery: pageable => `${customerCharges.Controller.base}/query?${pageable}`,
    create: () => `${customerCharges.Controller.base}`,
    update: () => `${customerCharges.Controller.base}`,
    delete: id => `${customerCharges.Controller.base}/${id}`,
  },
}
export default {
  customerCharges,
}
