import Vue from 'vue'
import { POSITION, TYPE } from 'vue-toastification'

export default {
  success(text, title = 'SUCCESS', position = POSITION.TOP_CENTER) {
    const message = title ? `${title.toUpperCase()}: ${text}` : text
    Vue.$toast(message, {
      type: TYPE.SUCCESS,
      position: position,
    })
  },
  warning(text, title = 'WARNING', position = POSITION.TOP_CENTER) {
    const message = title ? `${title.toUpperCase()}: ${text}` : text
    Vue.$toast(message, {
      type: TYPE.WARNING,
      position: position,
    })
  },
  danger(text, title = 'ERROR', position = POSITION.TOP_CENTER) {
    const message = title ? `${title.toUpperCase()}: ${text}` : text
    Vue.$toast(message, {
      type: TYPE.ERROR,
      position: position,
    })
  },
  info(text, title = 'INFO', position = POSITION.TOP_CENTER) {
    const message = title ? `${title.toUpperCase()}: ${text}` : text
    Vue.$toast(message, {
      type: TYPE.INFO,
      position: position,
    })
  },
  error(text, title = 'ERROR', position = POSITION.TOP_CENTER) {
    const message = title ? `${title.toUpperCase()}: ${text}` : text
    Vue.$toast(message, {
      type: TYPE.ERROR,
      position: position,
    })
  },
  message(text, title, position = POSITION.TOP_CENTER) {
    const message = title ? `${title.toUpperCase()}: ${text}` : text
    Vue.$toast(message, {
      type: TYPE.DEFAULT,
      position: position,
    })
  },
}
