import Vue from 'vue'
import VueRouter from 'vue-router'
// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  isSessionValid,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import dashboard from './routes/dashboard'
import inbound from './routes/inbound'
import outbound from './routes/outbound'
import inventory from './routes/inventory'
import pages from './routes/pages'
import management from './routes/management'
import account from './routes/account'
import tools from './routes/tools'
import printerManagement from './routes/printer-management'
import membership from './routes/membership'
import admin from './routes/admin'
import settings from './routes/settings'
import reports from './routes/reports'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-home' } },
    {
      path: '/apps/chat',
      name: 'apps-chat',
      component: () => import('@/views/apps/chat/Chat.vue'),
      meta: {
        resource: 'resources_common',
        action: 'read',
      },
    },
    ...dashboard,
    ...pages,
    ...inbound,
    ...outbound,
    ...inventory,
    ...management,
    ...account,
    ...reports,
    ...tools,
    ...printerManagement,
    ...membership,
    ...admin,
    ...settings,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const isValid = isSessionValid()
  if (isValid) {
    return next({ name: 'auth-login' })
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role.roleName : null))
  }
  document.title = to.meta.pageTitle || 'PrepShipHub'
  return next()
})
export default router
