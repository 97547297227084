const carriers = {

  Controller: {
    base: '/api/common/carrier',

    // carriers Endpoints
    findAll: () => `${carriers.Controller.base}`,
    findAllByType: type => `${carriers.Controller.base}/${type}`,
    create: () => `${carriers.Controller.base}`,
  },
}
export default {
  carriers,
}
