const membershipPlans = {
  Controller: {
    base: '/api/membership/plans',
    create: () => `${membershipPlans.Controller.base}`,
    delete: id => `${membershipPlans.Controller.base}/${id}`,
    update: () => `${membershipPlans.Controller.base}`,
    getListByFilter: () => `${membershipPlans.Controller.base}/list`,
    getByPageableQuery: pageable => `${membershipPlans.Controller.base}/query?${pageable}`,
    findById: id => `${membershipPlans.Controller.base}/${id}`,
    getPlanPackages: tenantType => `${membershipPlans.Controller.base}/packages/${tenantType}`,
  },
}

export default { membershipPlans }
