import {
  ProblemTypeEnum,
  DamageTypeEnum,
  ProblemResolutionTypeEnum,
  ProblemStatusEnum,
  LocationTypeEnum,
  InventoryMoveReason,
  PricingModulesEnum,
  PricingType,
  PricingPlanType,
  ItemActionTypeEnum,
  PackTypeEnum,
  BatchStatusEnum,
  LabelingPreferenceEnum,
  PackingTypeEnum,
  ShipmentTypeEnum,
  WorkflowEnum,
  ItemSourceEnum,
  NotificationTypeEnum,
  NotificationSeverityEnum,
  ReferenceTypeEnum,
  SettingsTypeEnum,
  ClientShipmentItemStatusEnum,
  ManagedKeysEnum,
  ValueTypeEnum,
  ScopeTypeEnum,
  FbmResourceTypeEnum,
  FbmOrderStatusEnum,
} from '~/enums'

export default {
  enums: {
    ProblemTypeEnum,
    DamageTypeEnum,
    ProblemResolutionTypeEnum,
    ProblemStatusEnum,
    LocationTypeEnum,
    InventoryMoveReason,
    PricingModulesEnum,
    PricingType,
    PricingPlanType,
    ItemActionTypeEnum,
    PackTypeEnum,
    BatchStatusEnum,
    LabelingPreferenceEnum,
    PackingTypeEnum,
    ShipmentTypeEnum,
    WorkflowEnum,
    ItemSourceEnum,
    NotificationTypeEnum,
    NotificationSeverityEnum,
    ReferenceTypeEnum,
    SettingsTypeEnum,
    ClientShipmentItemStatusEnum,
    ManagedKeysEnum,
    ValueTypeEnum,
    ScopeTypeEnum,
    FbmResourceTypeEnum,
    FbmOrderStatusEnum,
  },
}
