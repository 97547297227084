<template>
  <div ref="target">
    <img v-if="isVisible" class="rounded" :src="src" :alt="alt" :style="{width:width, height:height}">
  </div>
</template>

<script>
import { useIntersectionObserver } from '@vueuse/core'
import { ref } from '@vue/composition-api'

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
  },
  setup() {
    const target = ref(null)
    const isVisible = ref(false)

    const { stop } = useIntersectionObserver(
      target,
      ([{ isIntersecting }]) => {
        isVisible.value = isIntersecting
        if (isIntersecting) {
          stop()
        }
      },
    )
    return {
      target,
      isVisible,
      stop,
    }
  },
}
</script>

<style scoped>
img {
  max-width: 80px;
}
</style>
