/**
 * Enum for problem handling keys.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const keys = Object.freeze(
  {
    PROBLEM_DAMAGED: { key: 'PROBLEM_DAMAGED', value: 'PROBLEM_DAMAGED', text: 'Damaged Problem' },
    PROBLEM_MISPICK_MISSHIP: { key: 'PROBLEM_MISPICK_MISSHIP', value: 'PROBLEM_MISPICK_MISSHIP', text: 'Mis-pick/Mis-ship Problem' },
    PROBLEM_ALARM_TAG: { key: 'PROBLEM_ALARM_TAG', value: 'PROBLEM_ALARM_TAG', text: 'Alarm Tag Problem' },
    PROBLEM_HAZMAT: { key: 'PROBLEM_HAZMAT', value: 'PROBLEM_HAZMAT', text: 'Hazmat Problem' },
    PROBLEM_DIRTY_OR_USED: { key: 'PROBLEM_DIRTY_OR_USED', value: 'PROBLEM_DIRTY_OR_USED', text: 'Dirty or Used Problem' },
    PROBLEM_EXPIRATION_DATE_ISSUE: { key: 'PROBLEM_EXPIRATION_DATE_ISSUE', value: 'PROBLEM_EXPIRATION_DATE_ISSUE', text: 'Expiration Date Issue Problem' },
    PROBLEM_CUSTOMER_CHANGED_MIND: { key: 'PROBLEM_CUSTOMER_CHANGED_MIND', value: 'PROBLEM_CUSTOMER_CHANGED_MIND', text: 'Customer Changed Mind Problem' },
    PROBLEM_MISSING_ITEM: { key: 'PROBLEM_MISSING_ITEM', value: 'PROBLEM_MISSING_ITEM', text: 'Missing Item Problem' },
    PROBLEM_NOT_DELIVERED: { key: 'PROBLEM_NOT_DELIVERED', value: 'PROBLEM_NOT_DELIVERED', text: 'Not Delivered Problem' },
    PROBLEM_LISTING_ASIN_ISSUE: { key: 'PROBLEM_LISTING_ASIN_ISSUE', value: 'PROBLEM_LISTING_ASIN_ISSUE', text: 'Listing ASIN Issue Problem' },
    PROBLEM_ASIN_INPUT_ERROR: { key: 'PROBLEM_ASIN_INPUT_ERROR', value: 'PROBLEM_ASIN_INPUT_ERROR', text: 'ASIN Input Error Problem' },
    PROBLEM_ITEM_MISMATCH: { key: 'PROBLEM_ITEM_MISMATCH', value: 'PROBLEM_ITEM_MISMATCH', text: 'Item Mismatch Problem' },
    PROBLEM_MISSING_ITEM_DATA_ENTRY: { key: 'PROBLEM_MISSING_ITEM_DATA_ENTRY', value: 'PROBLEM_MISSING_ITEM_DATA_ENTRY', text: 'Missing Item Data Entry Problem' },

    PROBLEM_DAMAGED_IND: { key: 'PROBLEM_DAMAGED_IND', value: 'PROBLEM_DAMAGED_IND', text: 'Damaged Problem Individual' },
    PROBLEM_MISPICK_MISSHIP_IND: { key: 'PROBLEM_MISPICK_MISSHIP_IND', value: 'PROBLEM_MISPICK_MISSHIP_IND', text: 'Mis-pick/Mis-ship Problem Individual' },
    PROBLEM_ALARM_TAG_IND: { key: 'PROBLEM_ALARM_TAG_IND', value: 'PROBLEM_ALARM_TAG_IND', text: 'Alarm Tag Problem Individual' },
    PROBLEM_HAZMAT_IND: { key: 'PROBLEM_HAZMAT_IND', value: 'PROBLEM_HAZMAT_IND', text: 'Hazmat Problem Individual' },
    PROBLEM_DIRTY_OR_USED_IND: { key: 'PROBLEM_DIRTY_OR_USED_IND', value: 'PROBLEM_DIRTY_OR_USED_IND', text: 'Dirty or Used Problem Individual' },
    PROBLEM_EXPIRATION_DATE_ISSUE_IND: { key: 'PROBLEM_EXPIRATION_DATE_ISSUE_IND', value: 'PROBLEM_EXPIRATION_DATE_ISSUE_IND', text: 'Expiration Date Issue Problem Individual' },
    PROBLEM_CUSTOMER_CHANGED_MIND_IND: { key: 'PROBLEM_CUSTOMER_CHANGED_MIND_IND', value: 'PROBLEM_CUSTOMER_CHANGED_MIND_IND', text: 'Customer Changed Mind Problem Individual' },
    PROBLEM_MISSING_ITEM_IND: { key: 'PROBLEM_MISSING_ITEM_IND', value: 'PROBLEM_MISSING_ITEM_IND', text: 'Missing Item Problem Individual' },
    PROBLEM_NOT_DELIVERED_IND: { key: 'PROBLEM_NOT_DELIVERED_IND', value: 'PROBLEM_NOT_DELIVERED_IND', text: 'Not Delivered Problem Individual' },
    PROBLEM_LISTING_ASIN_ISSUE_IND: { key: 'PROBLEM_LISTING_ASIN_ISSUE_IND', value: 'PROBLEM_LISTING_ASIN_ISSUE_IND', text: 'Listing ASIN Issue Problem Individual' },
    PROBLEM_ASIN_INPUT_ERROR_IND: { key: 'PROBLEM_ASIN_INPUT_ERROR_IND', value: 'PROBLEM_ASIN_INPUT_ERROR_IND', text: 'ASIN Input Error Problem Individual' },
    PROBLEM_ITEM_MISMATCH_IND: { key: 'PROBLEM_ITEM_MISMATCH_IND', value: 'PROBLEM_ITEM_MISMATCH_IND', text: 'Item Mismatch Problem Individual' },
    PROBLEM_MISSING_ITEM_DATA_ENTRY_IND: { key: 'PROBLEM_MISSING_ITEM_DATA_ENTRY_IND', value: 'PROBLEM_MISSING_ITEM_DATA_ENTRY_IND', text: 'Missing Item Data Entry Problem Individual' },
  },
)

export default keys
