const commonEntities = {
  Controller: {
    base: '/api/common',

    // CommonHub
    getCommonHubById: id => `${commonEntities.Controller.base}/${id}`,
    getCommonHubObjectIdByCommonHubId: commonHubId => `${commonEntities.Controller.base}/${commonHubId}/common-hub-type`,
    createCommonHub: () => `${commonEntities.Controller.base}`,

    // Phones
    findPhoneById: id => `${commonEntities.Controller.base}/phone/${id}`,
    findPhonesByCommonHub: commonHub => `${commonEntities.Controller.base}/phone/hub/${commonHub}`,
    findPhonesByType: (commonHub, phoneType) => `${commonEntities.Controller.base}/phone/hub/${commonHub}/type/${phoneType}`,
    findPhonesByTenantAndCompany: () => `${commonEntities.Controller.base}/phones/tenant/company`,
    createPhone: () => `${commonEntities.Controller.base}/phone`,
    updatePhone: () => `${commonEntities.Controller.base}/phone`,
    deletePhoneById: id => `${commonEntities.Controller.base}/phone/${id}`,

    // Emails
    findEmailById: id => `${commonEntities.Controller.base}/email/${id}`,
    findEmailsByCommonHub: commonHub => `${commonEntities.Controller.base}/email/hub/${commonHub}`,
    createEmail: () => `${commonEntities.Controller.base}/email`,
    updateEmail: () => `${commonEntities.Controller.base}/email`,
    deleteEmailById: id => `${commonEntities.Controller.base}/email/${id}`,

    // Addresses
    findAddressById: id => `${commonEntities.Controller.base}/address/${id}`,
    findAddressesByCommonHub: commonHub => `${commonEntities.Controller.base}/address/hub/${commonHub}`,
    findAddressesByType: (commonHub, addressType) => `${commonEntities.Controller.base}/address/hub/${commonHub}/type/${addressType}`,
    findAddressesByTenantAndCompany: companyId => `${commonEntities.Controller.base}/address/tenant-and-company/${companyId}`,
    createAddress: () => `${commonEntities.Controller.base}/address`,
    updateAddress: () => `${commonEntities.Controller.base}/address`,
    deleteAddressById: id => `${commonEntities.Controller.base}/address/${id}`,
    getAmazonAddress: () => `${commonEntities.Controller.base}/address/amazon`,
    getAmazonAddressById: id => `${commonEntities.Controller.base}/address/amazon/${id}`,
    // Documents
    // TBD

    // Stores
    findECommerceStoreById: id => `${commonEntities.Controller.base}/e-commerce-store/${id}`,
    getStoresByParentCompany: companyId => `${commonEntities.Controller.base}/e-commerce-store/company/${companyId}`,
    findStoresByParentCompanyAccountNo: accountNo => `${commonEntities.Controller.base}/e-commerce-store/company/account-no/${accountNo}`,
    getStoresByCompanyAccountNoAndChannel: (accountNo, channel) => `${commonEntities.Controller.base}/e-commerce-store/company/account-no/${accountNo}/${channel}`,
    findStoreForCurrentCompany: () => `${commonEntities.Controller.base}/e-commerce-store`,
    findStoreByCommonHub: commonHub => `${commonEntities.Controller.base}/e-commerce-store/hub/${commonHub}`,
    createStore: () => `${commonEntities.Controller.base}/e-commerce-store`,
    updateStore: () => `${commonEntities.Controller.base}/e-commerce-store`,
    deleteStoreById: id => `${commonEntities.Controller.base}/e-commerce-store/${id}`,
    getNextMskuId: id => `${commonEntities.Controller.base}/e-commerce-store/next-msku-id/${id}`,
    setAskAuth: storeId => `${commonEntities.Controller.base}/e-commerce-store/set-ask-auth/${storeId}`,
    unsetAskAuth: storeId => `${commonEntities.Controller.base}/e-commerce-store/unset-ask-auth/${storeId}`,
    toggleFBMConnection: (storeId, toggleValue) => `${commonEntities.Controller.base}/e-commerce-store/${storeId}/enable/fbm/${toggleValue}`,
    checkRdtStatus: storeId => `${commonEntities.Controller.base}/e-commerce-store/amazon/is-rdt-enabled/${storeId}`,

    // Credentials
    findCredentialsById: id => `${commonEntities.Controller.base}/credentials/${id}`,
    findCredentialsByCommonHub: commonHub => `${commonEntities.Controller.base}/credentials/hub/${commonHub}`,
    findCredentialsByAccountNo: (filter, params) => `${commonEntities.Controller.base}/credentials/account/${filter.accountNo}?${params}`,
    createCredentials: () => `${commonEntities.Controller.base}/credentials`,
    updateCredentials: () => `${commonEntities.Controller.base}/credentials`,
    deleteCredentialsById: id => `${commonEntities.Controller.base}/credentials/${id}`,

  },
}
export default {
  commonEntities,
}
