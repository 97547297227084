const externalAmazon = {
  controller: {
    /*
    * Amazon Authorization
    */
    auth: '/api/external/amazon/auth',
    findById: id => `${externalAmazon.controller.auth}/id/${id}`,
    findByStoreId: id => `${externalAmazon.controller.auth}/store/${id}`,
    create: () => `${externalAmazon.controller.auth}/create`,
    update: () => `${externalAmazon.controller.auth}/update`,

    /*
    * Amazon
    */
    amazon: '/api/external/amazon',
    getProductDefinition: (storeId, asin) => `${externalAmazon.controller.amazon}/product-type-definitions/${storeId}/${asin}`,
    getShippingRates: storeId => `${externalAmazon.controller.amazon}/shipping/rates/${storeId}`,
    getClientSecret: () => `${externalAmazon.controller.amazon}/get-client-secret`,

    /*
    * Amazon FBA Inbound Eligibility
    * @param program The program for which eligibility was determined:
    *                INBOUND: Inbound shipment.
    *                COMMINGLING: Using the manufacturer barcode for FBA inventory tracking.
    */
    fbaEligibility: '/api/external/amazon/fba/eligibility',
    getItemEligibilityPreview: (asin, program) => `${externalAmazon.controller.fbaEligibility}/itemPreview/${asin}/${program}`,

    /*
    * Amazon Shipment
    */
    fba: '/api/external/amazon/fba',
    getInboundGuidance: () => `${externalAmazon.controller.fba}/inbound/items-guidance`,
    getInventorySummaries: () => `${externalAmazon.controller.fba}/inventory/summary-by-upc-asin-keywords`,

    /*
    * Amazon Product Catalog
    */
    catalog: '/api/external/amazon/catalog',
    getCatalogItemByAsin: asin => `${externalAmazon.controller.catalog}/get-catalog-item-by-asin/0/${asin}`,
    searchCatalogItems: storeId => `${externalAmazon.controller.catalog}/search-catalog-items/${storeId}`,
    getCatalogItemVariationsByAsin: parentAsin => `${externalAmazon.controller.catalog}/get-catalog-item-variations-by-asin/${parentAsin}`,

    /*
    * Amazon Product Type Definitions
    */
    productTypeDefinitions: '/api/external/amazon/product-type-definitions',
    searchProductTypeDefinitions: () => `${externalAmazon.controller.productTypeDefinitions}/search-product-type-definitions`,
    getProductTypeDefinitions: productType => `${externalAmazon.controller.productTypeDefinitions}/get-product-type-definition/${productType}`,

    /*
    * Amazon Reports
    */
    reports: '/api/external/amazon/reports',
    fetchSellerInventoryData: storeId => `${externalAmazon.controller.reports}/fetch-inventory-data/${storeId}`,

    /*
    * Amazon Listings
    */
    listing: '/api/external/amazon/listing',
    putListingsItem: () => `${externalAmazon.controller.listing}/put`,
    patchListingsItem: () => `${externalAmazon.controller.listing}/patch`,
    deleteListingsItem: msku => `${externalAmazon.controller.listing}/delete/${msku}`,
    getListingItem: msku => `${externalAmazon.controller.listing}/get-by-msku/${msku}`,
    // @to-be-deleted
    getListingRestrictions: (storeId, asin) => `${externalAmazon.controller.listing}/restrictions/${storeId}/${asin}`,

    /*
    * Amazon Product Pricing
    */
    pricing: '/api/external/amazon/pricing',
    getPricingByAsin: () => `${externalAmazon.controller.pricing}/product-pricing/by-asin`,
    getPricingByMsku: () => `${externalAmazon.controller.pricing}/product-pricing/by-msku`,
    getCompetitivePricing: () => `${externalAmazon.controller.pricing}/product-competitive-pricing/by-asin`,
    getListingOffersByAsin: (condition, asin) => `${externalAmazon.controller.pricing}/get-listing-offers-by-msku/${condition}/${asin}`,
    getListingOffersByMsku: (condition, sku) => `${externalAmazon.controller.pricing}/get-listing-offers-by-msku/${condition}/${sku}`,
    getItemOffersByAsin: (storeId, condition, asin, countryCode) => `${externalAmazon.controller.pricing}/get-item-offers-by-asin/${storeId}/${condition}/${asin}/${countryCode}`,

    /*
    * Amazon Product Fees
    */
    fees: '/api/external/amazon/products/fees',
    getMyFeesEstimateForSKU: (storeId, msku) => `${externalAmazon.controller.fees}/estimated-by-msku/${storeId}/${msku}`,
    getMyFeesEstimateForASIN: (storeId, asin) => `${externalAmazon.controller.fees}/estimated-by-asin/${storeId}/${asin}`,

    /**
     * Amazon Inbound
     */
    inbound: '/api/external/amazon/inbound',
    getPrepInstructions: (storeId, countryCode) => `${externalAmazon.controller.inbound}/shipments/prepInstructions/${storeId}/asin/${countryCode}`,
  },
}
export default {
  externalAmazon,
}
