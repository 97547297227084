import find from 'lodash/find'
/**
 * Enum for Merchant Connect Underwriting Status.
 * @readonly
 * @enum {{key: string, value: string, text: string}}
 */

const balanceTypesEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'Category (All)' },
  DEPOSIT: { key: 'DEPOSIT', value: 'DEPOSIT', text: 'Deposit' },
  SHIPPING: { key: 'SHIPPING', value: 'SHIPPING', text: 'Shipping' },
})

function getBalanceTypeEnum(predicate, predicateKey = 'key') {
  const result = find(balanceTypesEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getBalanceTypeList() {
  const options = []
  Object
    .keys(balanceTypesEnum)
    .map(key => options.push(
      {
        value: balanceTypesEnum[key].value,
        text: balanceTypesEnum[key].text,
      },
    ))
  return options
}

export default balanceTypesEnum

export {
  getBalanceTypeEnum,
  getBalanceTypeList,
}
