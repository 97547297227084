import Vue from 'vue'

// axios
import axios from 'axios'

const baseURL = process.env.VUE_APP_API_URL
const timeout = process.env.VUE_APP_TIMEOUT

const accessToken = localStorage.getItem('accessToken')
const bearer = `Bearer ${accessToken}`

axios.defaults.trailingSlash = false
const axiosIns = axios.create({
  baseURL,
  timeout,
  headers: {
    'Content-type': 'application/json;charset=UTF-8',
    'Accept-Language': 'en',
    get: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    post: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
    common: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: bearer,
    },
  },
})

Vue.prototype.$http = axiosIns

export default axiosIns
