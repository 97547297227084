export default function enums(Vue, options = {}) {
  const { namespace } = options
  Vue.mixin({
    created() {
      const enumItems = this.$options.enums
      if (enumItems) {
        let target = this
        if (namespace) {
          this[namespace] = {}
          target = this[namespace]
        }
        Object.keys(enumItems).forEach(name => {
          target[name] = Object.freeze(enumItems[name])
        })
      }
    },
  })
}
